import { memo } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Index, { isShowConfig as homeIsShowConfig } from '@/pages/home'
import NotFound, { isShowConfig as notFoundIsShowConfig } from '@/pages/notFound'
import { shell } from './shell'
import { appSave } from '@/tools'

// 遍历pages目录下的index.js文件并动态加载各模块route
// eslint-disable-next-line no-undef
const files = require.context('@/pages', true, /index.js$/)
const routes = []

files.keys().filter((v) => !v.includes('/components/')).forEach((file) => {
	const fileName = file.slice(2).slice(0, -9) // 掐头去尾 ./candidate/index.js -> candidate
	if (fileName) {
		const Component = files(file).default // 组件
		const isShowConfig = files(file).isShowConfig || {} // 布局(各组件是否展示 为配置默认都展示)
		routes.push({
			path: `/${fileName}`,
			element: shell(Component, isShowConfig, fileName),
		})
	}
})

routes.unshift({ path: '/', element: shell(Index, homeIsShowConfig) }) // 添加除了按文件名称默认的路由 以外的自定义路由
routes.push({ path: '*', element: shell(NotFound, notFoundIsShowConfig) }) // 404

try {
	appSave('routes', routes.map(v => v.path))
} catch (error) {
	console.log(`保存 routes 报错: ${error}`)
}

const AppRouter = memo(() => {
	const router = createBrowserRouter(routes)
	return <RouterProvider router={router} />
})

export { routes }
export default AppRouter