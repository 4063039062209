import { memo, useState, useMemo } from 'react'
import api from '@/api'
import { Button, Form, Input, Table, Space, Select, message } from 'antd'
import ListPage from '@/components/listPage'
import Search from '@/components/search'
import TimeSelect from '@/components/timeSelect'
import Curd from '@/components/curd'
import Copy from '@/components/copy'
import MyTag from '@/components/tag'
import SubTable from './components/subTbale'
import RecursionFormList from './components/recursionFormList'
import { useAntdTable, useRequest } from 'ahooks'
import { SearchOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons'
import { dateToStr, tableConfig, arrDayToArrTimestamp, typeOptions, echo, commonColumnsIndex, commonColumnsCreatedAndUpdated } from '@/tools'

const Page = memo(() => {
	const [form] = Form.useForm()
	const [open, setOpen] = useState(false) // curd 窗口展示状态
	const [row, setRow] = useState({}) // 表格当前所选行数据
	const [type, setType] = useState('') // curd 窗口类型
	const [loading, setLoading] = useState(false) // 加载状态
	const { data: dicValueType } = useRequest(() => api.dicGetByKey({ key: 'dicValueType' })) // 字典-字典取值类型
	const [currentType, setCurrentType] = useState(dicValueType?.[0]?.value) // 字典-取值类型
	const dicValueTypeEcho = useMemo(() => echo({ arr: dicValueType, labelField: '*' }), [dicValueType]) // 字典类型回显

	// 列表数据
	const getTableData = ({ current, pageSize }, formData) => {
		const { name, key, desc, type, createdAt, updatedAt } = formData

		const filter = {
			name,
			key,
			desc,
			type,
			createdAt: arrDayToArrTimestamp(createdAt),
			updatedAt: arrDayToArrTimestamp(updatedAt),
		}

		const req = {
			filter,
			page: current,
			limit: pageSize,
		}

		return api.dicList(req).then((res) => {
			res.list = res?.data?.rows
			res.total = res?.data?.count
			return res
		})
	}
	const {
		tableProps,
		search: { submit, reset },
	} = useAntdTable(getTableData, { defaultPageSize: 100, form })

	// 表格列
	const getColumns = () => {
		const data = [
			commonColumnsIndex,
			{
				width: 260,
				title: '_id',
				dataIndex: '_id',
				render: (v) => <Copy>{v}</Copy>,
			},
			{ width: 200, title: '字典名称', dataIndex: 'name' },
			{
				width: 170,
				title: '唯一标识',
				dataIndex: 'key',
				render: (v) => <Copy>{v}</Copy>,
			},
			{ width: 95, title: '取值类型', dataIndex: 'type', render: (v) => <MyTag options={dicValueTypeEcho} value={v} />, },
			{ width: 200, title: '字典描述', dataIndex: 'desc' },
			...commonColumnsCreatedAndUpdated,
			{
				width: 188,
				title: '操作',
				fixed: 'right',
				render: (v, row) => (
					<Space>
						<Button size="small" onClick={() => handle('get', row)}>
              查看
						</Button>
						<Button size="small" onClick={() => handle('set', row)}>
              修改
						</Button>
						<Button size="small" onClick={() => handle('del', row)} danger>
              删除
						</Button>
					</Space>
				),
			},
		]
		return data
	}

	// 打开curd操作抽屉
	const handle = (type, row = {}) => {
		setRow(row)
		setType(type)

		if (type === 'add') {
			setCurrentType(dicValueType?.[0]?.value) // 取值类型默认值
		} else {
			setCurrentType(row.type)
		}
		setOpen(true)
	}

	// curd操作
	const curdSubmit = async (formData, row, type) => {
		formData = { ...formData }

		const init = () => {
			setLoading(false) // 取消加载状态
			setOpen(false) // 关闭窗口
			submit() // 刷新外层列表
			message.success('操作成功')
		}

		try {
			setLoading(true)

			switch (type) {
			case 'add':
				await api.dicAdd({ data: formData })
				init()
				break

			case 'del':
				await api.dicDel({ _id: row._id })
				init()
				break

			case 'set':
				await api.dicSet({ ...formData, _id: row._id })
				init()
				break

			case 'get':
				setLoading(false)
				setOpen(false) // 关闭窗口
				break

			default:
				break
			}
		} catch (error) {
			setLoading(false)
			console.log('curd报错: ', error)
		}
	}

	// 搜索栏-表单
	const searchFormData = () => {
		return [
			{
				formItemProps: { name: 'name' },
				render: <Input placeholder="字典名称" allowClear />,
			},
			{
				formItemProps: { name: 'key' },
				render: <Input placeholder="唯一标识" allowClear />,
			},
			{
				formItemProps: { name: 'type' },
				render: (<Select allowClear placeholder="取值类型" options={dicValueType} />),
			},
			{
				formItemProps: { name: 'desc' },
				render: <Input placeholder="字典描述" allowClear />,
			},
			{
				formItemProps: { name: 'createdAt' },
				render: (
					<TimeSelect placeholder={['创建时间-开始', '创建时间-结束']} />
				),
				colPorps: { multiple: 2 },
			},
			{
				formItemProps: { name: 'updatedAt' },
				render: (
					<TimeSelect placeholder={['更新时间-开始', '更新时间-结束']} />
				),
				colPorps: { multiple: 2 },
			},
		]
	}

	// 搜索栏-按钮
	const searchBtnData = () => {
		return [
			{
				title: '搜索',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={<SearchOutlined />}
						onClick={submit}
						loading={tableProps.loading}
					/>
				),
			},
			{
				title: '重置',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={<ReloadOutlined />}
						onClick={reset}
					/>
				),
			},
			{
				title: '新增',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={<PlusOutlined />}
						onClick={() => handle('add')}
					/>
				),
			},
		]
	}

	// curd-表单
	const curdFormConfig = () => {
		return [
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '字典名称',
					name: 'name',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '唯一标识',
					name: 'key',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '取值类型',
					name: 'type',
				},
				render: (<Select
					allowClear
					options={dicValueType}
					onChange={setCurrentType}
					value={currentType}
				/>),
			},
			{
				formItemProps: { label: '字典描述', name: 'desc' },
				render: (
					<Input.TextArea
						allowClear
						rows={4}
						showCount
						maxLength={128}
						style={{ marginBottom: 40 }}
					/>
				),
			},
			{
				render: (
					<RecursionFormList
						fields={{ name: 'data' }}
						lv={1}
						type={dicValueType}
						row={row}
						open={open}
						currentType={currentType}
						disabled={type === 'get' || type === 'del'}
					/>
				),
			},
		]
	}

	return (
		<ListPage
			search={
				<Search
					form={form}
					submit={submit}
					formData={searchFormData()}
					btnData={searchBtnData()}
				/>
			}
		>
			<Table
				rowKey={(v) => v._id}
				columns={getColumns()}
				{...tableProps}
				{...tableConfig({ tableProps })}
				expandable={{
					childrenColumnName: '_', // 因为有 children 字段 随便制定一个不存在的字段 防止被自动添加 children 的子表展开按钮
					rowExpandable: (record) => record?.data?.children?.length,
					expandedRowRender: (record) => (
						<SubTable dataSource={record?.data?.children || []} />
					),
				}}
			/>

			<Curd
				width={800}
				open={open}
				setOpen={setOpen}
				row={row}
				type={type}
				title={`${typeOptions?.[type] || ''}字典`}
				formProps={{ labelCol: { span: 3 }, wrapperCol: { span: 21 } }}
				formConfig={curdFormConfig()}
				submit={curdSubmit}
				loading={loading}
			/>
		</ListPage>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: false,
}
