import { memo, useState, useMemo } from 'react'
import api from '@/api'
import { Button, Form, Input, Table, Space, Select, message } from 'antd'
import ListPage from '@/components/listPage'
import Search from '@/components/search'
import TimeSelect from '@/components/timeSelect'
import Curd from '@/components/curd'
import Copy from '@/components/copy'
import Tag from '@/components/tag'
import Btns from '@/components/btns'
import DicSelect from '@/components/dicSelect'
import { useAntdTable, useRequest } from 'ahooks'
import { tableConfig, arrDayToArrTimestamp, typeOptions, echo, icon, commonColumnsIndex, commonColumnsCreatedAndUpdated } from '@/tools'

const Page = memo(() => {
	const [form] = Form.useForm()
	const [open, setOpen] = useState(false) // curd 窗口展示状态
	const [row, setRow] = useState({}) // 表格当前所选行数据
	const [type, setType] = useState('') // curd 窗口类型
	const [loading, setLoading] = useState(false) // 加载状态
	const { data: errStatus } = useRequest(() => api.dicGetByKey({ key: 'errStatus' })) // 字典-错误状态
	const errStatusEcho = useMemo(() => echo({ arr: errStatus, labelField: '*' }), [errStatus]) // 错误状态-回显数据

	const { data: errCode } = useRequest(() => api.dicGetByKey({ key: 'errCode' })) // 字典-错误代码
	const codeEcho = useMemo(() => echo({ arr: errCode, labelField: '*' }), [errCode]) // 错误代码-回显数据

	const [tableSelectData, setTableSelectData] = useState([]) // 所选表格数据
	const [tableSelectKey, setTableSelectKey] = useState([]) // 所选表格key

	// 列表数据
	const getTableData = ({ current, pageSize }, formData) => {
		const {
			msg,
			interface: _interface,
			status,
			desc,
			code,
			createdAt,
			updatedAt,
		} = formData

		const filter = {
			msg,
			interface: _interface,
			status,
			desc,
			code,
			createdAt: arrDayToArrTimestamp(createdAt),
			updatedAt: arrDayToArrTimestamp(updatedAt),
		}

		const req = {
			filter,
			page: current,
			limit: pageSize,
		}

		return api.errList(req).then((res) => {
			res.list = res?.data?.rows
			res.total = res?.data?.count
			setTableSelectData([])
			setTableSelectKey([])
			return res
		})
	}
	const { tableProps, search: { submit, reset } } = useAntdTable(getTableData, { defaultPageSize: 100, form })

	// 表格列
	const getColumns = (tableType) => {
		const data = [
			commonColumnsIndex,
			{
				width: 260,
				title: '_id',
				dataIndex: '_id',
				render: (v) => <Copy>{v}</Copy>,
			},
			{ width: 200, title: '接口url', dataIndex: 'interface' },
			{ width: 200, title: '错误信息', dataIndex: 'msg' },
			{ width: 100, title: 'code', dataIndex: 'code' },
			{ width: 150, title: 'code名称', dataIndex: 'code', render: (v) => <Tag options={codeEcho} value={v} /> },
			{
				width: 100,
				title: '状态',
				dataIndex: 'status',
				render: (v) => <Tag options={errStatusEcho} value={v} />,
			},
			{ width: 200, title: '描述', dataIndex: 'desc' },
			...commonColumnsCreatedAndUpdated,
			{
				width: 188,
				title: '操作',
				fixed: 'right',
				render: (v, row) => (
					<Space>
						<Button size="small" onClick={() => handle('get', row)}>
								查看
						</Button>
						<Button size="small" onClick={() => handle('set', row)}>
								修改
						</Button>
						<Button size="small" onClick={() => handle('del', row)} danger>
								删除
						</Button>
					</Space>
				),
			},
		]

		switch (tableType) {
		case 'batchHandle':
			return data.slice(0, 4)

		default:
			return data
		}
	}

	// 打开curd操作抽屉
	const handle = (type, row = {}) => {
		setRow(row)
		setType(type)
		setOpen(true)
	}

	// curd操作
	const curdSubmit = async (formData, row, type) => {
		formData = { ...formData }

		const init = () => {
			setLoading(false) // 取消加载状态
			setOpen(false) // 关闭窗口
			submit() // 刷新外层列表
			message.success('操作成功')
		}

		try {
			setLoading(true)

			switch (type) {
			case 'add':
				await api.errAdd({ data: formData })
				init()
				break

			case 'del':
				await api.errDel({ _id: row._id })
				init()
				break

			case 'set':
				await api.errSet({ ...formData, _id: row._id })
				init()
				break

			case 'get':
				setLoading(false)
				setOpen(false) // 关闭窗口
				break

			case 'batchHandle': // 批量处理
				await api.errSet({ ...formData, ids: tableSelectKey, status: '1' })
				init()
				break

			default:
				break
			}
		} catch (error) {
			setLoading(false)
			console.log('curd报错: ', error)
		}
	}

	// 表格选择
	const tableSelectChange = (_selectedRowKeys, _selectedRows) => {
		setTableSelectData(_selectedRows)
		setTableSelectKey(_selectedRowKeys)
	}

	// 搜索栏-表单
	const searchFormData = () => {
		return [
			{
				formItemProps: { name: 'interface' },
				render: <Input placeholder="接口url" allowClear />,
			},
			{
				formItemProps: { name: 'msg' },
				render: <Input placeholder="错误信息" allowClear />,
			},
			{
				formItemProps: { name: 'status' },
				render: (
					<Select placeholder="状态" allowClear options={errStatus} />
				),
			},
			{
				formItemProps: { name: 'code' },
				render: (
					<DicSelect placeholder="错误代码" dicKey='errCode' />
				),
			},
			{
				formItemProps: { name: 'createdAt' },
				render: (
					<TimeSelect placeholder={['创建时间-开始', '创建时间-结束']} />
				),
				colPorps: { multiple: 2 },
			},
			{
				formItemProps: { name: 'updatedAt' },
				render: (
					<TimeSelect placeholder={['更新时间-开始', '更新时间-结束']} />
				),
				colPorps: { multiple: 2 },
			},
			{
				formItemProps: { name: 'desc' },
				render: <Input placeholder="描述" allowClear />,
			},
		]
	}

	// 搜索栏-按钮
	const searchBtnData = () => {
		return [
			{
				title: '搜索',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={icon('SearchOutlined')}
						onClick={submit}
						loading={tableProps.loading}
					/>
				),
			},
			{
				title: '重置',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={icon('ReloadOutlined')}
						onClick={reset}
					/>
				),
			},
			{
				title: '新增',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={icon('PlusOutlined')}
						onClick={() => handle('add')}
					/>
				),
			},
		]
	}

	// 批量操作区-按钮
	const batchBtnData = () => {
		return [
			{
				title: '批量处理',
				btn: (
					<Button
						type="primary"
						icon={icon('AuditOutlined')}
						onClick={() => handle('batchHandle')}
						disabled={!tableSelectKey?.length}
					/>
				),
			},
		]
	}

	// curd-表单
	const curdFormConfig = () => {
		return type !== 'batchHandle' ?
			[
				{
					formItemProps: {
						rules: [{ required: true }],
						label: '接口url',
						name: 'interface',
					},
					render: <Input allowClear />,
				},
				{
					formItemProps: {
						rules: [{ required: true }],
						label: '错误信息',
						name: 'msg',
					},
					render: <Input allowClear />,
				},
				{
					formItemProps: {
						rules: [{ required: true }],
						label: '状态',
						name: 'status',
					},
					render: <Select allowClear options={errStatus} />,
				},
				{
					formItemProps: { label: '描述', name: 'desc' },
					render: (
						<Input.TextArea
							allowClear
							rows={4}
							showCount
							maxLength={128}
						/>
					),
				},
			]
			:
			[
				{
					formItemProps: { label: '描述', name: 'desc' },
					render: (
						<Input.TextArea
							allowClear
							rows={4}
							showCount
							maxLength={128}
						/>
					),
				},
			]
	}

	return (
		<ListPage
			search={
				<Search
					form={form}
					submit={submit}
					formData={searchFormData()}
					btnData={searchBtnData()}
				/>
			}
			count={tableSelectKey.length}
			actionsRight={<Btns data={batchBtnData()} />}
		>
			<Table
				rowKey={(v) => v._id}
				columns={getColumns()}
				{...tableProps}
				{...tableConfig({ tableProps })}
				rowSelection={{
					type: 'checkbox',
					onChange: tableSelectChange,
					selectedRowKeys: tableSelectKey,
				}}
			/>

			<Curd
				width={800}
				open={open}
				setOpen={setOpen}
				row={row}
				type={type}
				title={`${typeOptions?.[type] || ''}错误`}
				formProps={{ labelCol: { span: 3 }, wrapperCol: { span: 21 } }}
				formConfig={curdFormConfig()}
				submit={curdSubmit}
				loading={loading}
				beforeChildren={
					type === 'batchHandle' ? (
						<Table
							rowKey={(v) => v._id}
							columns={getColumns('batchHandle')}
							pagination={false}
							dataSource={tableSelectData}
							scroll={{ y: 450 }}
							style={{ marginBottom: 20 }}
						/>
					) : null
				}
			/>
		</ListPage>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: false,
}
