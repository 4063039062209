import { memo, useState, useMemo, useEffect, useRef } from 'react'
import api from '@/api'
import { Button, Form, Input, Table, Space, Cascader, Tag, Tooltip, Select, Alert, message } from 'antd'
import ListPage from '@/components/listPage'
import Search from '@/components/search'
import TimeSelect from '@/components/timeSelect'
import Copy from '@/components/copy'
import Curd from '@/components/curd'
import Btns from '@/components/btns'
import BoolSelect from '@/components/boolSelect'
import { useAntdTable, useRequest } from 'ahooks'
import { SearchOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons'
import { tableConfig, arrDayToArrTimestamp, typeOptions, generateMenuTree, removeDuplicateChildren, cascadeFlatEchoObj, icon, unique, cascaderResultCompletion, clone, markSelected, restoreSelectedValues, recursiveMerge, filterConfig, bindDataProcessing, commonColumnsIndex, commonColumnsCreatedAndUpdated } from '@/tools'
import { routes as _routes } from '@/router/components/router'

// 权限类型回显
const TypeShow = memo((props) => {
	const { data, echo } = props

	if (data?.length) {
		const newData = unique(data.flat(Infinity))
		return (
			<>
				{newData.map((i) => (
					<Tag key={i} color={echo?.[i]?.color}>
						{echo?.[i]?.label}
					</Tag>
				))}
			</>
		)
	} else {
		return null
	}
})

const renameKeys = (data) => {
	if (Array.isArray(data)) {
		return data.map((item) => renameKeys(item))
	} else if (typeof data === 'object' && data !== null) {
		const result = {}
		for (const key in data) {
			if (key === 'key') {
				result.value = data[key]
			} else if (key === 'value') {
				result._id = data[key]
			} else {
				result[key] = renameKeys(data[key])
			}
		}
		return result
	} else {
		return data
	}
}

const Page = memo(() => {
	const childRef = useRef(null)
	const [form] = Form.useForm()
	const [open, setOpen] = useState(false) // curd 窗口展示状态
	const [row, setRow] = useState({}) // 表格当前所选行数据
	const [type, setType] = useState('') // curd 窗口类型
	const [loading, setLoading] = useState(false) // 加载状态
	const { data: apiOptions } = useRequest(() => api.permissionsuApiOptions().then((res) => res?.data || [])) // 数据权限选项(接口)
	const { data: menuAll } = useRequest(() => api.menuAll({}).then((res) => renameKeys(res?.data?.rows) || [])) // 全部菜单数据(菜单)
	const routes = useMemo(() => { // 页面(路由)
		if (_routes?.length) {
			return _routes.map((v) => ({ label: v.path, value: v.path }))
		} else {
			return []
		}
	}, [_routes])
	const { data: roleAll } = useRequest(() => api.roleAll({}).then((res) => res?.data?.rows || [])) // 全部角色数据-父级选项
	const { data: permissionsAll, run: permissionsAllRun } = useRequest(() => api.permissionsAll({}).then((res) => res?.data?.rows || [])) // 全部权限数据-父级选项
	const { data: permissionType } = useRequest(() => api.dicGetByKey({ key: 'permissionType' })) // 字典-权限类型
	const [formPermissionType, setFormPermissionType] = useState([]) // 表单选中的权限类型
	const permissionTypeEcho = useMemo(() => { // 权限类型 扁平化 用于回显
		if (permissionType?.length) {
			return cascadeFlatEchoObj(permissionType)
		} else {
			return []
		}
	}, [permissionType])
	const [tableSelectData, setTableSelectData] = useState([]) // 所选表格数据(扁平数据)
	const [tableSelectTreeData, setTableSelectTreeData] = useState([]) // 所选表格数据(树结构数据)
	const [tableSelectKey, setTableSelectKey] = useState([]) // 所选表格key
	const [bindRoleTableData, setBindRoleTableData] = useState([]) // 关联表数据(当前选中权限 & 已关联的角色信息 的归并数据)
	const selectedRoleIds = useRef([]) // 单条绑定角色-选中的角色数据

	// 列表数据
	const getTableData = ({ current, pageSize }, formData) => {
		const { name, parentId, type, menu, url, isApproval, disabled, desc, createdAt, updatedAt } = formData

		const filter = {
			name,
			parentId,
			type,
			url,
			isApproval,
			disabled,
			menu,
			desc,
			createdAt: arrDayToArrTimestamp(createdAt),
			updatedAt: arrDayToArrTimestamp(updatedAt),
		}

		const req = {
			filter,
			page: current,
			limit: pageSize,
		}

		return api.permissionsList(req).then((res) => {
			res.list = generateMenuTree(
				res?.data?.rows?.map((v) => ({ ...v, value: v._id })) || []
			)
			res.total = res?.data?.count
			return res
		})
	}
	const { tableProps, search: { submit, reset } } = useAntdTable(getTableData, { defaultPageSize: 100, form })

	// 表格列
	const getColumns = (type) => {
		const data = [
			commonColumnsIndex,
			{
				width: 260,
				title: '_id',
				dataIndex: '_id',
				render: (v) => <Copy>{v}</Copy>,
			},
			{ width: 160, title: '权限名称', dataIndex: 'name' },
			{
				width: 240,
				title: '权限类型',
				dataIndex: 'type',
				render: (v) => <TypeShow data={v} echo={permissionTypeEcho} />,
			},
			{
				width: 260,
				title: '关联菜单',
				dataIndex: 'menu',
				render: (v) =>
					v?.length ? v.map((i) => <Tag key={i}>{i}</Tag>) : null,
			},
			{ width: 260, title: '关联路由', dataIndex: 'url' },
			{
				width: 260,
				title: '关联接口',
				dataIndex: 'api',
				render: (v) =>
					v?.length ? v.map((i) => <Tag key={i}>{i}</Tag>) : null,
			},
			{
				width: 100,
				title: '是否审批',
				dataIndex: 'isApproval',
				render: (v) => <Tag>{v ? '是' : '否'}</Tag>,
			},
			{
				width: 100,
				title: '是否禁用',
				dataIndex: 'disabled',
				render: (v) => <Tag>{v ? '是' : '否'}</Tag>,
			},
			{ width: 200, title: '权限描述', dataIndex: 'desc' },
			{
				width: 260,
				title: '父级id',
				dataIndex: 'parentId',
				render: (v) => <Copy>{v?.join(',')}</Copy>,
			},
			...commonColumnsCreatedAndUpdated,
			{
				width: 188,
				title: '操作',
				fixed: 'right',
				render: (v, row) => (
					<Space>
						<Button size="small" onClick={() => handle('get', row)}>查看</Button>
						<Button size="small" onClick={() => handle('set', row)}>修改</Button>
						<Button size="small" onClick={() => handle('del', row)} danger>删除</Button>
						<Button size="small" onClick={() => handle('bind', row)}>绑定角色</Button>
					</Space>
				),
			},
		]
		if (type === 'batchBind') {
			return data.slice(0, 4).concat({
				width: 300,
				title: '已绑角色',
				dataIndex: 'bindData',
				render: (v) =>
					v?.length
						? v.map((i) => (
							<Tag key={i._id}>{i?.roleData?.[0]?.name || ''}</Tag>
						))
						: null,
			})
		} else {
			return data
		}
	}

	// 打开curd操作抽屉
	const handle = (type, row = {}) => {
		setRow({
			...row,
			parentId: row?.parentId?.length ? [row?.parentId] : row?.parentId,
		})
		setType(type)
		setOpen(true)
		setFormPermissionType(row.type) // 权限类型回显
	}

	// curd操作
	const curdSubmit = async (formData, row, type) => {
		formData = { ...formData }

		if (formData?.parentId?.length) {
			formData.parentId = formData.parentId[0]
		} else {
			formData.parentId = null
		}

		// 刷新窗口状态
		const init = () => {
			setLoading(false) // 取消加载状态
			setOpen(false) // 关闭窗口
			submit() // 刷新外层列表
			permissionsAllRun() // 刷新全部权限选项
			message.success('操作成功')
		}

		try {
			setLoading(true)

			switch (type) {
			case 'add':
				await api.permissionsAdd({ data: formData })
				init()
				break

			case 'del':
				await api.permissionsDel({ _id: row._id })
				init()
				break

			case 'set':
				await api.permissionsSet({ ...formData, _id: row._id })
				init()
				break

			case 'get':
				setLoading(false)
				setOpen(false) // 关闭窗口
				break

			case 'bind': // 绑定角色
				setLoading(false)
				setOpen(false) // 关闭窗口
				break

			case 'batchBind': // 批量绑定角色
				await api.role_permissionsSave({
					roleIds: cascaderResultCompletion(formData?.roleIds, roleAll),
					permissionsIds: tableSelectKey,
				})
				init()
				break

			default:
				break
			}
		} catch (error) {
			setLoading(false)
			console.log('curd报错: ', error)
		}
	}

	// 表格选择
	const tableSelectChange = (_selectedRowKeys, _selectedRows) => {
		setTableSelectData(_selectedRows)
		setTableSelectTreeData(removeDuplicateChildren(_selectedRows)) // 剔除 重复的一级节点数据
		setTableSelectKey(_selectedRowKeys)
	}

	// 补全 permissionType (有节点全选时的后辈节点)
	const selectPermissionTypeData = useMemo(() => {
		if (permissionType?.length && formPermissionType?.length) {
			return cascaderResultCompletion(
				formPermissionType,
				permissionType,
				'value',
			)
		} else {
			return []
		}
	}, [permissionType, formPermissionType])

	// 绑定时 锁定无关表单
	const isSetFormDisabled = useMemo(() => (type === 'bind' ? true : undefined), [type])

	// 单条权限 绑定/解绑 角色
	const bind = async (value, selectedOptions) => {
		const req = {
			// roleIds: cascaderResultCompletion(value, roleAll),
			permissionsIds: [row._id],
		}

		const task = []
		bindDataProcessing({
			req,
			selectedIds: selectedRoleIds.current,
			dataAll: roleAll,
			targetIds: 'roleIds',
			apiDel: api.role_permissionsConditionalDel,
			apiSave: api.role_permissionsSave,
			task,
			value,
		})

		const res = await Promise.allSettled(task) // 增/删
		console.log(0, res)
		const isSuccess = res.every(v => v?.value?.code === 0)

		if (isSuccess) {
			message.success('操作成功')
			getBindData() // 从新获取数据
		}
	}

	// 获取form配置
	const formConfig = useMemo(() => {
		const data = [
			{
				formItemProps: {
					rules: [{ required: true }, { max: 100 }],
					label: '权限名称',
					name: 'name',
				},
				render: <Input allowClear disabled={isSetFormDisabled} />,
			},
			{
				formItemProps: {
					rules: [
						({ getFieldValue }) => ({
							validator(_, value) {
								if (value?.length > 1)
									return Promise.reject(new Error('只能选择一个节点'))
								return Promise.resolve()
							},
						}),
					],
					label: '父级权限',
					name: 'parentId',
				},
				render: (
					<Cascader
						allowClear
						options={permissionsAll}
						expandTrigger="hover"
						multiple
						changeOnSelect
						disabled={isSetFormDisabled}
						{...filterConfig}
					/>
				),
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '权限类型',
					name: 'type',
				},
				render: (
					<Cascader
						allowClear
						options={permissionType}
						value={formPermissionType}
						onChange={setFormPermissionType}
						expandTrigger="hover"
						multiple
						disabled={isSetFormDisabled}
						{...filterConfig}
					/>
				),
			},

			selectPermissionTypeData?.flat(Infinity)?.includes('1-1')
				? {
					formItemProps: {
						rules: [
							{ required: true },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (value?.length > 1)
										return Promise.reject(new Error('只能选择一个节点'))
									return Promise.resolve()
								},
							}),
						],
						label: '关联菜单',
						name: 'menu',
					},
					render: (
						<Cascader
							allowClear
							options={menuAll}
							expandTrigger="hover"
							multiple
							changeOnSelect
							disabled={isSetFormDisabled}
							{...filterConfig}
						/>
					),
				}
				: undefined,

			selectPermissionTypeData?.flat(Infinity)?.includes('1-2')
				? {
					formItemProps: {
						rules: [{ required: true }],
						label: '关联路由',
						name: 'url',
					},
					render: (
						<Select
							allowClear
							options={routes}
							disabled={isSetFormDisabled}
						/>
					),
				}
				: undefined,

			selectPermissionTypeData?.flat(Infinity)?.includes('2')
				? {
					formItemProps: {
						rules: [{ required: true }],
						label: '关联接口',
						name: 'api',
					},
					render: (
						<Cascader
							allowClear
							options={apiOptions}
							expandTrigger="hover"
							multiple
							changeOnSelect
							disabled={isSetFormDisabled}
							{...filterConfig}
						/>
					),
				}
				: undefined,

			{
				formItemProps: {
					rules: [],
					label: (
						<Tooltip placement="top" title={'暂时无用 扩展审批流使用'}>
              是否审批 {icon('QuestionCircleOutlined')}
						</Tooltip>
					),
					name: 'isApproval',
					initialValue: false,
				},
				render: <BoolSelect disabled={isSetFormDisabled} />,
			},
			{
				formItemProps: {
					rules: [],
					label: '是否禁用',
					name: 'disabled',
					initialValue: false,
				},
				render: <BoolSelect disabled={isSetFormDisabled} />,
			},
			{
				formItemProps: { rules: [], label: '权限描述', name: 'desc' },
				render: (
					<Input.TextArea
						allowClear
						rows={4}
						showCount
						maxLength={128}
						disabled={isSetFormDisabled}
					/>
				),
			},
		].filter((v) => v)

		if (type === 'batchBind') {
			return [
				{
					formItemProps: {
						label: '角色',
						name: 'roleIds',
						labelCol: { span: 2 },
						wrapperCol: { span: 22 },
					},
					render: (
						<Cascader
							allowClear
							options={roleAll}
							multiple
							expandTrigger="hover"
							{...filterConfig}
						/>
					),
				},
			]
		} else if (type === 'bind') {
			return [
				...data,
				{
					formItemProps: {
						label: '角色',
						name: 'roleIds',
					},
					render: (
						<Cascader
							allowClear
							options={roleAll}
							multiple
							expandTrigger="hover"
							onChange={bind}
							loading={loading}
							disabled={loading}
							{...filterConfig}
						/>
					),
				},
			]
		} else {
			return data
		}
	}, [type, selectPermissionTypeData, row])

	// 获取已绑定数据
	const getBindData = async () => {
		setLoading(true)

		const req = { permissionsIds: [row._id] }
		const res = await api.role_permissionsFind(req)
		const selectedData = res?.data?.[0]?.data?.map((v) => v.roleId) || []

		// 克隆
		const _roleAll = clone(roleAll)

		// 打标
		const markingData = markSelected(selectedData, _roleAll)

		// 抽取
		const reductionData = restoreSelectedValues(markingData)

		// 渲染
		childRef.current.form.setFieldsValue({ roleIds: reductionData })

		// 存储
		selectedRoleIds.current = reductionData

		setLoading(false)
	}
	// 打开绑定窗口时查询并回显已绑定数据
	useEffect(() => {
		if (open) {
			try {
				if (type === 'batchBind') {
					setLoading(true)
					const req = { permissionsIds: tableSelectKey }
					api.role_permissionsFind(req).then((res) => {
						const dic = {}

						res?.data?.forEach(({ _id, data }) => {
							dic[_id] = data
						})

						const data = clone(tableSelectTreeData)

						recursiveMerge(data, dic)
						setBindRoleTableData(data)
						setLoading(false)
					})
				} else if (type === 'bind') {
					getBindData() // 获取并回显 已绑定数据
				}
			} catch (error) {
				setLoading(false)
				console.log(error)
			}
		} else {
			setBindRoleTableData([])
			selectedRoleIds.current = []
		}
	}, [open, type])

	return (
		<ListPage
			search={
				<Search
					form={form}
					submit={submit}
					formData={[
						{
							formItemProps: { name: 'name' },
							render: <Input placeholder="权限名称" allowClear />,
						},
						{
							formItemProps: { name: 'parentId' },
							render: (
								<Cascader
									placeholder="父级权限"
									allowClear
									options={permissionsAll}
									expandTrigger="hover"
									{...filterConfig}
								/>
							),
						},
						{
							formItemProps: { name: 'type' },
							render: (
								<Cascader
									placeholder="权限类型"
									allowClear
									options={permissionType}
									expandTrigger="hover"
									{...filterConfig}
								/>
							),
						},
						{
							formItemProps: { name: 'menu' },
							render: (
								<Cascader
									placeholder="关联菜单"
									allowClear
									options={menuAll}
									expandTrigger="hover"
									multiple
									{...filterConfig}
								/>
							),
						},
						{
							formItemProps: { name: 'url' },
							render: (
								<Select placeholder="关联路由" allowClear options={routes} />
							),
						},
						{
							formItemProps: { name: 'api' },
							render: (
								<Cascader
									placeholder="关联接口"
									allowClear
									options={apiOptions}
									expandTrigger="hover"
									{...filterConfig}
								/>
							),
						},
						{
							formItemProps: { name: 'isApproval' },
							render: <BoolSelect placeholder="是否审批" />,
						},
						{
							formItemProps: { name: 'disabled' },
							render: <BoolSelect placeholder="是否禁用" />,
						},
						{
							formItemProps: { name: 'createdAt' },
							render: (
								<TimeSelect placeholder={['创建时间-开始', '创建时间-结束']} />
							),
							colPorps: { multiple: 2 },
						},
						{
							formItemProps: { name: 'updatedAt' },
							render: (
								<TimeSelect placeholder={['更新时间-开始', '更新时间-结束']} />
							),
							colPorps: { multiple: 2 },
						},
						{
							formItemProps: { name: 'desc' },
							render: <Input placeholder="菜单描述" allowClear />,
						},
					]}
					btnData={[
						{
							title: '搜索',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<SearchOutlined />}
									onClick={submit}
									loading={tableProps.loading}
								/>
							),
						},
						{
							title: '重置',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<ReloadOutlined />}
									onClick={reset}
								/>
							),
						},
						{
							title: '新增',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<PlusOutlined />}
									onClick={() => handle('add')}
								/>
							),
						},
					]}
				/>
			}
			count={tableSelectKey.length}
			actionsRight={
				<Btns
					data={[
						{
							title: '批量绑定角色',
							btn: (
								<Button
									type="primary"
									icon={icon('IdcardOutlined')}
									onClick={() => handle('batchBind')}
									disabled={!tableSelectKey?.length}
								/>
							),
						},
					]}
				/>
			}
		>
			<Table
				rowKey={(v) => v._id}
				columns={getColumns()}
				{...tableProps}
				{...tableConfig({ tableProps })}
				rowSelection={{
					type: 'checkbox',
					onChange: tableSelectChange,
					selectedRowKeys: tableSelectKey,
					checkStrictly: false,
				}}
			/>

			<Curd
				ref={childRef}
				open={open}
				setOpen={setOpen}
				row={row}
				type={type}
				title={
					type === 'batchBind'
						? '批量绑定角色'
						: type === 'bind'
							? '权限绑定角色'
							: `${typeOptions?.[type] || ''}权限`
				}
				width={type === 'batchBind' ? 1000 : 600}
				formConfig={formConfig}
				submit={curdSubmit}
				loading={loading}
				beforeChildren={
					type === 'batchBind' ? (
						<>
							<Alert
								message="批量操作无法回显或删除已绑定数据, 如需查看或删除已绑定数据请单条数据处理"
								type="info"
								showIcon
								style={{ marginBottom: 10 }}
							/>
							<Table
								rowKey={(v) => v._id}
								columns={getColumns('batchBind')}
								pagination={false}
								dataSource={bindRoleTableData}
								scroll={{ y: 450 }}
								style={{ marginBottom: 20 }}
								loading={loading}
							/>
						</>
					) : null
				}
			/>
		</ListPage>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: false,
}
