import axios from 'axios'
import { message } from 'antd'
import handleExceptionDic from './handleException'
// eslint-disable-next-line no-undef
const isProduction = process.env.NODE_ENV === 'production' // 是否生产环境 // 'development'
export const baseUrl = isProduction ? `${window.location.protocol}//api.iefi.cc:777` : 'http://127.0.0.1:777'
export const imgUrl = `${baseUrl}/uploads/pic`
console.log('是否生产环境', isProduction)

const METHOD = {
	POST: 'post',
	GET: 'get',
	PUT: 'put',
	DELETE: 'delete',
}

const axiosInstance = axios.create({
	timeout: 30000,
})

axiosInstance.defaults.baseURL = baseUrl

// request拦截器 - 注入token
axiosInstance.interceptors.request.use(
	config => {
		const userInfo = window?.__app__?.userInfo || JSON.parse(window.localStorage.getItem('userInfo')) || null
		const token = userInfo?.token

		if (token) {
			config.headers.token = token
		}
		return config
	},
	err => {
		return Promise.reject(err)
	}
)

// response拦截器 - 处理特殊code
axiosInstance.interceptors.response.use((response) => {
	// 约定的 特殊 code 调用对应的处理逻辑(例: 掉线是跳转登录页面)
	const code = response?.data?.code
	if (code !== 0) {
		handleExceptionDic?.[code]?.handle?.()
	}

	// res拦截器 只执行 约定了code的处理方式的case
	// res数据返回后 进一步处理
	return response
})

// 过滤掉请求参数为 null | undefined 的属性；去除字符串两端的特殊字符
const filterParams = (params) => {
	const newPrams = {}
	for (const [key, value] of Object.entries(params)) {
		if (typeof value === 'string') {
			// 不是空字符串切除 字符串开头结尾处空格 空串直接忽略
			if (value !== '') {
				newPrams[key] = value.trim()
			}
		} else if (Object.prototype.toString.call(value) === '[object Object]') {
			// 对象向下递归
			newPrams[key] = filterParams(value)
		} else if (value !== null && typeof value !== 'undefined') {
			newPrams[key] = value
		} else {
			newPrams[key] = value
		}
	}
	return newPrams
}

const request = ({ method, url, params, restConfig = {}, isFilter = true }) => {
	method = method.toLowerCase()

	if (typeof params === 'undefined') {
		params = null
	} else if (isFilter) {
		params = filterParams(params)
	}

	// params = typeof params === 'undefined' ? null : filterParams(params)

	return new Promise((resolve, reject) => {
		const config = {
			method,
			url,
			data: method === METHOD.POST || method === METHOD.PUT ? params : null,
			params: method === METHOD.GET || method === METHOD.DELETE ? params : null,
			...restConfig,
		}
		axiosInstance(config).then(
			(res) => {
				if (res?.data?.code === 0) {
					resolve(res.data)
				} else {
					message.error(res?.data?.msg)
					reject(res?.data?.msg)
				}
			},
			(err) => {
				message.error(err?.message)
				reject(err?.message)
			},
		)
	})
}

export default request
