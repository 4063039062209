import { memo, useState, useMemo, useRef, useEffect } from 'react'
import api from '@/api'
import { Button, Form, Input, Table, Space, Cascader, DatePicker, message, Alert, Tag as ATag } from 'antd'
import ListPage from '@/components/listPage'
import Search from '@/components/search'
import TimeSelect from '@/components/timeSelect'
import Curd from '@/components/curd'
import Copy from '@/components/copy'
import Tag from '@/components/tag'
import Btns from '@/components/btns'
import PicShow from '@/components/picShow'
import DicSelect from '@/components/dicSelect'
import { useAntdTable, useRequest } from 'ahooks'
import { SearchOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons'
import { dateToStr, tableConfig, arrDayToArrTimestamp, typeOptions, dayToTimestamp, echo, getReg, clone, icon, cascaderResultCompletion, markSelected, restoreSelectedValues, recursiveMerge, bindDataProcessing, filterConfig, commonColumnsIndex, commonColumnsCreatedAndUpdated } from '@/tools'
import dayjs from 'dayjs'
import { imgUrl } from '@/api/request'

const Page = memo(() => {
	const childRef = useRef(null)
	const [form] = Form.useForm()
	const [open, setOpen] = useState(false) // curd 窗口展示状态
	const [row, setRow] = useState({}) // 表格当前所选行数据
	const [type, setType] = useState('') // curd 窗口类型
	const [loading, setLoading] = useState(false) // 加载状态
	const { data: genderOptions } = useRequest(() => api.dicGetByKey({ key: 'gender' })) // 字典-性别
	const { data: userGroupAll } = useRequest(() => api.userGroupAll({}).then((res) => res?.data?.rows || [])) // 全部用户组数据-父级选项
	const { data: roleAll } = useRequest(() => api.roleAll({}).then((res) => res?.data?.rows || [])) // 全部角色数据-父级选项
	const genderEcho = useMemo(() => echo({ arr: genderOptions, labelField: '*' }), [genderOptions]) // 性别-回显数据

	const [tableSelectData, setTableSelectData] = useState([]) // 所选表格数据
	const [tableSelectKey, setTableSelectKey] = useState([]) // 所选表格key
	const [bindTableData, setBindTableData] = useState([]) // 关联表数据(当前选中权限 & 已关联的角色信息 的归并数据)

	const selectedRoleIds = useRef([]) // 单条绑定角色-选中的角色数据
	const selectedUserGroupIds = useRef([]) // 单条绑定用户组-选中的用户组数据

	const _typeOptions = {
		bindRole: '绑定角色',
		bindUserGroup: '绑定用户组',
		batchBindRole: '批量绑定角色',
		batchBindUserGroup: '批量绑定用户组',
	}

	// 列表数据
	const getTableData = ({ current, pageSize }, formData) => {
		const { name, eMail, profession, gender, birthday, createdAt, updatedAt } = formData

		const filter = {
			name,
			eMail,
			profession,
			gender,
			birthday: arrDayToArrTimestamp(birthday),
			createdAt: arrDayToArrTimestamp(createdAt),
			updatedAt: arrDayToArrTimestamp(updatedAt),
		}

		const req = {
			filter,
			page: current,
			limit: pageSize,
		}

		return api.userList(req).then((res) => {
			res.list = res?.data?.rows
			res.total = res?.data?.count
			return res
		})
	}
	const { tableProps, search: { submit, reset } } = useAntdTable(getTableData, { defaultPageSize: 100, form })

	// 表格列
	const getColumns = (type) => {
		const data = [
			commonColumnsIndex,
			{
				width: 260,
				title: '_id',
				dataIndex: '_id',
				render: (v) => <Copy>{v}</Copy>,
			},
			{
				width: 200,
				title: '用户名',
				dataIndex: 'name',
				render: (v, row) => (
					<Space>
						<PicShow src={row.pic ? `${imgUrl}/${row.pic}` : null} />
						<Copy>{v}</Copy>
					</Space>
				),
			},
			{ width: 200, title: '电子邮箱', dataIndex: 'eMail' },
			{ width: 200, title: '手机号', dataIndex: 'phone' },
			{
				width: 120,
				title: '生日',
				dataIndex: 'birthday',
				render: (v) => <span>{dateToStr(v, 'YYYY-MM-DD')}</span>,
			},
			{ width: 200, title: '职位', dataIndex: 'profession' },
			{
				width: 200,
				title: '性别',
				dataIndex: 'gender',
				render: (v) => <Tag options={genderEcho} value={v} />,
			},
			{ width: 200, title: '密码', dataIndex: 'password' },
			...commonColumnsCreatedAndUpdated,
			{
				width: 188,
				title: '操作',
				fixed: 'right',
				render: (v, row) => (
					<Space>
						<Button size="small" onClick={() => handle('get', row)}>查看</Button>
						<Button size="small" onClick={() => handle('set', row)}>修改</Button>
						<Button size="small" onClick={() => handle('del', row)} danger>删除</Button>
						<Button size="small" onClick={() => handle('bindRole', row)}>绑定角色</Button>
						<Button size="small" onClick={() => handle('bindUserGroup', row)}>绑定用户组</Button>
					</Space>
				),
			},
		]

		if (type === 'batchBindRole' || type === 'batchBindUserGroup') {
			return data.slice(0, 5).concat({
				width: 300,
				title: type === 'batchBindRole' ? '已绑角色' : '已绑用户组',
				dataIndex: 'bindData',
				render: (v) => v?.length ?
					v.map((i) => {
						if (type === 'batchBindRole') return <ATag key={i._id}>{i?.roleData?.[0]?.name || ''}</ATag>
						if (type === 'batchBindUserGroup') return <ATag key={i._id}>{i?.userGroupData?.[0]?.name || ''}</ATag>
					})
					: null,
			})
		} else {
			return data
		}
	}

	// 打开curd操作抽屉
	const handle = (type, row = {}) => {
		const newRow = { ...row }
		if (newRow.birthday) {
			newRow.birthday = dayjs(newRow.birthday)
		}

		setRow(newRow)
		setType(type)
		setOpen(true)
	}

	// curd操作
	const curdSubmit = async (formData, row, type) => {
		formData = { ...formData }

		const { birthday } = formData
		if (birthday) {
			formData.birthday = dayToTimestamp(birthday)
		}

		const init = () => {
			setLoading(false) // 取消加载状态
			setOpen(false) // 关闭窗口
			submit() // 刷新外层列表
			message.success('操作成功')
		}

		try {
			setLoading(true)

			switch (type) {
			case 'add':
				await api.userAdd({ data: formData })
				init()
				break

			case 'del':
				await api.userDel({ _id: row._id })
				init()
				break

			case 'set':
				await api.userSet({ ...formData, _id: row._id })
				init()
				break

			case 'get':
				setLoading(false)
				setOpen(false) // 关闭窗口
				break

			case 'bindUserGroup': // 绑定用户组
				setLoading(false)
				setOpen(false)
				break

			case 'bindRole': // 绑定角色
				setLoading(false)
				setOpen(false)
				break

			case 'batchBindUserGroup': // 批量绑定用户组
				await api.userGroup_userSave({
					userGroupIds: cascaderResultCompletion(formData?.userGroupIds, userGroupAll),
					userIds: tableSelectKey,
				})
				init()
				break

			case 'batchBindRole': // 批量绑定角色
				await api.user_roleSave({
					roleIds: cascaderResultCompletion(formData?.roleIds, roleAll),
					userIds: tableSelectKey,
				})
				init()
				break

			default:
				break
			}
		} catch (error) {
			setLoading(false)
			console.log('curd报错: ', error)
		}
	}

	// 表格选择
	const tableSelectChange = (_selectedRowKeys, _selectedRows) => {
		setTableSelectData(_selectedRows)
		setTableSelectKey(_selectedRowKeys)
	}

	// 绑定时 锁定无关表单
	const isSetFormDisabled = useMemo(() => (type === 'bindUserGroup' || type === 'bindRole' ? true : undefined), [type])

	// 单条权限 绑定/解绑 角色
	const bind = async (value, selectedOptions) => {
		const req = {
			// roleIds: cascaderResultCompletion(value, roleAll),
			userIds: [row._id],
		}

		const task = []

		switch (type) {
		case 'bindUserGroup': // 绑定用户组
			{
				bindDataProcessing({
					req,
					selectedIds: selectedUserGroupIds.current,
					dataAll: userGroupAll,
					targetIds: 'userGroupIds',
					apiDel: api.userGroup_userConditionalDel,
					apiSave: api.userGroup_userSave,
					task,
					value,
				})
			}
			break

		case 'bindRole': // 绑定角色
			{
				console.log('绑定角色', value)
				bindDataProcessing({
					req,
					selectedIds: selectedRoleIds.current,
					dataAll: roleAll,
					targetIds: 'roleIds',
					apiDel: api.user_roleConditionalDel,
					apiSave: api.user_roleSave,
					task,
					value,
				})
			}
			break

		default:
			break
		}

		const res = await Promise.allSettled(task) // 增/删
		const isSuccess = res.every(v => v?.value?.code === 0)

		if (isSuccess) {
			message.success('操作成功')
			getBindData() // 从新获取数据
		}
	}

	// 获取curd form配置
	const formConfig = useMemo(() => {
		const data = [
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '用户名',
					name: 'name',
				},
				render: <Input allowClear disabled={isSetFormDisabled} />,
			},
			{
				formItemProps: {
					rules: [{ required: true }, getReg('eMail')],
					label: '电子邮箱',
					name: 'eMail',
				},
				render: <Input allowClear disabled={isSetFormDisabled} />,
			},
			{
				formItemProps: {
					rules: [getReg('phone')],
					label: '手机号',
					name: 'phone',
				},
				render: <Input allowClear disabled={isSetFormDisabled} />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '密码',
					name: 'password',
				},
				render: <Input.Password allowClear disabled={isSetFormDisabled} />,
			},
			{
				formItemProps: { label: '性别', name: 'gender' },
				render: <DicSelect dicKey={'gender'} disabled={isSetFormDisabled} />,
			},
			{
				formItemProps: { label: '生日', name: 'birthday' },
				render: (
					<DatePicker allowClear style={{ width: '100%' }} placeholder='' disabled={isSetFormDisabled} />
				),
			},
			{
				formItemProps: { label: '职业', name: 'profession' },
				render: <Cascader allowClear expandTrigger="hover" disabled={isSetFormDisabled} />,
			},
		]

		switch (type) {
		case 'bindRole': // 绑定角色
			return [
				...data,
				{
					formItemProps: {
						label: '角色',
						name: 'roleIds',
					},
					render: (
						<Cascader
							allowClear
							options={roleAll}
							multiple
							expandTrigger='hover'
							onChange={bind}
							loading={loading}
							disabled={loading}
							{...filterConfig}
						/>
					),
				},
			]

		case 'bindUserGroup': // 绑定用户组
			return [
				...data,
				{
					formItemProps: {
						label: '用户组',
						name: 'userGroupIds',
					},
					render: (
						<Cascader
							allowClear
							options={userGroupAll}
							multiple
							expandTrigger='hover'
							onChange={bind}
							loading={loading}
							disabled={loading}
							{...filterConfig}
						/>
					),
				},
			]

		case 'batchBindRole': // 批量绑定角色
			return [
				{
					formItemProps: {
						label: '角色',
						name: 'roleIds',
						labelCol: { span: 2 },
						wrapperCol: { span: 22 },
					},
					render: (
						<Cascader
							allowClear
							options={roleAll}
							multiple
							expandTrigger='hover'
							{...filterConfig}
						/>
					),
				},
			]

		case 'batchBindUserGroup': // 批量绑定用户组
			return [
				{
					formItemProps: {
						label: '用户组',
						name: 'userGroupIds',
						labelCol: { span: 2 },
						wrapperCol: { span: 22 },
					},
					render: (
						<Cascader
							allowClear
							options={userGroupAll}
							multiple
							expandTrigger='hover'
							{...filterConfig}
						/>
					),
				},
			]

		default: // 增删改查
			return data
		}
	}, [type, row])

	// 获取已绑定数据
	const getBindData = async () => {
		setLoading(true)
	
		const req = { userIds: [row._id] }

		// 回显数据处理
		const bindCascaderEcho = (res, all) => {
			const selectedData = res?.data?.[0]?.data?.map((v) => type === 'bindRole' ? v.roleId : v.userGroupId) || []

			// 克隆
			const _all = clone(all)

			// 打标
			const markingData = markSelected(selectedData, _all)

			// 抽取
			const reductionData = restoreSelectedValues(markingData)

			const fd = {}

			switch (type) {
			case 'bindRole':
				fd.roleIds = reductionData
				selectedRoleIds.current = reductionData // 存储
				break

			case 'bindUserGroup':
				fd.userGroupIds = reductionData
				selectedUserGroupIds.current = reductionData // 存储
				break

			default:
				break
			}

			// 渲染
			childRef.current.form.setFieldsValue(fd)
			setLoading(false)
		}

		switch (type) {
		case 'bindRole':
			{
				const res = await api.user_roleFind(req)
				bindCascaderEcho(res, roleAll)
			}
			break
	
		case 'bindUserGroup':
			{
				const res = await api.userGroup_userFind(req)
				bindCascaderEcho(res, userGroupAll)
			}
			break
	
		default:
			break
		}
	}

	// 打开绑定窗口时查询并回显已绑定数据
	useEffect(() => {
		if (open) {
			try {
				const resEcho = (res) => {
					const dic = {}

					res?.data?.forEach(({ _id, data }) => {
						dic[_id] = data
					})

					const data = clone(tableSelectData)

					recursiveMerge(data, dic)

					console.log('表格数据归并', data)
					setBindTableData(data)
					setLoading(false)
				}

				switch (type) {
				case 'bindRole': // 绑定角色
					getBindData() // 获取并回显 已绑定数据
					break
	
				case 'bindUserGroup': // 绑定用户组
					getBindData() // 获取并回显 已绑定数据
					break
	
				case 'batchBindRole': // 批量绑定角色
					{
						setLoading(true)
						api.user_roleFind({ userIds: tableSelectKey }).then((res) => {
							resEcho(res)
						})
					}
					break
	
				case 'batchBindUserGroup': // 批量绑定用户组
					{
						setLoading(true)
						api.userGroup_userFind({ userIds: tableSelectKey }).then((res) => {
							resEcho(res)
						})
					}
					break
	
				default:
					break
				}
			} catch (error) {
				setLoading(false)
				console.log(error)
			}
		} else {
			setBindTableData([])
			selectedRoleIds.current = []
			selectedUserGroupIds.current = []
		}
	}, [open, type])

	return (
		<ListPage
			search={
				<Search
					form={form}
					submit={submit}
					formData={[
						{
							formItemProps: { name: 'name' },
							render: <Input placeholder="用户名" allowClear />,
						},
						{
							formItemProps: { name: 'eMail' },
							render: <Input placeholder="电子邮箱" allowClear />,
						},
						{
							formItemProps: { name: 'phone' },
							render: <Input placeholder="手机号" allowClear />,
						},
						{
							formItemProps: { name: 'gender' },
							render: <DicSelect placeholder="性别" dicKey={'gender'} />,
						},
						{
							formItemProps: { name: 'birthday' },
							render: <TimeSelect placeholder={['生日时间-开始', '生日时间-结束']} />,
							colPorps: { multiple: 2 },
						},
						{
							formItemProps: { name: 'createdAt' },
							render: <TimeSelect placeholder={['创建时间-开始', '创建时间-结束']} />,
							colPorps: { multiple: 2 },
						},
						{
							formItemProps: { name: 'updatedAt' },
							render: <TimeSelect placeholder={['更新时间-开始', '更新时间-结束']} />,
							colPorps: { multiple: 2 },
						},
						{
							formItemProps: { name: 'profession' },
							render: <Cascader placeholder="职业" allowClear expandTrigger="hover" />,
						},
					]}
					btnData={[
						{
							title: '搜索',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<SearchOutlined />}
									onClick={submit}
									loading={tableProps.loading}
								/>
							),
						},
						{
							title: '重置',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<ReloadOutlined />}
									onClick={reset}
								/>
							),
						},
						{
							title: '新增',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<PlusOutlined />}
									onClick={() => handle('add')}
								/>
							),
						},
					]}
				/>
			}
			count={tableSelectKey.length}
			actionsRight={
				<Btns
					data={[
						{
							title: '批量绑定角色',
							btn: (
								<Button
									type='primary'
									icon={icon('IdcardOutlined')}
									onClick={() => handle('batchBindRole')}
									disabled={!tableSelectKey?.length}
								/>
							),
						},
						{
							title: '批量绑定用户组',
							btn: (
								<Button
									type='primary'
									icon={icon('ClusterOutlined')}
									onClick={() => handle('batchBindUserGroup')}
									disabled={!tableSelectKey?.length}
								/>
							),
						},
					]}
				/>
			}
		>
			<Table
				rowKey={(v) => v._id}
				columns={getColumns()}
				{...tableProps}
				{...tableConfig({ tableProps })}
				rowSelection={{
					type: 'checkbox',
					onChange: tableSelectChange,
					selectedRowKeys: tableSelectKey,
					checkStrictly: false,
				}}
			/>

			<Curd
				ref={childRef}
				open={open}
				setOpen={setOpen}
				row={row}
				type={type}
				title={_typeOptions[type] ? _typeOptions[type] : `${typeOptions?.[type] || ''}用户`}
				width={type.includes('batch') ? 1000 : 800}
				formProps={{ labelCol: { span: 3 }, wrapperCol: { span: 21 } }}
				formConfig={formConfig}
				submit={curdSubmit}
				loading={loading}
				beforeChildren={
					type.includes('batch') ? (
						<>
							<Alert
								message="批量操作无法回显或删除已绑定数据, 如需查看或删除已绑定数据请单条数据处理"
								type="info"
								showIcon
								style={{ marginBottom: 10 }}
							/>
							<Table
								rowKey={(v) => v._id}
								columns={getColumns(type)}
								pagination={false}
								dataSource={bindTableData}
								scroll={{ y: 450 }}
								style={{ marginBottom: 20 }}
								loading={loading}
							/>
						</>
					) : null
				}
			/>
		</ListPage>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: false,
}
