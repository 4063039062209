import React, { memo, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Button, Drawer, Form, Space, Spin } from 'antd'

// 增删改查 弹窗
const Curd = forwardRef(({ ..._props }, ref) => {
	const {
		beforeChildren, // 之前
		afterChildren, // 之后
		row,
		type,
		setOpen,
		formProps = {},
		formConfig = [],
		submit = () => {},
		loading,
		...props
	} = _props

	const [form] = Form.useForm()

	useImperativeHandle(ref, () => ({ form })) // 暴露run方法给父组件调用

	useEffect(() => {
		if (props?.open) {
			form.setFieldsValue(row)
		} else {
			form.resetFields()
		}
	}, [props?.open, row])

	// 提交
	const _submit = (formData) => {
		if (typeof submit === 'function') {
			submit(formData, row, type)
		}
	}

	return (
		<Drawer
			placement="right"
			destroyOnClose={true}
			getContainer={'#drawerDisplayArea'}
			width={600}
			{...props}
			onClose={() => setOpen(false)}
			footer={
				<div style={{ textAlign: 'right' }}>
					<Space>
						<Button onClick={() => setOpen?.(false)}>取消</Button>
						<Button
							type="primary"
							onClick={formConfig?.length ? form.submit : submit({}, row, type)}
							loading={loading}
						>
              确认
						</Button>
					</Space>
				</div>
			}
		>
			{beforeChildren ? beforeChildren : null}
			{form !== undefined && formConfig?.length ? (
				<Spin spinning={loading}>
					<Form
						form={form}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 20 }}
						disabled={type === 'del' || type === 'get'}
						onFinish={_submit}
						{...formProps}
					>
						{formConfig.map((v) => {
							if (v?.formItemProps) {
								return (
									<Form.Item key={v.formItemProps.name} {...v.formItemProps}>
										{v.render}
									</Form.Item>
								)
							} else if (React.isValidElement(v.render)) {
								// 传入 <组件名/> 的时候直接渲染
								return v.render
							} else {
								// 传入 组件名 的时候透传form字段并渲染
								return <v.render form={form} key={v.formItemProps.name} />
							}
						})}
					</Form>
				</Spin>
			) : null}
			{afterChildren ? afterChildren : null}
		</Drawer>
	)
})

export default memo(Curd)
