// 搜索栏表单渲染
import { Col, Form } from 'antd'

const SearchFormItem = (props) => {
	const {
		data,
		grid = { xs: 24, sm: 24, md: 12, lg: 8, xl: 6, xxl: 4 },
		isNeedFormItem = true,
	} = props
	// xs屏幕 < 576px    sm屏幕 ≥ 576px    md屏幕 ≥ 768px    lg屏幕 ≥ 992px    xl屏幕 ≥ 1200px   xxl屏幕 ≥ 1600px

	return data.map((v, i) => {
		const _render = typeof v.render === 'function' ? v.render() : v.render

		const multiple = v?.colPorps?.multiple || 1

		const gridClac = (base, multiple) => {
			const res = base * multiple
			if (res > 24) {
				return 24
			} else {
				return res
			}
		}

		const newGrid = {
			xs: gridClac(grid.xs, multiple),
			sm: gridClac(grid.sm, multiple),
			md: gridClac(grid.md, multiple),
			lg: gridClac(grid.lg, multiple),
			xl: gridClac(grid.xl, multiple),
			xxl: gridClac(grid.xxl, multiple),
		}

		return (
			<Col
				key={data.key || i}
				{...(v?.colPorps?.span ? v.colPorps.span : v.grid ? v.grid : newGrid)}
				{...v.colPorps}
			>
				{isNeedFormItem ? (
					<Form.Item {...v.formItemProps}>{_render}</Form.Item>
				) : (
					<>{_render}</>
				)}
			</Col>
		)
	})
}

export default SearchFormItem
