// 初始化并配置持久化
import { atom, selector } from 'recoil'

// 永久级状态
const localStorageEffect = (key) => ({ setSelf, onSet }) => {
	const savedValue = localStorage.getItem(key)
	if (savedValue != null) {
		try {
			setSelf(JSON.parse(savedValue))
		} catch (error) {
			console.log('从localStorage中初始化state报错: ', error)
		}
	}
	onSet((newValue) => {
		try {
			localStorage.setItem(key, JSON.stringify(newValue))
		} catch (error) {
			console.log('state存入localStoragee报错: ', error)
		}
	})
}

// 会话级状态
const sessionStorageEffect = (key) => ({ setSelf, onSet }) => {
	const savedValue = sessionStorage.getItem(key)

	if (savedValue != null) {
		try {
			setSelf(JSON.parse(savedValue))
		} catch (error) {
			console.log('从sessionStorage中初始化state报错: ', error)
		}
	}
	
	onSet((newValue) => {
		try {
			sessionStorage.setItem(key, JSON.stringify(newValue))
		} catch (error) {
			console.log('state存入sessionStorage报错: ', error)
		}
	})
}

// 将数据存入window下 (用于特殊情况的动态监听)
const windowEffect = (key, type) => ({ setSelf, onSet }) => {
	const savedValue = window[type].getItem(key)
	if (savedValue != null) {
		try {
			window.__app__[key] = JSON.parse(savedValue)
		} catch (error) {
			console.log('从localStorage中初始化state报错: ', error)
		}
	}
	onSet((newValue) => {
		try {
			window.__app__[key] = newValue
		} catch (error) {
			console.log('state存入window.__app__报错: ', error)
		}
	})
}

// 左栏折叠状态
export const leftCollapsed = atom({
	key: 'leftCollapsed',
	default: false,
	effects_UNSTABLE: [localStorageEffect('leftCollapsed')],
})

// 左菜单栏数据
export const leftMenuData = atom({
	key: 'leftMenuData',
	default: [],
	effects_UNSTABLE: [sessionStorageEffect('leftMenuData')],
})

// 左栏菜单选中数据(用于面包屑渲染)
export const leftMenuSelectedData = atom({
	key: 'leftMenuSelectedData',
	default: [],
	effects_UNSTABLE: [sessionStorageEffect('leftMenuSelectedData')],
})

// 顶栏菜单选中数据(用于面包屑渲染)
export const topMenuSelectedData = atom({
	key: 'topMenuSelectedData',
	default: {},
	effects_UNSTABLE: [sessionStorageEffect('topMenuSelectedData')],
})

// 顶栏菜单选中key
export const topMenuSelectedKeys = atom({
	key: 'topMenuSelectedKeys',
	default: {},
	effects_UNSTABLE: [sessionStorageEffect('topMenuSelectedKeys')],
})

// 视口区域-尺寸
export const windowSize = atom({
	key: 'windowSize',
	default: { width: 0, height: 0 },
	effects_UNSTABLE: [sessionStorageEffect('windowSize')],
})

// 布局容器-内容区域-尺寸
export const layoutInnterSize = atom({
	key: 'layoutInnterSize',
	default: { width: 0, height: 0 },
	effects_UNSTABLE: [sessionStorageEffect('layoutInnterSize')],
})

// 面包屑尺寸
export const breadcrumbSize = atom({
	key: 'breadcrumbSize',
	default: { width: 0, height: 0 },
	effects_UNSTABLE: [sessionStorageEffect('breadcrumbSize')],
})

// 顶栏尺寸
export const headerSize = atom({
	key: 'headerSize',
	default: { width: 0, height: 0 },
	effects_UNSTABLE: [sessionStorageEffect('headerSize')],
})

// 列表页面-批量操作区域尺寸
export const actionsSize = atom({
	key: 'actionsSize',
	default: { width: 0, height: 0 },
	effects_UNSTABLE: [sessionStorageEffect('actionsSize')],
})

// 列表页面-搜索栏区域尺寸
export const searchSize = atom({
	key: 'searchSize',
	default: { width: 0, height: 0 },
	effects_UNSTABLE: [sessionStorageEffect('searchSize')],
})

// 虚拟表格高度
export const virtualTableHeight = atom({
	key: 'virtualTableHeight',
	default: { height: 100 },
	effects_UNSTABLE: [sessionStorageEffect('virtualTableHeight')],
})

// 图形验证码id
export const imageCaptchaId = atom({
	key: 'imageCaptchaId',
	default: '',
	effects_UNSTABLE: [sessionStorageEffect('imageCaptchaId')],
})

// 邮箱验证码id
export const eMailCaptchaId = atom({
	key: 'eMailCaptchaId',
	default: '',
	effects_UNSTABLE: [sessionStorageEffect('eMailCaptchaId')],
})

// 手机验证码id
export const phoneCaptchaId = atom({
	key: 'phoneCaptchaId',
	default: '',
	effects_UNSTABLE: [sessionStorageEffect('phoneCaptchaId')],
})

// 是否展示登录弹窗
export const isShowLogin = atom({
	key: 'isShowLogin',
	default: false,
	effects_UNSTABLE: [sessionStorageEffect('isShowLogin')],
})

// 用户信息
export const userInfo = atom({
	key: 'userInfo',
	default: null,
	effects_UNSTABLE: [localStorageEffect('userInfo'), windowEffect('userInfo', 'localStorage')],
})

// 用户信息-仅用户数据 (派生数据)
export const user = selector({
	key: 'user',
	default: {},
	get: ({ get }) => get(userInfo)?.userInfo || {},
	effects_UNSTABLE: [localStorageEffect('user')],
})

// 用户是否登录 (派生数据)
export const isLogin = selector({
	key: 'isLogin',
	default: false,
	get: ({ get }) => Boolean(get(userInfo)?.userInfo),
	effects_UNSTABLE: [localStorageEffect('isLogin')],
})

// 页面权限数据 (派生数据)
export const pageAuthority = selector({
	key: 'pageAuthority',
	default: [],
	get: ({ get }) => {
		try {
			const res = Object.values(get(userInfo)?.permissions?.permissionsDetail?.url) || []
			return res
		} catch (error) {
			return []
		}
	},
	effects_UNSTABLE: [localStorageEffect('pageAuthority')],
})

// 权限数据 (派生数据)
export const permissionsData = selector({
	key: 'permissionsData',
	default: [],
	get: ({ get }) => {
		try {
			const res = Object.values(get(userInfo)?.permissions?.permissionsData) || []
			return res
		} catch (error) {
			return []
		}
	},
	effects_UNSTABLE: [localStorageEffect('permissionsData')],
})

// --------------------------------------------------- 首页 ↓ ---------------------------------------------------
// 搜索的关键词
export const homeSearchBar = atom({
	key: 'homeSearchBar',
	default: {
		searchType: 1,
		// searchText: '',
	},
	effects_UNSTABLE: [sessionStorageEffect('homeSearchBar')],
})

// 是否展示list
export const homeIsShowList = atom({
	key: 'homeIsShowList',
	default: false,
	effects_UNSTABLE: [localStorageEffect('homeIsShowList')],
})

// 分类数据
export const classAll = atom({
	key: 'classAll',
	default: [{ label: '全部', key: '1', closable: false }],
	effects_UNSTABLE: [sessionStorageEffect('classAll')],
})

// 当前选择的分类
export const currentSelectClass = atom({
	key: 'currentSelectClass',
	default: '1',
	effects_UNSTABLE: [sessionStorageEffect('currentSelectClass')],
})

// 分页配置(首页)
export const cardListPaginationData = atom({
	key: 'cardListPaginationData',
	default: {
		current: 1,
		pageSize: 23,
		pageSizeOptions: [11, 23, 47, 71, 95],
		total: 12,
	},
	effects_UNSTABLE: [sessionStorageEffect('cardListPaginationData')],
})

// 卡片列表数据
export const cardData = atom({
	key: 'cardData',
	default: [1,2,3],
	effects_UNSTABLE: [sessionStorageEffect('cardData')],
})

// 当前(添加的)卡片信息
export const currentCardData = atom({
	key: 'currentCardData',
	default: {},
	effects_UNSTABLE: [sessionStorageEffect('currentCardData')],
})
// --------------------------------------------------- 首页 ↑ ---------------------------------------------------

