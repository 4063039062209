// 搜索栏 功能按钮渲染
import { Space, Form, Tooltip, Col } from 'antd'
import React, { memo } from 'react'

const Btns = memo((props) => {
	const { data, multiple = 1 } = props
	const grid = {
		md: 12 * multiple,
		lg: 8 * multiple,
		xl: 6 * multiple,
		xxl: 4 * multiple,
	}

	return (
		<Col {...grid}>
			<Form.Item>
				<Space>
					{data.map((v) => (
						<Tooltip placement="top" title={v.title} key={v.title}>
							{v.btn}
						</Tooltip>
					))}
				</Space>
			</Form.Item>
		</Col>
	)
})

export default Btns
