import { memo, useState, useEffect } from 'react'
import api from '@/api'
import { useRequest } from 'ahooks'
import { Spin, Row, Col, Card, Space, Typography, Button, Empty, Flex } from 'antd'
import Pic from '@/components/pic'
import { icon, findFastestURL } from '@/tools'
import { useRecoilState } from 'recoil'
import { userInfo as _userInfo } from '@/state'


const Box = memo((props) => {
	const { _id, userId, query = {} } = props
	const [loading, setLoading] = useState({})
	const [userInfo] = useRecoilState(_userInfo) // 用户信息

	const colGap = 16 // 列间隙
	const lineGap = 16 // 行间隙

	// 获取 类下的卡片
	const { data: cardData, loading: classLoading, run } = useRequest(
		() => {
			const { searchText: keyword } = query
			console.log('keyword', keyword)

			const req = {
				filter: {
					classId: Array.isArray(_id) ? _id : [_id],
					userId,
					keyword,
				}
			}

			return api.userCardGetOfficialCardByClassId(req).then(res => res?.data?.rows || [])
		}
	)

	useEffect(() => {
		run()
	}, [query])

	// 添加卡片
	const add = (e, options) => {
		e.stopPropagation()
		console.log(options)
	}

	// 卡片渲染
	const renderCard = (options) => {
		const { key, icons, title, description, type, url } = options
		const isAdd = type === 'add'

		const clickCard = async (key) => {
			// 用户开启了跳转优化 并且 有备用地址是
			if (userInfo?.config?.optimizeJump && options?.all?.cardData?._backupUrlList?.length) {

				const loadingData = { ...loading }
				loadingData[key] = true
				console.log(loadingData)
				setLoading(loadingData)
			
				const urls = [options.url, ...options.all.cardData._backupUrlList.map(v => v.backupUrl)]
			
				console.time('测试相应最快url耗时')
				const _url = await findFastestURL(urls) // 查找响应速度最快的url
			
				setLoading({})
			
				window.open(_url)
				console.timeEnd('测试相应最快url耗时')
			
				// 都不响应 且 又发布器地址 跳转发布器让用户自行选择
				// options?.all?.cardData?._backupUrlList?._publisher
			} else {
				window.open(url)
			}
		}

		return (
			<Col
				xs={24}
				sm={24}
				md={12}
				lg={8}
				xl={6}
				xxl={4}
				key={key}
			>
				<Spin spinning={loading[key] ? true : false}>
					<Card
						size='small'
						title={
							<Space style={{ width: '100%' }}>
								<Pic src={icons} size={20} />
								<p>{title}</p>
							</Space>
						}
						style={{
							width: '100%',
							cursor: 'pointer',
							border: isAdd ? '1px dashed #f0f0f0' : undefined,
						}}
						onClick={() => clickCard(key)}
					// 收藏按钮 ↓
					// extra={<Button type='text' icon={icon('PlusOutlined')} size='small' onClick={(e) => add(e, options)} />}
					>
						<Space style={{ width: '100%' }} direction='vertical'>
							<Typography.Paragraph style={{ fontSize: 11, height: 34.5, margin: 0 }} ellipsis={{ rows: 2 }}>
								{description}
							</Typography.Paragraph>
						</Space>
					</Card>
				</Spin>
			</Col>
		)
	}

	return <Spin spinning={classLoading}>
		<Row gutter={[colGap, lineGap]}>
			{
				cardData?.map(v => renderCard({
					key: v?._id,
					icons: [v?.cardData?.appleTouchIcon, v?.cardData?.icon, v?.cardData?.shortcutIcon],
					title: v?.customizeTitle || v?.cardData?.title,
					description: v?.customizeDescription || v?.cardData?.description,
					url: v?.cardData?.url,
					all: v
				}))
			}
			{
				!cardData?.length ? 
					<Flex justify='center' style={{ width: '100%' }}>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: 0 }} />
					</Flex>
					: null
			}
		</Row>
	</Spin>
})

export default Box