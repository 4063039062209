import { memo, useEffect, useState } from 'react'
import { Input, Space } from 'antd'
import ColorSelcet from './colorSelcet'

const ColorSelcetGroup = memo((props) => {
	const {
		value,
		onChange,
		disabled,
		..._props
	} = props

	const [_value, _setValue] = useState()
	const [colorVal, setColorVal] = useState()

	// 色卡 dic
	const pantone = {
		processing: '#1677FF',
		success: '#52C41A',
		error: '#FF4D4F',
		warning: '#FAAD14',
		default: '#1C1C1C',
	}

	useEffect(() => {
		_setValue(value) // 输入框内容回显

		// 颜色选择器回显
		if (value?.startsWith('#')) {
			setColorVal(value)
		} else {
			setColorVal(pantone[value])
		}
	}, [value])

	// 点击预设颜色值
	const onClickTag = (v) => {
		const { value, label, color } = v

		// 把预设颜色的还原成色值回显到颜色选择器
		setColorVal(color)

		_setValue(value)
		if (typeof onChange === 'function') {
			onChange(value)
		}
	}

	// 颜色选择器取值变化
	const onColorChange = (color, colorStr) => {
		setColorVal(colorStr)
		const newColorStr = colorStr?.length === 9 && colorStr.endsWith('00') ? undefined : colorStr

		_setValue(newColorStr)

		if (typeof onChange === 'function') {
			// 不是以 # 开头的字符串 (很可能是 颜色预设值)

			if (typeof newColorStr === 'string' && !newColorStr.startsWith('#')) {
				onChange(pantone[newColorStr])
			} else {
				onChange(newColorStr)
			}
		}
	}

	// 打开颜色选择器时 如果是完全透明的初始值则去掉透明度(防止给用户造成修了个没有效果的假象)
	const onOpenChange = (open) => {
		if (open) {
			if (colorVal?.length === 9 && colorVal.endsWith('00')) {
				setColorVal(v => v.slice(0, -2))
			} else if (colorVal === undefined) {
				setColorVal('#ffffff')
			}
		}
	}

	return (
		<Space.Compact style={{ width: '100%' }}>
			<Input
				value={_value}
				onChange={onChange}
				allowClear
				style={{
					backgroundColor: disabled ? 'rgba(0, 0, 0, 0.04)' : '#fff',
					color: disabled ? 'rgba(0, 0, 0, 0.04)' : 'rgba(0, 0, 0, 0.88)',
				}}
				{..._props}
				autoComplete={'off'}
				disabled
			/>

			<ColorSelcet
				style={{
					borderLeft: 0,
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
				}}
				onClickTag={onClickTag}
				onChange={onColorChange}
				value={colorVal}
				onOpenChange={onOpenChange}
				disabled={disabled}
			/>
		</Space.Compact>
	)
})

export default ColorSelcetGroup
