import { memo, useMemo } from 'react'
import { Form, Radio, Switch, Space, message } from 'antd'
import ShowData from '../showData'
import api from '@/api'
import FormItmeRender from '@/components/formItmeRender'
import ProblemExplanation from '@/components/problemExplanation'
import { useRequest } from 'ahooks'
import { useRecoilState } from 'recoil'
import { userInfo as _userInfo } from '@/state'


// 偏好设置
const Preference = memo(() => {
	const [form] = Form.useForm()
	const { data: cacheAgingOptions } = useRequest(() => api.dicGetByKey({ key: 'cacheAging' })) // 字典-缓存时效
	const [userInfo, setUserInfo] = useRecoilState(_userInfo) // 用户信息

	const initialValues = useMemo(() => {
		if (userInfo?.config?._id) {
			return userInfo.config // 用户配置
		} else {
			// 表单默认值
			return {
				cacheAging: 604800000, // 缓存时效
				personalizedRecommendation: true, // 个性化推荐
				optimizeJump: false, // 跳转优化
			}
		}
	}, [userInfo])

	// 表单数据
	const data = [
		{
			label: (
				<Space size={5}>
					缓存时效
					<ProblemExplanation text={'长时间不使用 多久需要重新登录'} />
				</Space>
			),
			value: (
				<FormItmeRender
					data={[
						{
							formItemProps: {
								name: 'cacheAging',
							},
							render: (<Radio.Group options={cacheAgingOptions || []} />),
						}
					]}
				/>
			),
			right: '',
		},
		{
			label: '语言', // language
			value: '中文',
			right: '',
		},
		{
			label: (
				<Space size={5}>
					个性化推荐
					<ProblemExplanation text={'根据当前用户信息及喜好 优化推荐结果'} />
				</Space>
			),
			value: (
				<FormItmeRender
					data={[
						{
							formItemProps: {
								name: 'personalizedRecommendation',
								valuePropName: 'checked',
							},
							render: (<Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked />),
						}
					]}
				/>
			),
			right: '',
		},
		{
			label: (
				<Space size={5}>
					优化跳转
					<ProblemExplanation text={'跳转某个网站标签时 如该网站有多个网址默认打开响应速度最快的网址'} />
				</Space>
			),
			value: (
				<FormItmeRender
					data={[
						{
							formItemProps: {
								name: 'optimizeJump',
								valuePropName: 'checked',
							},
							render: (<Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked />),
						}
					]}
				/>
			),
			right: '',
		},
	]

	// 单条数据修改
	const attributeSubmit = async (formData, item) => {
		const req = {}
		req[item.formConfig.formItemProps.name] = formData[item.formConfig.formItemProps.name]

		console.log(req)
		// await api.userSetPhone(req)
		message.success('操作成功')
	}

	// 模态窗数据修改
	const modalSubmit = async (formData, modalFormItemData) => {
		try {
			const fields = modalFormItemData.map(v => v.formItemProps.name)

			// let res = null
			// 修改邮箱
			// if (fields.includes('eMail')) {
			// 	res = await api.userSetEMail({ ...formData, imageCaptchaId })
			// }

			// setUserInfo(res.data)
			return true
		} catch (error) {
			// childRef?.current?.run?.() // 重新获取验证码
			console.log(error)
			return false
		}
	}

	// 字段修改
	const onValuesChange = async (changedValues, allValues) => {
		const req = {
			...allValues,
			// userId: userInfo.userInfo._id
		}
		const res = await api.userConfigSave(req)
		setUserInfo(res.data)
		message.success('操作成功')
	}

	return (
		<Form
			layout='vertical'
			wrapperCol={{ span: 24 }}
			form={form}
			initialValues={initialValues}
			onValuesChange={onValuesChange}
		>
			<ShowData
				data={data}
				form={form}
				attributeSubmit={attributeSubmit}
				modalSubmit={modalSubmit}
			/>
		</Form>
	)
})

export default Preference