// 差集 a-b
export const difference = (A, B) => [...new Set(A.filter(v => !new Set(B).has(v)))]

// 并集 a+b
export const union = (...arr) => [...new Set(arr.flat(1))]

// 交集 A∪B
export const intersection = (A, B) => [...new Set(A.filter(v => B.includes(v)))]

// 对称集 A△B = (A-B)∪(B-A)
export const symmetricSet = (A, B) => [...new Set([...difference(A, B), ...difference(B, A)])]

// 数组对象按某属性去重
//                入参  去重key(多层可以 用.链接)  是否翻转 true前面覆盖后面 fasle后面的数据覆盖前面
export const unionKey = (data, key, reverse = false) => {
	let hash = {}

	const keys = []
	if (key.includes('.')) {
		keys.push(...key.split('.'))
	}
	// 
	const getKeyStr = (keys) => {
		if (keys.length) {
			return `curVal${keys.map(v => '[' + `'${v}'` + ']').join('')}`
		} else {
			return 'curVal[key]'
		}
	}


	const unionData = data[reverse ? 'reduceRight' : 'reduce']((preVal, curVal) => {
		// hash[curVal[key]] ? '' : hash[curVal[key]] = true && preVal.push(curVal);
		const depthVisit = getKeyStr(keys)
		eval(`hash[${depthVisit}] ? '' : hash[${depthVisit}] = true && preVal.push(curVal);`)
		// hash[getKeyStr(keys)] ? '' : hash[getKeyStr(keys)] = true && preVal.push(curVal);
		return preVal
	}, [])

	return unionData
}

// 按照对象数组的中 对象的某个key排序
export const sortBy = (arr, property, order = true) => arr.sort((a,b) => order ? (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? - 1 : 0) : (a[property] < b[property]) ? 1 : ((b[property] < a[property]) ? - 1 : 0) )

// 按 str 处理连续字符 1..2 => 1.2
export const continuousCharacterProcessing = (data, str) => {
	const arr = data.split('')
	let start = -1
	let end = -1
	for (let i = arr.length - 1; i >= 0; i--) {
		const s = arr[i]
		if (s === str) {
			if (end === -1) end = i
			else start = i
		} else {
			if (start >= 0 && end >= 0) arr.splice(start, end - start)
			end = -1
			start = -1
		}
	}
	if (start >= 0 && end >= 0) arr.splice(start, end - start) // 程序边界处理
	return arr.join('')
}

export default {
	difference,
	union,
	intersection,
	symmetricSet,
	unionKey,
	sortBy,
	continuousCharacterProcessing,
}