import { memo, useEffect } from 'react'
import { Flex, Input, Space, Select, Form, message } from 'antd'
import styles from './index.module.scss'
import { useRequest } from 'ahooks'
import { useRecoilState } from 'recoil'
import { cardData as _cardData, homeSearchBar as _homeSearchBar, currentSelectClass as _currentSelectClass, homeIsShowList as _homeIsShowList, cardListPaginationData as _cardListPaginationData, isLogin as _isLogin, isShowLogin as _isShowLogin } from '@/state'
import api from '@/api'
import { useNavigate } from 'react-router-dom'
import { appSave } from '@/tools' // pinyin_to_hanzi


const SearchBar = memo(() => {
	const navigate = useNavigate()
	const [cardData, setCardData] = useRecoilState(_cardData) // card数据
	const [homeSearchBar, setHomeSearchBar] = useRecoilState(_homeSearchBar) // 搜索栏数据
	const [currentSelectClass, setCurrentSelectClass] = useRecoilState(_currentSelectClass) // 当前选择的分类
	const [cardListPaginationData, setCardListPaginationData] = useRecoilState(_cardListPaginationData) // 分页参数
	const [homeIsShowList, setHomeIsShowList] = useRecoilState(_homeIsShowList) // 是否展示<更多>列表
	const [isLogin] = useRecoilState(_isLogin) // 是否登录
	const [, setIsShowLogin] = useRecoilState(_isShowLogin) // 是否展示登录窗口
	const [form] = Form.useForm()
	const { data: searchTypeSelectOptions } = useRequest(() => api.searchTypeAll({}).then((res) => res?.data?.rows || [])) // 全部菜单数据

	// 回显搜索栏内容
	useEffect(() => {
		form.setFieldsValue(homeSearchBar)
	}, [homeSearchBar])

	const getSession = (key) => JSON.parse(window.sessionStorage.getItem(key))

	// 获取 card 数据
	// 当 search 方法暴露到全局时 useRecoilState 的动态监听会失效所以兜底参数要从 sessionStorage 中获取
	const search = async (options = {}) => {
		const { keyword, cardListPaginationData: paginationData, currentSelectClass: label } = options

		// 如果调用 search 的时候有传参优先使用传进来的参数 否者没有 传进来的参数使用 state 中的数据
		const req = {
			filter: {},
			// limit: 100,
			// page: 1,
		}

		// 分类
		if (label) {
			req.filter.classId = [label]
		} else {
			const sessionStorageCurrentSelectClass = getSession('currentSelectClass') || ''
			sessionStorageCurrentSelectClass ? req.filter.classId = [sessionStorageCurrentSelectClass] : req.filter.classId = undefined
		}

		if (Array.isArray(req.filter.classId)) {
			req.filter.classId = req.filter.classId.filter(v => v !== '1') // 排除全部
		}

		// 搜索内容
		if (keyword) {
			req.filter.keyword = keyword
		} else {
			const sessionStorageHomeSearchBar = getSession('homeSearchBar') || {}
			if (sessionStorageHomeSearchBar?.searchType === 2) {
				req.filter.keyword = sessionStorageHomeSearchBar.searchText
			} else {
				req.filter.keyword = undefined
			}
		}

		// 分页
		if (paginationData) {
			req.page = paginationData.current
			req.limit = paginationData.pageSize
		} else {
			const sessionStorageCardListPaginationData = getSession('cardListPaginationData') || {}
			req.page = sessionStorageCardListPaginationData?.current || 1
			req.limit = sessionStorageCardListPaginationData?.pageSize || 23
		}

		if (isLogin) {
			const res = await api.userCardFind(req)
			setCardData(res?.data?.rows || []) // 更新卡片数据
			setCardListPaginationData(v => ({ ...v, total: res?.data?.count || 0 })) // 更新分页数据
			console.log('搜索我的书签', req, res)
		}
	}

	// 初始化卡片数据
	useEffect(() => {
		if (isLogin, homeIsShowList) {
			// 等token写入state
			setTimeout(() => {
				search()
			}, 0)
		}
	}, [isLogin, homeIsShowList])

	// search方法暴露到全局
	useEffect(() => {
		if (typeof search === 'function' && typeof window?.__app__?.search !== 'function') {
			appSave('search', search)
		}
	}, [search])

	// 搜索
	const onSearch = (v) => {
		const { searchText, searchType } = form.getFieldsValue()

		if (searchType === 1) { // 本站
			navigate(`/recommend?searchText=${searchText}`) // 本站: 带参数跳转 /recommend
		} else if (searchType === 2) { // 我的书签
			// 判断是否登录
			if (!isLogin) {
				setIsShowLogin(true)
				return
			}

			// <更多>打开
			if (!homeIsShowList) {
				setHomeIsShowList(true)
			}

			// tabs 切换至 all 并按关键词搜索
			setCurrentSelectClass('1')
			search({ keyword: searchText, currentSelectClass: '1' })

			navigate('/') // 本站: 带参数跳转 /recommend
		} else {
			const queryStr = searchTypeSelectOptions.filter(v => v.value === searchType)[0].queryStr.replace(/\${searchText}/g, searchText)
			window.open(queryStr) // 站外: 直接跳转
		}
	}

	// 搜索内容变化(拉热词)
	const onSearchChange = (v) => {
		// 如果输入的内容只有pinyin则转汉字
		// pinyin_to_hanzi(v)

		// console.log(v)
	}

	// 输入内容变化
	const onValuesChange = (changedValues, allValues) => {
		// 同步到状态管理工具中
		setHomeSearchBar(allValues)

		// 同步到url
	}

	return <Flex justify='space-between' className={styles.search}>
		<div />

		<div className={styles.searchBox}>
			<Form
				form={form}
				onValuesChange={onValuesChange}
				initialValues={{ searchType: 1 }}
			>
				<Space.Compact className={styles.spaceCompact}>
					<Form.Item name="searchType">
						<Select
							options={searchTypeSelectOptions}
							size="large"
							className={styles.searchTypeSelect}
						/>
					</Form.Item>

					<Form.Item name="searchText" style={{ flex: 1 }}>
						<Input.Search
							enterButton="搜索"
							size="large"
							onSearch={onSearch}
							onChange={(e) => onSearchChange(e.target.value)}
						/>
					</Form.Item>
				</Space.Compact>
			</Form>
		</div>

		<div>
			{/* {JSON.stringify(homeSearchBar)} */}
		</div>
	</Flex>
})

export default SearchBar
