import React, { memo, useMemo } from 'react'
import { Form, Input, Button, Space, Row, Col, Tooltip, Switch, InputNumber } from 'antd'
import { icon } from '@/tools'
import ColorSelcetGroup from '@/components/colorSelcetGroup'

const RecursionFormList = memo(({ fields, lv, currentType, disabled }) => { // type = [], row, open
	const currentTypeIsBool = useMemo(() => currentType === 'boolean', [currentType])

	return (
		<div
			style={{
				padding: 10,
				borderRadius: 10,
				border: '1px solid #eee',
				backgroundColor: 'rgba(0,0,0,.05)',
			}}
		>
			<Form.List name={[fields.name, 'children']}>
				{(formFields, { add, remove, move }) => (
					<>
						{formFields.map((field, index) => (
							<Row key={field.key} gutter={[10, 0]}>
								<Col span={5}>
									<Form.Item
										colon={false}
										name={[field.name, 'label']}
										// label={'选项'}
										// labelCol={{ span: 8 }}
										wrapperCol={{ span: 24 }}
										rules={[{ required: true }]}
									>
										<Input allowClear placeholder="选项" />
									</Form.Item>
								</Col>

								<Col span={5}>
									<Form.Item
										colon={false}
										name={[field.name, 'value']}
										labelCol={{ span: currentTypeIsBool ? 12 : 0 }}
										wrapperCol={{ span: currentTypeIsBool ? 12 : 24 }}
										rules={[
											{ required: true },
											({ getFieldValue }) => ({
												validator(_, value) {
													if (
														typeof value === currentType || value === undefined
													) {
														if (value === '') {
															return Promise.reject(new Error('请输入'))
														} else {
															return Promise.resolve()
														}
													} else {
														return Promise.reject(
															new Error('<取值>填写错误, 请尝试重新输入'),
														)
													}
												},
											}),
										]}
										label={currentTypeIsBool ? '取值' : undefined}
										valuePropName={currentTypeIsBool ? 'checked' : undefined}
									>
										{currentType === 'string' ? (
											<Input allowClear placeholder={'取值'} />
										) : currentType === 'number' ? (
											<InputNumber
												allowClear
												placeholder={'取值'}
												style={{ width: '100%' }}
											/>
										) : currentType === 'boolean' ? (
											<Switch checkedChildren="开启" unCheckedChildren="关闭" />
										) : null}
									</Form.Item>
								</Col>

								<Col span={5}>
									<Form.Item
										colon={false}
										name={[field.name, 'color']}
										wrapperCol={{ span: 24 }}
									>
										<ColorSelcetGroup placeholder="颜色" disabled={disabled} />
									</Form.Item>
								</Col>

								<Col span={4}>
									<Form.Item
										colon={false}
										name={[field.name, 'disabled']}
										label={'禁用'}
										valuePropName={'checked'}
										labelCol={{ span: 12 }}
										wrapperCol={{ span: 12 }}
									>
										<Switch />
									</Form.Item>
								</Col>

								<Col span={5}>
									<Space>
										<Space size={5}>
											<Tooltip placement="top" title={'删除选项'}>
												<Button
													onClick={() => remove(field.name)}
													icon={icon('DeleteOutlined')}
													disabled={lv === 1 ? formFields.length <= 1 : false}
												/>
											</Tooltip>

											<Tooltip placement="top" title={'上移'}>
												<Button
													onClick={() => move(index, index - 1)}
													icon={icon('ArrowUpOutlined')}
													disabled={index === 0}
												/>
											</Tooltip>

											<Tooltip placement="top" title={'下移'}>
												<Button
													onClick={() => move(index, index + 1)}
													icon={icon('ArrowDownOutlined')}
													disabled={index === formFields.length - 1}
												/>
											</Tooltip>

											{/* <Tooltip placement='top' title={'添加选项'}>
                        <Button
                          onClick={() => add()}
                          icon={icon('PlusOutlined')}
                        />
                      </Tooltip> */}
										</Space>
									</Space>
								</Col>

								<Col span={24}>
									<Form.Item
										name={[field.name, 'children']}
										labelCol={{ span: 0 }}
										wrapperCol={{ span: 24 }}
									>
										<RecursionFormList
											fields={field}
											lv={lv + 1}
											currentType={currentType}
											disabled={disabled}
											// type={type}
											// row={row}
											// open={open}
										/>
									</Form.Item>
								</Col>
							</Row>
						))}

						<Button onClick={() => add()} size="small" block>
							新增 {lv} 级子项
						</Button>
					</>
				)}
			</Form.List>
		</div>
	)
})

export default RecursionFormList
