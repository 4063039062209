import { memo } from 'react'
import { Typography, Image } from 'antd'
import styles from './index.module.scss'

const { Title } = Typography

const TitleBox = memo((props) => {
	const { src, title, desc, width = 300 } = props

	return (
		<div className={styles.titleBox}>
			<div  className={styles.titleLeft}>
				<Title level={3} className={styles.title}>{title}</Title>
				<Typography className={styles.text}>{desc}</Typography>
			</div>

			<div className={styles.titleRight}>
				<Image
					preview={false}
					width={width}
					src={src}
				/>
			</div>
		</div>
	)
})

export default TitleBox