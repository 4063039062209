import React from 'react'
import * as Icon from '@ant-design/icons'
import dayjs from 'dayjs'
import { Tag, Typography } from 'antd'
import { difference } from './setOperation'
// import init, { pinyin_to_hanzi as _pinyin_to_hanzi } from './pinyin_to_hanzi/pinyin_to_hanzi.js'

const { Text, Link } = Typography


export const ProjectName = 'IEFI', PName = ProjectName

// 保存全局数据
export const appSave = (key, val) => {
	if (window.__app__) {
		window.__app__[key] = val
	} else {
		const o = {}
		o[key] = val
		setTimeout(() => {
			window.__app__ = o
		}, 0)
	}
}

// 所有icon名称
export const iconNames = Object.keys(Icon).slice(0, 790) // 最后四个不是iconName会导致报错 => createFromIconfontCN, default, getTwoToneColor, setTwoToneColor

// 传入icon名称返回 <Icon /> 标签
export const icon = (iconName) => Icon?.[iconName] ? React.createElement(Icon[iconName]) : null

// 判断一个menu key 是否以 / 开头
export const isLink = (key = '', sign = '/') => key.startsWith(sign)

// 根绝传入字符串是否以 / 开头展示 (是展示连接 否展示文字)
export const showLink = (key = '') => isLink(key) ? <Link>{key}</Link> : <Text>{key}</Text>

// 分割路径
export const splitPath = (path) => {
	if (path.startsWith('/')) {
		// 去掉路径开头的斜杠，如果有的话
		path = path.slice(1)
	}
	let segments = path.split('/') // 用斜杠分割路径，得到一个数组
	let result = segments.map((segment) => '/' + segment) // 在每个数组元素前面加上斜杠，得到一个新的数组

	return result
}

// 窗口大小监听
export const onWindowSize = (dom, set) => {
	let _ = null

	// 是element直接使用 否则先获取元素
	if (dom instanceof HTMLElement) {
		_ = dom
	} else {
		_ = document.querySelector(dom)
	}

	if (_ instanceof HTMLElement) {
		new ResizeObserver(([entry] = []) => {
			const [size] = entry.borderBoxSize || []
			set({ width: size.inlineSize, height: size.blockSize })
		}).observe(_) // 通过监听 document.body 来实现监听窗口大小变化
	} else {
		setTimeout(() => {
			onWindowSize(dom, set)
		}, 100)
	}
}

// 时间格式化
export const dateToStr = (v, type = 'YYYY-MM-DD HH:mm:ss') => v ? dayjs(v).format(type) : ''

// 展示布尔值
export const showBool = (
	bool,
	labelConfig = [
		{ label: '是', color: 'success' },
		{ label: '否', color: 'error' },
	],
) => {
	const lab = bool ? labelConfig[0] : labelConfig[1]
	return <Tag color={lab.color}>{lab.label}</Tag>
}

// 表格统一默认配置
export const tableConfig = (props) => {
	const {
		tableProps,
		scroll = { x: 'max-content' },
		rowKey = (i) => i._id,
		paginationSize = 'small',
	} = props || {}

	return {
		rowKey,
		scroll,
		pagination: {
			...tableProps?.pagination,
			showSizeChanger: true,
			showQuickJumper: true,
			showTotal: (total) => `共 ${total} 条`,
			size: paginationSize,
		},
	}
}

// dayjs 转 毫秒级时间戳
export const dayToTimestamp = (d) => {
	if (typeof d === 'object' && d.$d) {
		return d.valueOf()
	} else {
		return d
	}
}

// dayjs数组批量转时间戳
export const arrDayToArrTimestamp = (arr) => {
	if (!Array.isArray(arr)) {
		return arr
	}

	const newArr = []
	for (let i of arr) {
		newArr.push(dayToTimestamp(i))
	}
	return newArr
}

// 抽屉类型
export const typeOptions = {
	add: '新增',
	del: '删除',
	set: '修改',
	get: '查看',
	handle: '处理',
	batchHandle: '批量处理',
	bind: '绑定',
	batchBind: '批量绑定',
	reGet: '重新获取',
}

// 菜单树结构递归
export const generateMenuTree = (menuItems) => {
	const menuMap = {}
	const rootMenuItems = []

	// 将菜单项按照_id存储到menuMap中
	for (const menuItem of menuItems) {
		menuMap[menuItem._id] = { ...menuItem }
	}

	// 遍历菜单项，将子菜单项添加到对应的父菜单项的children数组中
	for (const menuItem of menuItems) {
		const parentId = menuItem.parentId
		if (parentId) {
			const parentMenuItem = menuMap[parentId[parentId.length - 1]]

			if (parentMenuItem?.children?.length) {
				parentMenuItem.children.push(menuMap[menuItem._id])
			} else if (parentMenuItem) {
				parentMenuItem.children = [menuMap[menuItem._id]]
			} else {
				rootMenuItems.push(menuItem)
			}
		} else {
			rootMenuItems.push(menuMap[menuItem._id])
		}
	}

	return rootMenuItems
}

// 剔除传入数组重复的一级节点   传入一个数组 递归每个 item下的 children (层级不确定) 如果发现某个 children 子节点的parentId与一级数据的_id重复 则剔除该一级节点数据
export const removeDuplicateChildren = (arr) => {
	let result = []

	for (let i = 0; i < arr.length; i++) {
		let item = arr[i]

		if (item?.children?.length) {
			item.children = removeDuplicateChildren(item.children)
		}

		let isDuplicate = false

		for (let j = 0; j < result?.length; j++) {
			if (result?.[j]?._id === item?.parentId?.[0]) {
				isDuplicate = true
				break
			}
		}

		if (!isDuplicate) {
			result.push(item)
		}
	}

	return result
}

// 字典转换回显数据
export const echo = ({
	arr,
	isBilateral = false,
	valueField = 'value',
	labelField = 'label',
}) => {
	const o = {}
	if (Array.isArray(arr)) {
		for (const i of arr) {
			// 通配 返回整个对象 没有反向
			if (labelField === '*') {
				o[i[valueField]] = i
			} else {
				o[i[valueField]] = i[labelField]
				if (isBilateral) o[i[labelField]] = i[valueField] // 是否需要双向转换
			}
		}
	}
	return o
}

// 级联选择器的每个item 扁平化转为 {value: label, ...} 的形式
export const cascadeFlatEcho = (data) => {
	let result = {}

	function traverse(obj) {
		if (obj.children) {
			obj.children.forEach((child) => {
				result[child.value] = child.label
				traverse(child)
			})
		}
	}

	data.forEach((item) => {
		result[item.value] = item.label
		traverse(item)
	})

	return result
}

// 级联选择器的每个item 扁平化转为 {value: label, ...} 的形式
export const cascadeFlatEchoObj = (data) => {
	let result = {}

	function traverse(obj) {
		if (obj.children) {
			obj.children.forEach((child) => {
				result[child.value] = {
					label: child.label,
					color: child.color,
				}
				traverse(child)
			})
		}
	}

	data.forEach((item) => {
		result[item.value] = {
			label: item.label,
			color: item.color,
		}
		traverse(item)
	})

	return result
}

// 随机抽取两个数字之间的一个数字
export const betRandom = (min, max) =>
	Math.floor(Math.random() * (max + 1 - min)) + min

// 正则
export const regData = (() => {
	const data = [
		{
			key: 'phone',
			name: '中国(宽松) 只要是13/14/15/16/17/18/19开头即可',
			rule: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
			message: '内容格式错误',
		},
		{
			key: 'eMail',
			name: '邮箱',
			rule: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			message: '内容格式错误',
		},
		{
			key: 'url',
			name: '网址',
			rule: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
			message: '内容格式错误',
		},
	]

	const res = {}
	for (const { rule, message, key } of data) {
		res[key] = {
			rule,
			formItemRule: {
				validator: (_, value) => {
					if (!value || rule.test(value)) {
						return Promise.resolve()
					}
					return Promise.reject(message)
				},
			},
		}
	}
	return res
})()

// 选择一条正则返回
export const getReg = (key, type = 'formItemRule') => regData?.[key]?.[type]

// 按key动态组成表单
export const dynamicForm = (formData, keys) => keys.map((key) => formData[key])

// 去重
export const unique = (arr) => [...new Set(arr)]

// cascader 选择结果补全(补齐某些全选的节点的后辈级节点_id)
export const cascaderResultCompletion = (selects, options, key = '_id') => {
	// const res = [...new Set(selects.flat(Infinity))] // 返回值: 长辈级节点 + 所有选项最后一级节点 + 其所有后辈节点(如果有 且最后一级节点处于全选状态)
	if (Array.isArray(selects) && selects.length && Array.isArray(options) && options.length) {
		const last_id = selects.map((v) => v[v.length - 1]) // 所有选择的最后一级节点_id
		const res = [...last_id] // 返回值: 所有选项最后一级节点 + 其所有后辈节点(如果有 且最后一级节点处于全选状态)

		// 将处于全选状态的节点的所有后辈节点 一并处理
		const involve = (children, res) => {
			for (const i of children) {
				res.push(i[key])

				if (i?.children?.length) {
					involve(i.children, res)
				}
			}
		}

		// 递归查找符合的节点
		const find = (options, last_id) => {
			for (const i of options) {
				// 如果某选项数据的最后一级 还有后续children 说明当前节点被全选了 获取所有后续节点_id 一并处理
				if (last_id.includes(i[key]) && i?.children?.length) {
					involve(i.children, res)
				} else if (i?.children?.length) {
					find(i.children, last_id)
				}
			}
		}

		find(options, last_id)

		return res
	} else {
		return []
	}
}

// 克隆对象
export const clone = (o) => JSON.parse(JSON.stringify(o))

// 对 options数据 打标
/*
	使用js编写一个函数 传入两个入参 第一个参数是_id数组 第二个参数是antd选择器的options参数
	递归第二个参数 判断以上两组数据 如果某个item的_id 被第一个参数所包含则对当前item打标记(添加 selected: true)
*/
export const markSelected = (arr, options) => {
	for (let i = 0; i < options.length; i++) {
		if (arr.includes(options[i]._id)) {
			options[i].selected = true
		}
		if (options[i]?.children?.length) {
			markSelected(arr, options[i].children)
		}
	}
	return options
}

// 还原cascader组件选择数据
/*
	以下数据是在antd cascader组件的options数据基础之上
	打了"selected": true 标签, 表示用户的选择状态  (multiple为true cascader返回值是二维数组)
	请根据数据使用js编写通用函数用于还原cascader返回的用户的选择数据value
*/
export const restoreSelectedValues = (data) => {
	const selectedValues = []
	function traverseOptions(options, path = []) {
		for (let i = 0; i < options.length; i++) {
			const option = options[i]
			if (option.selected) {
				selectedValues.push([...path, option.value])
			}
			if (option.children) {
				traverseOptions(option.children, [...path, option.value])
			}
		}
	}
	traverseOptions(data)
	return selectedValues
}

// 绑定数据处理
export const bindDataProcessing = (options) => {
	const { req, selectedIds, dataAll, targetIds, apiDel, apiSave, task, value } = options

	const prevData = cascaderResultCompletion(selectedIds, dataAll) // 之前-补全选中数据
	const currentData = cascaderResultCompletion(value, dataAll) // 当前-补全选中数据

	const delData = difference(prevData, currentData) // 删除的数据
	const addData = difference(currentData, prevData) // 新增的数据

	if (delData?.length) {
		const delReq = { ...req }
		delReq[targetIds] = delData
		task.push(apiDel(delReq))
	}
	if (addData?.length) {
		const addReq = { ...req }
		addReq[targetIds] = addData
		task.push(apiSave(addReq))
	}
}

// 递归归并两组数据
export const recursiveMerge = (data, dic) => {
	for (const i of data) {
		i.bindData = dic?.[i._id] || []
		if (i?.children?.length) {
			recursiveMerge(i.children, dic)
		}
	}
}

// 选择器 级联搜索默认配置函数
export const filterConfig = {
	showSearch: true,
	filter: (inputValue, path) => path.some((v) => (v.label).toLowerCase().includes(inputValue.toLowerCase()))
}

// 表格通用列
export const commonColumns = {
	index: {
		width: 120,
		title: '序号',
		dataIndex: 'index',
		render: (v, row, index) => <>{index + 1}</>,
		fixed: 'left',
	},
	createdAt: {
		width: 220,
		title: '创建时间',
		dataIndex: 'createdAt',
		render: (v) => <span>{dateToStr(v)}</span>,
	},
	updatedAt: {
		width: 220,
		title: '修改时间',
		dataIndex: 'updatedAt',
		render: (v) => <span>{dateToStr(v)}</span>,
	},
	createdBy: {
		width: 220,
		title: '创建人',
		dataIndex: 'createdBy',
	},
	updatedBy: {
		width: 220,
		title: '修改人',
		dataIndex: 'updatedBy',
	},
}

export const commonColumnsIndex = commonColumns.index
export const commonColumnsCreatedAndUpdated = [
	commonColumns.createdAt,
	commonColumns.updatedAt,
	commonColumns.createdBy,
	commonColumns.updatedBy,
]

// js 封装一个函数 通过循环的方式获取 数组对象中以(及子leftchildren和children中的)所有key (用于路由跳转时判断 目标url是否在menu中存在的)
export const getAllKeys = (data) => {
	const keys = []

	const stack = [...data]

	while (stack.length > 0) {
		const item = stack.pop()

		if (item.key) {
			keys.push(item.key)
		}

		if (item.children && item.children.length > 0) {
			stack.push(...item.children)
		} else if (item.leftchildren && item.leftchildren.length > 0) {
			stack.push(...item.leftchildren)
		}
	}

	return keys
}

// 集合操作
export * from './setOperation'

/*
// 拼音转汉字
async function initializeWASM() {
	await init() // Initialize the WASM module
}
initializeWASM() // 初始化
export async function pinyin_to_hanzi(pinyinInput) {
	const result = _pinyin_to_hanzi(pinyinInput)
	const data = JSON.parse(result)

	// 没有匹配时会返回 strErrMsg
	if (Array.isArray(data)) {
		return data
	} else {
		return []
	}
}
*/

// 传入 urls数组 返回响应最快的一个 url (有跨域问题)
export const findFastestUrl = (urls) => {
	const urlPromises = urls.map(url => fetch(url).then(response => response.text()))

	return Promise.race(urlPromises)

	// return Promise.all(urlPromises).then(data => {
	// 	const responseTimes = data.map ((data, index) => ({url: urls[index], time: Date.now () - new Date(data).getTime()}))
	// 	const fastestUrl = responseTimes.reduce((acc, current) => acc.time  < current.time  ? acc : current, {url: null, time: Infinity})
	// 	return fastestUrl.url
	// })
}


/*
//
	Q: js 判断 数组内的url 那个最先返回 则停止其他的请求 返回相应最快的url   请使用Promise.race + iframe 的方式实现

	A: 使用Promise.race和iframe来实现跨域请求并判断哪个URL最先返回，可以采取以下步骤：
		创建一个iframe元素，用于加载目标URL。
		监听iframe的load事件，当iframe加载完成时，认为请求成功。
		使用Promise.race来比较多个iframe的load事件，最先触发的load事件对应的URL即为响应最快的URL。
*/

// 创建一个函数，用于加载URL并返回一个Promise
const className = 'iFrameRace'
function _loadURL(url) {
	return new Promise((resolve, reject) => {
		// 创建一个iframe元素
		const iframe = document.createElement('iframe')

		// 设置iframe的src属性为给定的URL
		iframe.src = url

		// 监听load事件，当iframe加载完成时解析Promise
		iframe.onload = () => resolve(url)

		// 监听error事件，如果加载失败则拒绝Promise
		iframe.onerror = () => reject(new Error(`Failed to load ${url}`))    // 将iframe添加到页面中（可以将其设置为不可见）

		iframe.style.display = 'none'
		iframe.className = className

		document.body.appendChild(iframe)    // 设置iframe的样式，使其不可见
	})
}

// 定义一个函数，用于找到最快响应的URL
export async function findFastestURL(urls) {  // 使用map创建一个Promise数组  
	const promises = urls.map(url => _loadURL(url))  // 使用Promise.race来获取最快完成的Promise

	const res = await Promise.race(promises)

	// 删除类名为 iFrameRace 的iframe
	setTimeout(() => {
		const ele = document.querySelectorAll(`.${className}`)
		Array.from(ele)?.map?.(v => document.body.removeChild(v))
	}, 0)

	return res
}
