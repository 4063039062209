import { Table, Tag } from 'antd'
import { showBool } from '@/tools'

// 子表
const SubTable = (props) => {
	// 表格列
	const getColumns = () => {
		const data = [
			{
				width: 120,
				title: '序号',
				render: (v, row, index) => <>{index + 1}</>,
				fixed: 'left',
			},
			{ 
				title: '名称', 
				dataIndex: 'label' 
			},
			{
				title: '取值',
				dataIndex: 'value',
				render: v => v.toString()
			},
			{
				title: '颜色',
				dataIndex: 'color',
				render: v => v ? <Tag color={v}>{v}</Tag> : '-'
			},
			{
				title: '是否禁用',
				dataIndex: 'disabled',
				render: (v) =>
					showBool(v, [
						{ label: '是', color: 'error' },
						{ label: '否', color: 'success' },
					]),
			},
			// { title: '是否展示', dataIndex: 'isShow', render: (v) => showBool(v) },
		]
		return data
	}

	return (
		<Table
			columns={getColumns()}
			rowKey={(v) => `${v.label}-${v.value}`}
			{...props}
		/>
	)
}

export default SubTable
