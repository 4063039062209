import React, { memo } from 'react'
import { Form } from 'antd'

// 表单项渲染
const FormItmeRender = memo((props) => {
	const { data } = props

	return (
		<>
			{data.map((v) => {
				if (v?.formItemProps) {
					return (
						<Form.Item key={v.formItemProps.name} {...v.formItemProps}>
							{v.render}
						</Form.Item>
					)
				} else if (React.isValidElement(v.render)) {
					// 传入 <组件名/> 的时候直接渲染
					return v.render
				}
				// else {
				//     // 传入 组件名 的时候透传form字段并渲染
				//     return <v.render form={form} />
				// }
			})}
		</>
	)
})

export default FormItmeRender
