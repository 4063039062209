import ReactJson from 'react-json-view'
import React, { memo } from 'react'

// json 渲染器封装
const Page = memo((props) => {
	const { value = {} } = props
	return (
		<ReactJson
			src={value}
			theme={'pop'}
			style={{ borderRadius: 5, padding: 20, marginTop: 20 }}
		/>
	)
})

export default Page
