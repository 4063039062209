import { memo } from 'react'
import { Select } from 'antd'

// 布尔值选择
const TimeSelect = memo((props) => {
	const {
		placeholder,
		options = [
			{ label: '是', value: true },
			{ label: '否', value: false },
		],
		..._props
	} = props

	return (
		<Select
			placeholder={placeholder}
			allowClear
			options={options}
			{..._props}
		/>
	)
})

TimeSelect.displayName = 'TimeSelect'
export default TimeSelect
