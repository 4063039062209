import { memo, useMemo } from 'react'
import { Anchor, Layout, Typography, Space } from 'antd'
import styles from './index.module.scss'
import { icon } from '@/tools'
import Box from './components/box'
import api from '@/api'
import { useRequest } from 'ahooks'
import LazyLoadContainer from '@/components/lazyLoadContainer'
import SearchBar from '@/components/searchBar'
import Logo from '@/components/logo'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

const { Content, Sider } = Layout
const { Title } = Typography

const Recommend = memo(() => {
	const location = useLocation()
	const search = location?.search?.length > 1 ? location?.search.slice(1) : ''
	const o = qs.parse(search) // 查询字符串 对象

	// 全部菜单数据(获取对应用户的卡片分类列表)
	const { data: classOptions } = useRequest(() => api.classOfficialAll({ userId: o.userId }).then(res => {
		return res?.data?.rows || []
	}))

	const items = useMemo(() => {
		const other = {
			key: 'other',
			title: '其他',
		}

		const res = classOptions?.map(v => ({
			key: v._id,
			id: v._id,
			_id: v._id,
			href: '#' + v._id,
			title: v.label,
		})) || []

		res.push({
			key: other.key,
			id: [],
			_id: [],
			href: '#' + other.key,
			title: other.title,
		})

		return res
	}, [classOptions])

	return (
		<div className={styles.recommendPage}>
			<Layout>
				<Sider className={styles.Sider}>
					<Anchor items={items} />
				</Sider>

				<Content className={styles.Content}>
					<Logo size='350X80' />
					<SearchBar />

					{
						items.map(v =>
							(
								<div id={v.key} key={v.key} className={styles.group}>
									<Title level={4} style={{ color: '#4b4b4b', marginBottom: 20 }}>
										<Space size={5}>
											{icon('TagOutlined')}
											{v.title}
											{/* {JSON.stringify(o)} */}
										</Space>
									</Title>

									<LazyLoadContainer>
										<Box _id={v.id} userId={o.userId} query={o} />
									</LazyLoadContainer>
								</div>
							)
						)
					}
				</Content>
			</Layout>
		</div>
	)
})

export default Recommend

export const isShowConfig = {
	isShowHeader: true,
	isShowSider: false,
	isShowBreadcrumb: false,
	isShowContent: false,
	isShowFooter: false,
}
