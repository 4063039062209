import { Flex, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import logo240X80 from '@/static/240X80.png'
import logo192X192 from '@/static/192X192.png'
// import logo130X32 from '@/static/130X32.png'
// import logo850X300 from '@/static/850X300.png'

const Logo = (props) => {
	const { size = '350X80', style = {}, className, _props } = props
	const navigate = useNavigate()

	const fn = () => {
		if (size === '36X36') {
			return (
				<div
					style={{
						// width: 130,
						width: 36,
						height: 36,
						background: 'rgba(255, 255, 255, 0)',
						borderRadius: 6,
						cursor: 'pointer',
					}}
					onClick={() => navigate('/')}
				>
					{/* <Image width={100} height={32} src={logo240X80} preview={false} style={{ position: 'relative', top: -17.5, left: 12 }} /> */}
					<Image width={36} height={36} src={logo192X192} preview={false} style={{ position: 'relative', top: -16 }} />
				</div>
			)
		} else if (size === '350X80') {
			return (
				<Flex justify='center'>
					<div
						style={{
							width: 240,
							height: 80,
							backgroundColor: 'rgba(255,255,255, 0)',
							position: 'relative',
							top: 50,
							borderRadius: 15,
						}}
					>
						<Image width={240} height={80} src={logo240X80} preview={false} />
					</div>
				</Flex>
			)
		} else {
			// 返回指定尺寸的占位符
			const sizeData = size.split('X')
			const [width, height] = sizeData

			return (
				<Flex justify='center' className={className} {..._props}>
					<div
						style={{
							width,
							height,
							backgroundColor: '#eee',
							borderRadius: Math.min(width, height) * .1875,
							...style
						}}
					/>
				</Flex>
			)
		}
	}

	return fn(size)
}

export default Logo