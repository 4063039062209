import { memo, useState } from 'react'
import { Avatar, Image } from 'antd'

// 图片展示
const PicShow = memo((props) => {
	const { src } = props
	const [visible, setVisible] = useState(false)

	return (
		<div
			style={{
				width: 40,
				height: 40
			}}
		>
			<Avatar 
				style={{ cursor: 'pointer' }}
				size='large'
				src={src}
				onClick={() => src ? setVisible(true) : null}
			/>

			<Image
				width={200}
				style={{ display: 'none' }}
				src={src}
				preview={{
					visible,
					src: src,
					onVisibleChange: setVisible,
				}}
			/>
		</div>)
})

export default PicShow
