// 列表页搜索栏组件封装
import { memo, useEffect } from 'react'
import { Form, Row } from 'antd'
import Btns from './btns'
import SearchFormItem from './searchFormItem'
import debounce from 'lodash/debounce'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'
import dayjs from 'dayjs'

// (表格)搜索栏封装
const Search = memo((props) => {
	const { form, formData = [], btnData = [], submit, ..._props } = props
	const __formData__ = '__formData__'
	const location = useLocation()
	const navigate = useNavigate()

	// 初始化的时候获取url上的 queryString 转为obj 回填到表单
	useEffect(() => {
		const search = location?.search?.length > 1 ? location?.search.slice(1) : ''
		const o = qs.parse(search)
		const r = /^\d{4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}:\d{1,2}/ // 时间字符串

		// 递归 所有字段还原 时间字符串
		function recursive(formData) {
			for (const key in formData) {
				const val = formData[key]

				if (typeof val === 'string' && (val === 'true' || val === 'false')) { // 布尔值
					formData[key] = Boolean(val)
				} else if (typeof val === 'string' && r.test(val)) { // 时间字符串
					formData[key] = dayjs(val)
				} else if (Array.isArray(val)) {
					for (let i = 0; i < val.length; i++) {
						const v = val[i]
						if (typeof v === 'string' && r.test(v)) { // 时间字符串
							val[i] = dayjs(v)
						} else if (
							Object.prototype.toString.call(v) === '[object Object]'
						) {
							recursive(v)
						}
					}
				} else if (Object.prototype.toString.call(val) === '[object Object]') {
					recursive(val)
				}
			}
		}

		if (o?.[__formData__]) {
			const formData = o[__formData__]
			recursive(formData)
			form.setFieldsValue(formData)
		}
	}, [])

	// 按钮组
	const btns = () => <Btns data={btnData} />

	// 搜索栏
	const searchForm = () => <SearchFormItem data={formData} />

	// from change 时刷新表格数据
	const _submit =
    typeof submit === 'function' ? debounce(submit, 500) : () => {}
	const onValuesChange = (changedValues, allValues) => {
		// 调用外层组件传入的 change 函数
		try {
			_props?.onValuesChange?.(changedValues, allValues)
		} catch (error) {
			console.log('调用外层组件传入的change函数报错: ', error)
		}

		// 发请求刷新表格数据
		try {
			_submit()
		} catch (error) {
			console.log('发请求刷新表格数据报错: ', error)
		}

		// 将form的数据变化同步到url
		try {
			const search = location?.search?.slice(1) || '' // 获取浏览器当前url中的search
			const otherData = {} // 其它数据
			if (search.length) {
				const searchObj = qs.parse(search)
				for (const [key, val] of Object.entries(searchObj)) {
					// 将除 __formData__ 以外的数据单独处理
					if (key !== __formData__) {
						otherData[key] = val
					}
				}
			}
			const data = { ...otherData } // 其他参数不能受到影响
			data[__formData__] = allValues // 加入最新的formData
			const o = qs.stringify(JSON.parse(JSON.stringify(data)))
			navigate(`${location.pathname}${o ? `?${o}` : ''}`)
		} catch (error) {
			console.log('将form的数据变化同步到url报错: ', error)
		}
	}

	return (
		<Form
			layout="inline"
			form={form}
			{..._props}
			onValuesChange={onValuesChange}
		>
			<Row gutter={[0, 16]} style={{ width: '100%' }}>
				{searchForm()}
				{btns()}
			</Row>
		</Form>
	)
})

export default Search
