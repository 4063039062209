import { memo } from 'react'
import { Pagination, Flex } from 'antd'
import { useRecoilState } from 'recoil'
import { cardListPaginationData as _cardListPaginationData } from '@/state'

const MyPagination = memo(() => {
	const [cardListPaginationData, setCardListPaginationData] = useRecoilState(_cardListPaginationData) // 分页数据

	const onChange = (page, pageSize) => {
		setCardListPaginationData(v => ({
			...v,
			current: page,
			pageSize: pageSize,
		}))

		window.__app__?.search({
			cardListPaginationData: {
				current: page,
				pageSize,
			}
		})
	}

	return (
		<Flex justify='center'>
			<Pagination
				size='small'
				hideOnSinglePage={true} // 只有一页时是否隐藏分页器
				showLessItems={true} // 是否显示较少页面内容
				showSizeChanger={true} // 是否展示 pageSize 切换器
				showQuickJumper={true} // 是否可以快速跳转至某页
				showTotal={(total) => `共 ${total} 条`}

				current={cardListPaginationData.current} // 当前页
				pageSize={cardListPaginationData.pageSize} // 每页条数
				pageSizeOptions={cardListPaginationData.pageSizeOptions} // 指定每页可以显示多少条
				total={cardListPaginationData.total} // 数据总数
				onChange={onChange}
			/>
		</Flex>
	)
})

export default MyPagination
