// http 请求接口
import request from './request'

const api = {
	// 测试
	testAdd: (params) => request({ url: 'test/add', params, method: 'post' }),
	testDel: (params) => request({ url: 'test/del', params, method: 'delete' }),
	testSet: (params) => request({ url: 'test/set', params, method: 'put' }),
	testGet: (params) => request({ url: 'test/get', params, method: 'get' }),
	testList: (params) => request({ url: 'test/list', params, method: 'post' }),

	// 菜单
	menuAdd: (params) => request({ url: 'menu/add', params, method: 'post' }),
	menuDel: (params) => request({ url: 'menu/del', params, method: 'delete' }),
	menuSet: (params) => request({ url: 'menu/set', params, method: 'put', isFilter: false }),
	menuGet: (params) => request({ url: 'menu/get', params, method: 'get' }),
	menuList: (params) => request({ url: 'menu/list', params, method: 'post' }),
	menuAll: (params) => request({ url: 'menu/all', params, method: 'post' }),
	menuShow: (params) => request({ url: 'menu/show', params, method: 'post' }),

	// 字典
	dicAdd: (params) => request({ url: 'dic/add', params, method: 'post' }),
	dicDel: (params) => request({ url: 'dic/del', params, method: 'delete' }),
	dicSet: (params) => request({ url: 'dic/set', params, method: 'put', isFilter: false }),
	dicGet: (params) => request({ url: 'dic/get', params, method: 'get' }),
	dicList: (params) => request({ url: 'dic/list', params, method: 'post' }),
	dicGetByKey: (params) => request({ url: 'dic/getByKey', params, method: 'get' }).then(res => res?.data?.data?.children || []),

	// 错误
	errAdd: (params) => request({ url: 'err/add', params, method: 'post' }),
	errDel: (params) => request({ url: 'err/del', params, method: 'delete' }),
	errSet: (params) => request({ url: 'err/set', params, method: 'put', isFilter: false }),
	errGet: (params) => request({ url: 'err/get', params, method: 'get' }),
	errList: (params) => request({ url: 'err/list', params, method: 'post' }),

	// 日志
	logDel: (params) => request({ url: 'log/del', params, method: 'delete' }),
	logGet: (params) => request({ url: 'log/get', params, method: 'get' }),
	logList: (params) => request({ url: 'log/list', params, method: 'post' }),

	// 用户-B端
	userAdd: (params) => request({ url: 'user/add', params, method: 'post' }),
	userDel: (params) => request({ url: 'user/del', params, method: 'delete' }),
	userSet: (params) => request({ url: 'user/set', params, method: 'put', isFilter: false }),
	userGet: (params) => request({ url: 'user/get', params, method: 'get' }),
	userList: (params) => request({ url: 'user/list', params, method: 'post' }),
	userAll: (params) => request({ url: 'user/all', params, method: 'post' }),

	// 用户-C端
	userRegister: (params) => request({ url: 'user/register', params, method: 'post' }), // 注册
	userLogIn: (params) => request({ url: 'user/login', params, method: 'get' }), // 登录
	userForget: (params) => request({ url: 'user/forget', params, method: 'put' }), // 忘记密码
	userImageCaptcha: (params) => request({ url: 'user/imageCaptcha', params, method: 'get' }), // 获取SVG图片验证码
	userEMailVerificationCode: (params) => request({ url: 'user/eMailVerificationCode', params, method: 'get' }), // 获取邮箱验证码

	userSignOut: (params) => request({ url: 'user/signOut', params, method: 'get' }), // 退出登录
	userSave: (params) => request({ url: 'user/save', params, method: 'post' }), // 修改-C端-(不能修改邮箱/密码/手机)
	userSetPassword: (params) => request({ url: 'user/set/password', params, method: 'post' }), // 修改密码
	userSetPhone: (params) => request({ url: 'user/set/phone', params, method: 'post' }), // 修改手机
	userSetEMail: (params) => request({ url: 'user/set/eMail', params, method: 'post' }), // 修改邮箱

	// 用户配置
	userConfigSave: (params) => request({ url: 'userConfig/save', params, method: 'post' }), // 保存用户配置

	// 用户组
	userGroupAdd: (params) => request({ url: 'userGroup/add', params, method: 'post' }),
	userGroupDel: (params) => request({ url: 'userGroup/del', params, method: 'delete' }),
	userGroupSet: (params) => request({ url: 'userGroup/set', params, method: 'put', isFilter: false }),
	userGroupGet: (params) => request({ url: 'userGroup/get', params, method: 'get' }),
	userGroupList: (params) => request({ url: 'userGroup/list', params, method: 'post' }),
	userGroupAll: (params) => request({ url: 'userGroup/all', params, method: 'post' }),

	// 权限
	permissionsAdd: (params) => request({ url: 'permissions/add', params, method: 'post' }),
	permissionsDel: (params) => request({ url: 'permissions/del', params, method: 'delete' }),
	permissionsSet: (params) => request({ url: 'permissions/set', params, method: 'put', isFilter: false }),
	permissionsGet: (params) => request({ url: 'permissions/get', params, method: 'get' }),
	permissionsList: (params) => request({ url: 'permissions/list', params, method: 'post' }),
	permissionsAll: (params) => request({ url: 'permissions/all', params, method: 'post' }),
	permissionsuApiOptions: (params) => request({ url: 'permissions/api/options', params, method: 'get' }), // 数据权限(接口选项)

	// 角色
	roleAdd: (params) => request({ url: 'role/add', params, method: 'post' }),
	roleDel: (params) => request({ url: 'role/del', params, method: 'delete' }),
	roleSet: (params) => request({ url: 'role/set', params, method: 'put', isFilter: false }),
	roleGet: (params) => request({ url: 'role/get', params, method: 'get' }),
	roleList: (params) => request({ url: 'role/list', params, method: 'post' }),
	roleAll: (params) => request({ url: 'role/all', params, method: 'post' }),

	// 角色_权限关联表
	role_permissionsSave: (params) => request({ url: 'role-permissions/save', params, method: 'post' }),
	role_permissionsFind: (params) => request({ url: 'role-permissions/find', params, method: 'post' }),
	role_permissionsDel: (params) => request({ url: 'role-permissions/del', params, method: 'delete' }),
	role_permissionsConditionalDel: (params) => request({ url: 'role-permissions/conditionalDel', params, method: 'delete' }), // 按条件删除
	role_permissionsGet: (params) => request({ url: 'role-permissions/get', params, method: 'get' }),
	role_permissionsList: (params) => request({ url: 'role-permissions/list', params, method: 'post' }),
	role_permissionsAll: (params) => request({ url: 'role-permissions/all', params, method: 'post' }),

	// 用户_角色关联表
	user_roleSave: (params) => request({ url: 'user-role/save', params, method: 'post' }),
	user_roleFind: (params) => request({ url: 'user-role/find', params, method: 'post' }),
	user_roleDel: (params) => request({ url: 'user-role/del', params, method: 'delete' }),
	user_roleConditionalDel: (params) => request({ url: 'user-role/conditionalDel', params, method: 'delete' }), // 按条件删除
	user_roleGet: (params) => request({ url: 'user-role/get', params, method: 'get' }),
	user_roleList: (params) => request({ url: 'user-role/list', params, method: 'post' }),
	user_roleAll: (params) => request({ url: 'user-role/all', params, method: 'post' }),

	// 用户组_用户关联表
	userGroup_userSave: (params) => request({ url: 'userGroup-user/save', params, method: 'post' }),
	userGroup_userFind: (params) => request({ url: 'userGroup-user/find', params, method: 'post' }),
	userGroup_userDel: (params) => request({ url: 'userGroup-user/del', params, method: 'delete' }),
	userGroup_userConditionalDel: (params) => request({ url: 'userGroup-user/conditionalDel', params, method: 'delete' }), // 按条件删除
	userGroup_userGet: (params) => request({ url: 'userGroup-user/get', params, method: 'get' }),
	userGroup_userList: (params) => request({ url: 'userGroup-user/list', params, method: 'post' }),
	userGroup_userAll: (params) => request({ url: 'userGroup-user/all', params, method: 'post' }),

	// 用户组_角色关联表
	userGroup_roleSave: (params) => request({ url: 'userGroup-role/save', params, method: 'post' }),
	userGroup_roleFind: (params) => request({ url: 'userGroup-role/find', params, method: 'post' }),
	userGroup_roleDel: (params) => request({ url: 'userGroup-role/del', params, method: 'delete' }),
	userGroup_roleConditionalDel: (params) => request({ url: 'userGroup-role/conditionalDel', params, method: 'delete' }), // 按条件删除
	userGroup_roleGet: (params) => request({ url: 'userGroup-role/get', params, method: 'get' }),
	userGroup_roleList: (params) => request({ url: 'userGroup-role/list', params, method: 'post' }),
	userGroup_roleAll: (params) => request({ url: 'userGroup-role/all', params, method: 'post' }),

	// 文件
	uploadsSave: (params) => request({ url: 'uploads/save', params, method: 'post', restConfig: { headers: { 'Content-Type': 'multipart/form-data' }}, isFilter: false }), // 上传

	// 搜索类型选项(首页)
	searchTypeAdd: (params) => request({ url: 'searchType/add', params, method: 'post' }),
	searchTypeDel: (params) => request({ url: 'searchType/del', params, method: 'delete' }),
	searchTypeSet: (params) => request({ url: 'searchType/set', params, method: 'put', isFilter: false }),
	searchTypeGet: (params) => request({ url: 'searchType/get', params, method: 'get' }),
	searchTypeList: (params) => request({ url: 'searchType/list', params, method: 'post' }),
	searchTypeAll: (params) => request({ url: 'searchType/all', params, method: 'post' }),

	// 分类
	classAdd: (params) => request({ url: 'class/add', params, method: 'post' }),
	classDel: (params) => request({ url: 'class/del', params, method: 'delete' }),
	classSet: (params) => request({ url: 'class/set', params, method: 'put', isFilter: false }),
	classGet: (params) => request({ url: 'class/get', params, method: 'get' }),
	classList: (params) => request({ url: 'class/list', params, method: 'post' }),
	classAll: (params) => request({ url: 'class/all', params, method: 'post' }), // c端
	classFindByUserId: (params) => request({ url: 'class/findByUserId', params, method: 'post' }), // 按照指定用户id及分类id查找 对应卡片 (B端)
	classGetSortMax: (params) => request({ url: 'class/getSortMax', params, method: 'post' }), // c端
	classDelClass: (params) => request({ url: 'class/delClass', params, method: 'delete' }), // 删 c端
	classSetClass: (params) => request({ url: 'class/setClass', params, method: 'put', isFilter: false }), // 改 c端
	classOfficialAll: (params) => request({ url: 'class/official/all', params, method: 'post' }), // 获取全部官方分类c端

	// 卡片
	cardAdd: (params) => request({ url: 'card/add', params, method: 'post' }),
	cardDel: (params) => request({ url: 'card/del', params, method: 'delete' }),
	cardSet: (params) => request({ url: 'card/set', params, method: 'put' }),
	cardGet: (params) => request({ url: 'card/get', params, method: 'get' }),
	cardList: (params) => request({ url: 'card/list', params, method: 'post' }),
	cardReGet: (params) => request({ url: 'card/reGet', params, method: 'post' }), // 重新获取

	// 用户卡片
	userCardAdd: (params) => request({ url: 'userCard/add', params, method: 'post' }),
	userCardDel: (params) => request({ url: 'userCard/del', params, method: 'delete' }),
	userCardSet: (params) => request({ url: 'userCard/set', params, method: 'put', isFilter: false }),
	userCardGet: (params) => request({ url: 'userCard/get', params, method: 'get' }),
	userCardList: (params) => request({ url: 'userCard/list', params, method: 'post' }),
	userCardGetSortMax: (params) => request({ url: 'userCard/getSortMax', params, method: 'post' }), // c端 排序最大的一条
	userCardFind: (params) => request({ url: 'userCard/find', params, method: 'post', isFilter: false }), // 列表 按照当前用户id及分类id查找 对应卡片 (C端)
	userCardFindByUserId: (params) => request({ url: 'userCard/findByUserId', params, method: 'post' }), // 列表 按照指定用户id及分类id查找 对应卡片 (B端)
	userCardDelBind: (params) => request({ url: 'userCard/delBind', params, method: 'delete' }), // 删 c端
	userCardGetCard: (params) => request({ url: 'userCard/getCard', params, method: 'get' }), // 查 c端
	userCardSetCard: (params) => request({ url: 'userCard/setCard', params, method: 'put', isFilter: false }), // 改 c端
	userCardGetOfficialCardByClassId: (params) => request({ url: 'userCard/getOfficialCardByClassId', params, method: 'post', isFilter: false }), // 获取类下的全部卡片 c端
}

export default api
export * from './request'