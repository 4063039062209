import { memo } from 'react'
import { Button, Flex } from 'antd'
import { useRecoilState } from 'recoil'
import { homeIsShowList as _homeIsShowList } from '@/state'
import { icon } from '@/tools'

const More = memo(() => {
	const [homeIsShowList, setHomeIsShowList] = useRecoilState(_homeIsShowList) // 是否展示list

	return (
		<Flex justify='center' style={{ position: 'relative', top: -70, zIndex: 1 }}>
			<Button
				type="text"
				size='small'
				icon={homeIsShowList ? icon('CaretDownOutlined') : icon('CaretUpOutlined')}
				onClick={() => setHomeIsShowList(v => !v)}
				style={{ color: '#999', fontSize: 12 }}
			/>
		</Flex>
	)
})

export default More