import { memo, useRef, useCallback } from 'react'
import { Form, Input, message } from 'antd'
import ShowData from '../showData'
import { useRecoilState } from 'recoil'
import { userInfo as _userInfo, user, imageCaptchaId as _imageCaptchaId } from '@/state'
import { getReg } from '@/tools'
import api from '@/api'
import ImageCaptcha from '@/components/verificationCode/imageCaptcha'
import VerificationCode from '@/components/verificationCode/verificationCode'

const getVerificationCode = async (form, imageCaptchaId) => {
	const fd = form.getFieldsValue()

	const { code, eMail } = fd

	if (!code) {
		message.error('请输入图形验证码')
		return false
	} else if (!getReg('eMail', 'rule')?.test(eMail)) {
		message.error('电子邮箱 格式有误')
		return false
	} else {

		const req = {
			eMail,
			_id: imageCaptchaId,
			code,
			// loginType,
			type: 'setEMail'
		}

		console.log(req)

		// 通过<邮箱>注册
		const res = await api.userEMailVerificationCode(req) // 获取邮箱验证码
		console.log(res)
	}
}

const ContactInformation = memo(() => {
	const childRef = useRef(null)
	const [form] = Form.useForm()
	const [, setUserInfo] = useRecoilState(_userInfo) // 用户信息
	const [userInfo] = useRecoilState(user) // 用户信息
	const [imageCaptchaId] = useRecoilState(_imageCaptchaId) // 图片验证码Id

	/*
	// 获取验证码(邮箱/手机)
	const getVerificationCode = useCallback(async () => {
		const fd = form.getFieldsValue()
	
		const { code, eMail } = fd
	
		if (!code) {
			message.error('请输入图形验证码')
			return false
		} else if (!getReg('eMail', 'rule')?.test(eMail)) {
			message.error('电子邮箱 格式有误')
			return false
		} else {

			const req = {
				eMail,
				_id: imageCaptchaId,
				code,
				// loginType,
				type: 'setEMail'
			}

			console.log(req)

			// 通过<邮箱>注册
			const res = await api.userEMailVerificationCode(req) // 获取邮箱验证码
			console.log(res)
		}
	}, [form, imageCaptchaId])
	*/

	const data = [
		{
			label: '电子邮箱',
			value: userInfo.eMail,
			modalConfig: {
				formConfig: [
					{
						formItemProps: {
							rules: [{ required: true, message: '请输入' }, getReg('eMail')],
							name: 'eMail',
							label: '电子邮箱',
							initialValue: userInfo.eMail
						},
						render: <Input allowClear />,
					},
					{
						formItemProps: {
							rules: [{ required: true, message: '请输入' }],
							name: 'code',
							label: '图形验证码'
						},
						render: (
							<ImageCaptcha
								placeholder='图形验证码'
								ref={childRef}
							/>
						),
					},
					// {
					// 	formItemProps: {
					// 		rules: [{ required: true, message: '请输入' }],
					// 		name: 'eMailVerificationCode',
					// 		label: '邮箱验证码'
					// 	},
					// 	render: (
					// 		<VerificationCode
					// 			placeholder={'邮箱验证码'}
					// 			allowClear
					// 			onClick={() => getVerificationCode(form, imageCaptchaId)}
					// 		/>
					// 	),
					// }
				]
			}
		},
		{
			label: '手机号',
			value: userInfo.phone,
			formConfig: {
				render: <Input allowClear />,
				formItemProps: {
					rules: [getReg('phone')],
					name: 'phone',
				},
			},
		},
	]

	// 单条数据修改
	const attributeSubmit = async (formData, item) => {
		const req = {}
		req[item.formConfig.formItemProps.name] = formData[item.formConfig.formItemProps.name]

		const res = await api.userSetPhone(req)
		message.success('操作成功')
		setUserInfo(res.data)
	}

	// 模态窗数据修改
	const modalSubmit = async (formData, modalFormItemData) => {
		try {
			const fields = modalFormItemData.map(v => v.formItemProps.name)

			let res = null
			// 修改邮箱
			if (fields.includes('eMail')) {
				res = await api.userSetEMail({ ...formData, imageCaptchaId })
			}

			setUserInfo(res.data)
			return true
		} catch (error) {
			childRef?.current?.run?.() // 重新获取验证码
			console.log(error)
			return false
		}
	}

	return (
		<Form
			layout='vertical'
			wrapperCol={{ span: 24 }}
			form={form}
			initialValues={{ ...userInfo }}
		>
			<ShowData
				labelCol={{ span: 5 }}
				wrapperCol={{ span: 19 }}
				data={data}
				form={form}
				attributeSubmit={attributeSubmit}
				modalSubmit={modalSubmit}
			/>
		</Form>
	)
})

export default ContactInformation