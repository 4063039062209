import { ColorPicker, Divider, Tag } from 'antd'
import { memo } from 'react'

// 颜色选择器
const ColorSelcet = memo((props) => {
	const {
		// 预设颜色选项
		presetsData = [
			{
				title: '预设颜色',
				data: [
					{ value: 'processing', label: '主色', color: '#1677FF' },
					{ value: 'success', label: '成功', color: '#52C41A' },
					{ value: 'error', label: '错误', color: '#FF4D4F' },
					{ value: 'warning', label: '警告', color: '#FAAD14' },
					{ value: 'default', label: '默认', color: '#1C1C1C' },
				],
			},
		],
		onClickTag,
	} = props

	// 点击Tag选择预设颜色
	const onClick = (color) => {
		if (typeof onClickTag === 'function') {
			onClickTag(color)
		}
	}

	// 选择内容
	const panelRender = (_, { components: { Picker } }) => (
		<div>
			<Picker />
			<div>
				{
					presetsData.map(v => (
						<div key={v.title}>
							<Divider orientation="left">
								<span style={{ fontSize: 12 }}>{v.title}</span>
							</Divider>

							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								{
									v.data.map(color => (
										<Tag
											key={color.value}
											color={color.value}
											style={{ cursor: 'pointer', margin: 0 }}
											onClick={() => onClick(color)}
										>
											{color.label}
										</Tag>
									))
								}
							</div>
						</div>
					))
				}
			</div>
		</div>
	)

	return (
		<ColorPicker
			allowClear
			placement='bottom'
			panelRender={panelRender}
			defaultValue={'#FFFFFF00'}
			{...props}
		/>
	)
})

export default ColorSelcet
