import { memo } from 'react'
import { useRecoilState } from 'recoil'
import { isLogin as _isLogin } from '@/state'
import NotLogin from './notLogin' // 未登录
import HasLogin from './hasLogin' // 已登录

// 用户模块(已登录 & 未登录)
const User = memo(() => {
	const [isLogin] = useRecoilState(_isLogin) // 用户是否登录

	if (isLogin) {
		return <HasLogin />
	} else {
		return <NotLogin />
	}
})

export default User
