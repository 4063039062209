import { memo, forwardRef, useImperativeHandle } from 'react'
import { Input, Spin } from 'antd'
import styles from '../index.module.scss'
import api from '@/api'
import { useRequest } from 'ahooks'
import { useRecoilState } from 'recoil'
import { imageCaptchaId as _imageCaptchaId } from '@/state'

// 图形验证码 + 输入框
const ImageCaptcha = forwardRef(({ ...props }, ref) => {
	const [, setImageCaptchaId] = useRecoilState(_imageCaptchaId) // 图片验证码
	const { data, run, loading } = useRequest(() =>
		api.userImageCaptcha().then((res) => {
			// 字典-字典取值类型
			setImageCaptchaId(res.data._id)
			return res.data
		}),
	)

	useImperativeHandle(ref, () => ({ run })) // 暴露run方法给父组件调用

	return (
		<section className={styles.formItmeRender}>
			<Input allowClear {...props} className={styles.input} />

			<Spin spinning={loading}>
				<div
					className={styles.img}
					onClick={run}
					dangerouslySetInnerHTML={{ __html: data?.code || '' }}
				/>
			</Spin>
		</section>
	)
})

export default memo(ImageCaptcha)
