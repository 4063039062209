import { memo, useState, useEffect } from 'react'
import { Tabs, Modal, Form, Input, InputNumber, message } from 'antd'
import Set from '../set'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useRecoilState } from 'recoil'
import { isLogin as _isLogin, homeIsShowList as _homeIsShowList, classAll as _classAll, isShowLogin as _isShowLogin, currentSelectClass as _currentSelectClass, cardListPaginationData as _cardListPaginationData } from '@/state'
import api from '@/api'

const { confirm } = Modal

const MyTabs = memo((props) => {
	const [isLogin] = useRecoilState(_isLogin) // 用户是否登录
	const [form] = Form.useForm()
	const [homeIsShowList] = useRecoilState(_homeIsShowList) // 是否展示list
	const [classAll, setClassAll] = useRecoilState(_classAll) // 分类数据
	const [, setIsShowLogin] = useRecoilState(_isShowLogin) // 是否展示登录窗口
	const [currentSelectClass, setCurrentSelectClass] = useRecoilState(_currentSelectClass) // 当前选择的分类数据
	const [cardListPaginationData, setCardListPaginationData] = useRecoilState(_cardListPaginationData) // 分页数据
	const [isShow, setIsShow] = useState(false) // 新增窗口
	const [addConfirmLoading, setAddConfirmLoading] = useState(false)

	// 初始化 class 数据
	useEffect(() => {
		getClassAll()
	}, [isLogin, homeIsShowList])

	// 获取当前用户的所有分类
	const getClassAll = () => {
		if (isLogin && homeIsShowList) {
			// 等状态管理工具先更新数据后 classAll 接口请求才会带上token (否则包权限不足)
			setTimeout(() => {
				api.classAll().then(res => {
					setClassAll(v => [
						v[0], // 始终保留第一条数据 (全部)
						...res.data.rows.map(v => ({
							_id: v._id,
							label: v.label,
							key: v._id,
							sort: v.sort,
						}))
					])
				})
			}, 0)
		}
	}

	// 新增
	const add = async (fd) => {
		setAddConfirmLoading(true)

		try {
			await api.classAdd({ data: fd }) // 新增
		} catch (error) {
			console.log(error)
			setAddConfirmLoading(false)
			return
		}

		getClassAll(isLogin, homeIsShowList) // 刷新
		setAddConfirmLoading(false)
		setIsShow(false)
		message.success('操作成功')
	}

	// 删除
	const del = (key) => {
		confirm({
			title: '提示',
			icon: <ExclamationCircleFilled />,
			content: <>
				<p>确认删除所选分类?</p>
				<p>{'该分类下的书签不会删除 仍可在<全部>书签下查询到'}</p>
			</>,
			okType: 'danger',
			onOk: async function () {
				try {
					await api.classDelClass({ _id: key })

					// 如果所删分类 === 当前激活状态的分类 则跳转到全部
					if (currentSelectClass === key) {
						setCurrentSelectClass('1')
					}

					getClassAll(isLogin, homeIsShowList) // 刷新
				} catch (error) {
					message.error(error)
				}
				message.success('操作成功')
			},
		})
	}

	// 修改
	const onEdit = async (key, action) => {
		if (action === 'add') {
			if (!isLogin) {
				setIsShowLogin(true)
				return
			}

			const newSort = await classGetSortMax()
			form.setFieldsValue({ sort: newSort })
			setIsShow(true)
		} else {
			del(key)
		}
	}

	// 获取当前用户 Sort 最大的一条数据
	const classGetSortMax = async () => {
		const data = await api.classGetSortMax()
		const maxSort = data?.data?.rows?.[0]?.sort || 1
		return maxSort + 1
	}

	const onChange = (v) => {
		setCurrentSelectClass(v)

		// 分页数据重置到1
		setCardListPaginationData(v => ({
			...v,
			current: 1,
		}))

		setTimeout(() => {
			window.__app__?.search({ currentSelectClass: v })
		}, 0)
	}

	return (
		<>
			<Tabs
				type="editable-card"
				onChange={onChange}
				activeKey={currentSelectClass}
				onEdit={onEdit}
				items={classAll}
				tabBarExtraContent={<Set refresh={getClassAll} />}
				size='small'
			/>

			<Modal
				width={450}
				title="新增分类"
				open={isShow}
				onOk={form.submit}
				onCancel={() => setIsShow(false)}
				confirmLoading={addConfirmLoading}
			>
				<Form form={form} onFinish={add}>
					<Form.Item
						label="分类"
						name="label"
						rules={[{ required: true }]}
					>
						<Input allowClear />
					</Form.Item>

					<Form.Item
						label="排序"
						name="sort"
						rules={[{ required: true }]}
					>
						<InputNumber min={1} style={{ width: '100%' }} />
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
})

export default MyTabs
