import { Tag } from 'antd'
import { memo } from 'react'

// Tag 回显字典内容
const MyTag = memo((props) => {
	const { options, value } = props
	const item = options?.[value]

	return value ? <Tag color={item?.color}>{item?.label}</Tag> : null
})

export default MyTag
