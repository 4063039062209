import React, { useState, useMemo } from 'react'
import { Avatar } from 'antd'

// 头像的二次封装(src可以传入多个url 如果加载失败则加载下一张图片)
const Pic = ({ src, ..._props }) => {
	const [currentSrcIndex, setCurrentSrcIndex] = useState(0)

	const _src = useMemo(() => {
		if (src[currentSrcIndex]) {
			return src[currentSrcIndex]
		} else {
			if (currentSrcIndex + 1 < src.length) {
				setCurrentSrcIndex(currentSrcIndex + 1)
			}
		}

	}, [src, currentSrcIndex])

	const handleImageError = () => {
		if (currentSrcIndex < src.length - 1) {
			setCurrentSrcIndex(currentSrcIndex + 1)
		}
	}

	return (
		<Avatar src={_src} onError={handleImageError} {..._props} />
	)
}

export default Pic