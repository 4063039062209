import React, { memo, useEffect } from 'react'
import { Menu, Button } from 'antd'
import styles from '../../index.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import {
	leftCollapsed as _leftCollapsed,
	leftMenuData as _leftMenuData,
	topMenuSelectedData as _topMenuSelectedData,
	leftMenuSelectedData as _leftMenuSelectedData,
	topMenuSelectedKeys as _topMenuSelectedKeys,
} from '@/state'
import { icon, isLink, splitPath } from '@/tools'

const MyMenu = memo((props) => {
	const { isShowSider, menuData = [] } = props
	const location = useLocation()
	const navigate = useNavigate()
	const [collapsed, setCollapsed] = useRecoilState(_leftCollapsed) // 左栏折叠状态
	const [, setLeftMenuData] = useRecoilState(_leftMenuData) // 左栏数据
	const [, setTopMenuSelectedData] = useRecoilState(_topMenuSelectedData) // 顶栏选中数据
	const [topMenuSelectedKeys, setTopMenuSelectedKeys] = useRecoilState(_topMenuSelectedKeys) // 顶栏选中key
	const [, setLeftMenuSelectedData] = useRecoilState(_leftMenuSelectedData) // 左栏选中数据

	// 1 初始化时
	// 2 路由变化
	//  根据url是否命中menu数据(命中时默认选中 否者没有默认选中的菜单)
	useEffect(() => {
		const path = location.pathname

		const pathArr = splitPath(path)
		const hit = menuData.filter((v) => {
			const currentKey = v.key

			// currentKey是url开头会有/
			if (isLink(currentKey)) {
				return currentKey === path || currentKey === pathArr[0]
			} else {
				return currentKey === path || currentKey === pathArr[0].slice(1) // 切掉开头的 /
			}
		})

		if (hit?.length) {
			setTopMenuSelectedKeys([hit[0].key])
		}
	}, [location, menuData])

	// 选择菜单时
	const onSelect = ({ key, item }) => {
		// keyPath, selectedKeys, domEvent
		setTopMenuSelectedKeys(key) // menu选中状态

		// 存储顶栏选中的数据 用于面包屑展示
		try {
			for (const i of menuData) {
				if (i.key === key) {
					setTopMenuSelectedData(i)
					setLeftMenuSelectedData([])
					break
				}
			}
		} catch (error) {
			console.log(error)
		}

		// key 以 / 开头的是url 需要跳转
		if (isLink(key)) {
			navigate(key)
			setLeftMenuData([]) // 清空左栏
			return
		}

		// 左栏跳转: 有左栏数据的 修改左栏数据
		const leftMenuData = item?.props?.leftchildren || []
		setLeftMenuData(leftMenuData)
		if (leftMenuData.length) {
			// 如果左栏数据第一条是url直接跳转
			const leftKey = leftMenuData[0]?.key || ''
			const leftChildrenKey = leftMenuData[0]?.children?.[0]?.key || ''

			if (isLink(leftKey)) {
				navigate(leftKey) // 拼接一级导航栏的key
				setLeftMenuSelectedData([leftMenuData[0]]) // 选中的左栏数据
			} else if (isLink(leftChildrenKey)) {
				// 否者跳转第一条的子项
				navigate(leftChildrenKey)
				setLeftMenuSelectedData([leftMenuData[0], leftMenuData[0].children[0]]) // 选中的左栏数据
			}
		}
	}

	return (
		<div className={styles.topMenu}>
			{isShowSider ? (
				<Button
					type="text"
					icon={
						collapsed ? icon('MenuUnfoldOutlined') : icon('MenuFoldOutlined')
					}
					onClick={() => setCollapsed(!collapsed)}
					style={{ fontSize: 16, width: 64, height: 64, color: '#ffffffa6' }}
				/>
			) : null}

			<Menu
				theme="dark"
				mode="horizontal"
				items={menuData.map((v) =>
					v?.icon ? { ...v, icon: icon(v?.icon) } : v,
				)}
				selectedKeys={topMenuSelectedKeys}
				onSelect={onSelect}
				style={{ width: `calc(100% - ${isShowSider ? '64px' : '0'} )` }}
			/>
		</div>
	)
})

export default MyMenu
