import { memo, useState } from 'react'
import api from '@/api'
import { Button, Form, Input, Table, Space, message } from 'antd'
import ListPage from '@/components/listPage'
import Search from '@/components/search'
import TimeSelect from '@/components/timeSelect'
import Curd from '@/components/curd'
import Copy from '@/components/copy'
import { useAntdTable } from 'ahooks'
import { tableConfig, arrDayToArrTimestamp, typeOptions, icon, commonColumnsIndex, commonColumnsCreatedAndUpdated } from '@/tools'
import Json from '@/components/json'

const Page = memo(() => {
	const [form] = Form.useForm()
	const [open, setOpen] = useState(false) // curd 窗口展示状态
	const [row, setRow] = useState({}) // 表格当前所选行数据
	const [type, setType] = useState('') // curd 窗口类型
	const [loading, setLoading] = useState(false) // 加载状态

	// 列表数据
	const getTableData = ({ current, pageSize }, formData) => {
		const {
			userId,
			url,
			cookies,
			hostname,
			ip,
			originalUrl,
			path,
			protocol,
			secChUa,
			ua,
			platform,
			origin,
			referer,
			accept,
			language,
			method,

			createdAt,
			updatedAt,
		} = formData

		const filter = {
			url,
			cookies,
			hostname,
			ip,
			originalUrl,
			path,
			protocol,
			secChUa,
			ua,
			platform,
			origin,
			referer,
			accept,
			language,
			userId,
			method,

			createdAt: arrDayToArrTimestamp(createdAt),
			updatedAt: arrDayToArrTimestamp(updatedAt),
		}

		const req = {
			filter,
			page: current,
			limit: pageSize,
		}

		return api.logList(req).then((res) => {
			res.list = res?.data?.rows
			res.total = res?.data?.count
			return res
		})
	}
	const {
		tableProps,
		search: { submit, reset },
	} = useAntdTable(getTableData, { defaultPageSize: 100, form })

	// 表格列
	const getColumns = () => {
		const data = [
			commonColumnsIndex,
			{
				width: 260,
				title: '_id',
				dataIndex: '_id',
				render: (v) => <Copy>{v}</Copy>,
			},
			{ width: 200, title: '用户id', dataIndex: 'userId', ellipsis: true },
			{ width: 200, title: 'url', dataIndex: 'url', ellipsis: true },
			{ width: 200, title: 'hostname', dataIndex: 'hostname', ellipsis: true },
			{ width: 70, title: 'method', dataIndex: 'method', ellipsis: true },
			{ width: 200, title: 'cookies', dataIndex: 'cookies', ellipsis: true },
			{ width: 200, title: 'ip', dataIndex: 'ip', ellipsis: true },
			{ width: 200, title: 'originalUrl', dataIndex: 'originalUrl', ellipsis: true },
			{ width: 200, title: 'path', dataIndex: 'path', ellipsis: true },
			{ width: 200, title: 'protocol', dataIndex: 'protocol', ellipsis: true },
			{ width: 200, title: 'secChUa', dataIndex: 'secChUa', ellipsis: true },
			{ width: 200, title: 'ua', dataIndex: 'ua', ellipsis: true },
			{ width: 200, title: 'platform', dataIndex: 'platform', ellipsis: true },
			{ width: 200, title: 'origin', dataIndex: 'origin', ellipsis: true },
			{ width: 200, title: 'referer', dataIndex: 'referer', ellipsis: true },
			{ width: 200, title: 'accept', dataIndex: 'accept', ellipsis: true },
			{ width: 200, title: 'language', dataIndex: 'language', ellipsis: true },
			...commonColumnsCreatedAndUpdated,
			{
				width: 135,
				title: '操作',
				fixed: 'right',
				render: (v, row) => (
					<Space>
						<Button size="small" onClick={() => handle('get', row)}>
              查看
						</Button>
						<Button size="small" onClick={() => handle('del', row)} danger>
              删除
						</Button>
					</Space>
				),
			},
		]
		return data
	}

	// 打开curd操作抽屉
	const handle = (type, row = {}) => {
		setRow(row)
		setType(type)
		setOpen(true)
	}

	// curd操作
	const curdSubmit = async (formData, row, type) => {
		formData = { ...formData }

		const init = () => {
			setLoading(false) // 取消加载状态
			setOpen(false) // 关闭窗口
			submit() // 刷新外层列表
			message.success('操作成功')
		}

		try {
			setLoading(true)

			switch (type) {
			case 'del':
				await api.logDel({ _id: row._id })
				init()
				break

			case 'get':
				setLoading(false)
				setOpen(false) // 关闭窗口
				break

			default:
				break
			}
		} catch (error) {
			setLoading(false)
			console.log('curd报错: ', error)
		}
	}

	// 搜索栏-表单数据
	const searchFormData = () => {
		return [
			{
				formItemProps: { name: 'userId' },
				render: <Input placeholder="用户Id" allowClear />,
			},
			{
				formItemProps: { name: 'url' },
				render: <Input placeholder="url" allowClear />,
			},
			{
				formItemProps: { name: 'hostname' },
				render: <Input placeholder="hostname" allowClear />,
			},
			{
				formItemProps: { name: 'method' },
				render: <Input placeholder="method" allowClear />,
			},
			{
				formItemProps: { name: 'ip' },
				render: <Input placeholder="ip" allowClear />,
			},
			{
				formItemProps: { name: 'originalUrl' },
				render: <Input placeholder="originalUrl" allowClear />,
			},
			{
				formItemProps: { name: 'path' },
				render: <Input placeholder="path" allowClear />,
			},
			{
				formItemProps: { name: 'protocol' },
				render: <Input placeholder="protocol" allowClear />,
			},
			{
				formItemProps: { name: 'secChUa' },
				render: <Input placeholder="secChUa" allowClear />,
			},
			{
				formItemProps: { name: 'ua' },
				render: <Input placeholder="ua" allowClear />,
			},
			{
				formItemProps: { name: 'platform' },
				render: <Input placeholder="platform" allowClear />,
			},
			{
				formItemProps: { name: 'origin' },
				render: <Input placeholder="origin" allowClear />,
			},
			{
				formItemProps: { name: 'referer' },
				render: <Input placeholder="referer" allowClear />,
			},
			{
				formItemProps: { name: 'accept' },
				render: <Input placeholder="accept" allowClear />,
			},
			{
				formItemProps: { name: 'createdAt' },
				render: (
					<TimeSelect placeholder={['创建时间-开始', '创建时间-结束']} />
				),
				colPorps: { multiple: 2 },
			},
			{
				formItemProps: { name: 'updatedAt' },
				render: (
					<TimeSelect placeholder={['更新时间-开始', '更新时间-结束']} />
				),
				colPorps: { multiple: 2 },
			},
			{
				formItemProps: { name: 'language' },
				render: <Input placeholder="language" allowClear />,
			},
		]
	}

	// 搜索栏-按钮数据
	const searchBtnData = () => {
		return [
			{
				title: '搜索',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={icon('SearchOutlined')}
						onClick={submit}
						loading={tableProps.loading}
					/>
				),
			},
			{
				title: '重置',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={icon('ReloadOutlined')}
						onClick={reset}
					/>
				),
			},
		]
	}

	// curd-表单数据
	const curdFormConfig = () => {
		return [
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '用户Id',
					name: 'userId',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'url',
					name: 'url',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'method',
					name: 'method',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'cookies',
					name: 'cookies',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'hostname',
					name: 'hostname',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'ip',
					name: 'ip',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'originalUrl',
					name: 'originalUrl',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'path',
					name: 'path',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'protocol',
					name: 'protocol',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'secChUa',
					name: 'secChUa',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'ua',
					name: 'ua',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'platform',
					name: 'platform',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'origin',
					name: 'origin',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'referer',
					name: 'referer',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'accept',
					name: 'accept',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'language',
					name: 'language',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'query',
					name: 'query',
				},
				render: <Json />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'params',
					name: 'params',
				},
				render: <Json />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'body',
					name: 'body',
				},
				render: <Json />,
			},
		]
	}

	return (
		<ListPage
			search={
				<Search
					form={form}
					submit={submit}
					formData={searchFormData()}
					btnData={searchBtnData()}
				/>
			}
		>
			<Table
				rowKey={(v) => v._id}
				columns={getColumns()}
				{...tableProps}
				{...tableConfig({ tableProps })}
			/>

			<Curd
				width={800}
				open={open}
				setOpen={setOpen}
				row={row}
				type={type}
				title={`${typeOptions?.[type] || ''}日志`}
				formProps={{ labelCol: { span: 3 }, wrapperCol: { span: 21 } }}
				formConfig={curdFormConfig()}
				submit={curdSubmit}
				loading={loading}
			/>
		</ListPage>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: false,
}
