import { useState, useMemo, useEffect } from 'react'
import { Image, Form, Avatar, Modal, Space, message } from 'antd'
import { icon } from '@/tools'
import styles from './index.module.scss'
import ImgCrop from 'antd-img-crop'
import { Upload } from 'antd'
import api from '@/api'
import { useRecoilState } from 'recoil'
import { userInfo as _userInfo } from '@/state'
import { imgUrl } from '@/api/request'


// 图片展示+编辑
const PicDisplayEdit = () => {
	const [form] = Form.useForm()
	const [userInfo, setUserInfo] = useRecoilState(_userInfo) // 用户信息
	const [open, setOpen] = useState(false)
	const [previewSrc, setPreviewSrc] = useState() // 预览用的临时src
	const [visible, setVisible] = useState(false) // 是否展示预览浮层
	const [fileList, setFileList] = useState([]) // 已选(回显)文件列表
	const picSrc = useMemo(() => userInfo?.userInfo?.pic ? `${imgUrl}/${userInfo?.userInfo?.pic}` : undefined, [userInfo]) // 用户头像

	// 图片选项change
	const onChange = ({ file, fileList: newFileList, event }) => {
		setFileList(newFileList)
	}

	// 查看图片详情
	const onPreview = async (file) => {
		let src = file?.thumbUrl || file?.url
		setPreviewSrc(src)
		setVisible(true) // 展示预览浮层
	}

	// 提交新头像
	const submit = async (formData) => {
		const { pic } = formData

		try {
			const res = await api.userSave({ pic })
			message.success('操作成功')
			setUserInfo(res.data)

			// 
		} catch (error) {
			//
		}

		setOpen(false)
	}

	// 上传图片
	const customRequest = ({ file, onSuccess, onError }) => {
		const formData = new FormData()
		formData.append('file', file)

		// 判断文件大小是否超过0.5MB
		const fileSize = file.size // 文件大小，单位为字节
		const maxSize = 0.5 * 1024 * 1024 // 0.5MB的限制，转换为字节
		if (fileSize > maxSize) {
			onError('文件尺寸最大 0.5MB')
			message.error('文件尺寸最大 0.5MB')
			return
		}

		// 判断文件类型是否为jpg/jpeg/png/gif
		const fileType = file.type // 文件类型
		const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'] // 允许的文件类型
		if (!allowedTypes.includes(fileType.toLowerCase())) {
			onError('只允许上传jpg/jpeg/png/gif格式的图片')
			message.error('只允许上传jpg/jpeg/png/gif格式的图片')
			return
		}

		// 调用自己封装的接口函数
		api.uploadsSave(formData).then(res => {
			// 上传成功
			if (res?.code === 0 && res?.data?.acknowledged) {
				const { fileName } = res.data // 假设返回的URL字段为url
				form.setFieldsValue({ pic: fileName }) // 设置url字段的值
			}

			onSuccess(res)
			form.submit()
		}).catch(error => {
			// 上传失败
			console.log('error', error)
			onError(error)
		})
	}

	// 初始化
	useEffect(() => {
		if (userInfo?.userInfo?.pic && picSrc) {
			form.setFieldsValue({ pic: userInfo?.userInfo?.pic }) // 表单数据回填
			setFileList([
				{
					uid: '-1',
					name: 'image.png',
					status: 'done',
					thumbUrl: picSrc
				}
			]) // 头像图片回填
		}
	}, [userInfo, picSrc])

	return (
		<section className={styles.pictureDisplayEditor}>
			<Avatar
				className={styles.avatar}
				size={50}
				icon={icon('UserOutlined')}
				src={picSrc}
				onClick={() => setOpen(true)}
			>
				{userInfo?.userInfo?.name}
			</Avatar>

			<Modal
				title='修改头像'
				open={open}
				onOk={() => setOpen(false)}
				onCancel={() => setOpen(false)}
				width={450}
			>
				<div className={styles.modalBody}>
					<Form form={form} onFinish={submit}>
						<Form.Item name='pic' rules={[{ required: true, message: '请上传头像' }]}>
							<ImgCrop
								rotationSlider
								showGrid
							>
								<Upload
									listType='picture-card'
									fileList={fileList}
									onChange={onChange}
									onPreview={onPreview} // 预览
									customRequest={customRequest}
									style={{ width: '100%' }}
								>
									{
										!fileList?.length ?
											(
												<Space direction='vertical' size={0}>
													<p className={styles.icon}>{icon('CloudUploadOutlined')}</p>
													<p className={styles.desc}>点击或拖动上传</p>
												</Space>
											)
											:
											null
									}
								</Upload>
							</ImgCrop>
						</Form.Item>
					</Form>

					{/* 图片预览 */}
					<Image
						width={200}
						style={{ display: 'none' }}
						src={previewSrc}
						preview={{
							visible,
							src: previewSrc,
							onVisibleChange: (value) => {
								setVisible(value)
							},
						}}
					/>
				</div>
			</Modal>
		</section>
	)
}

export default PicDisplayEdit
