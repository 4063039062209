// 懒加载组件
/*
  Q: 基于 react hook的版本编写一个 懒加载容器组件 要求该组件进入浏览器可视区范围内时内部的内容才开始加载
  A: 下面是一个基于 React Hook 的懒加载容器组件的示例代码：
    在上面的代码中，我们使用了 useRef 来创建一个容器的引用，然后使用 useState 来跟踪容器是否可见。在 useEffect 中，我们创建了一个 IntersectionObserver 实例来观察容器是否进入浏览器可视区域。当容器进入可视区域时，entry.isIntersecting 的值将为 true，我们将其设置为 isVisible 的状态值。最后，我们根据 isVisible 的值来决定是否渲染容器内部的内容。
    在上面的示例中，<LazyLoadContainer> 组件将包裹需要懒加载的内容，当容器进入浏览器可视区域时，内容将开始加载。
*/

/*
	Q: 对代码进行优化 避免重复触发加载事件
	A: 在优化后的代码中，我们在 IntersectionObserver 的回调函数中增加了一个判断条件，只有当 entry.isIntersecting 为 true 时才设置 isVisible 为 true，并且在设置完 isVisible 后立即调用 observer.unobserve(containerRef.current) 来停止观察当前容器，避免重复触发加载。同时，在 useEffect 中也增加了一个判断条件，只有当 containerRef.current 存在时才开始观察容器，避免在组件卸载时出现错误。
*/

import React, { useRef, useEffect, useState, memo } from 'react'

const LazyLoadContainer = memo(({ children, style, ...props }) => {
	const containerRef = useRef(null)
	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			const [entry] = entries
			if (entry.isIntersecting) {
				setIsVisible(true)
				observer.unobserve(containerRef.current)
			}
		})

		if (containerRef.current) {
			observer.observe(containerRef.current)
		}

		return () => {
			if (containerRef.current) {
				observer.unobserve(containerRef.current)
			}
		}
	}, [])

	return (
		<div ref={containerRef} {...props} style={{ ...style }}>
			{isVisible ? children : null}
		</div>
	)
})

export default LazyLoadContainer
