import { memo } from 'react'
import styles from './index.module.scss'
import Tabs from './components/tabs'
import More from './components/more'
import Pagination from './components/pagination'
import Card from './components/card'
import { useRecoilState } from 'recoil'
import { homeIsShowList as _homeIsShowList, isLogin as _isLogin } from '@/state'
import Logo from '@/components/logo'
import SearchBar from '@/components/searchBar'

const Home = memo(() => {
	const [homeIsShowList] = useRecoilState(_homeIsShowList) // 是否展示list
	const [isLogin] = useRecoilState(_isLogin) // 是否登录

	return (
		<div className={styles.home}>
			<Logo size='350X80' />

			<SearchBar />

			<More />

			{
				homeIsShowList ? (
					<div>
						<header>
							<Tabs />
						</header>

						<section>
							<Card />
						</section>

						<footer className={styles.footer}>
							{ isLogin ? <Pagination /> : null }
						</footer>
					</div>
				): null
			}
		</div>
	)
})

export default Home
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: false,
	isShowBreadcrumb: false,
	isShowContent: true,
	isShowFooter: false,
}
