import { memo, useMemo, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Layout, theme, Breadcrumb, message } from 'antd'
import styles from '../../index.module.scss'
import User from '../user'
import Logo from '@/components/logo'
import TopMenu from '../topMenu'
import LeftMenu from '../leftMenu'
import { useRecoilState } from 'recoil'
import {
	leftCollapsed as _leftCollapsed,
	topMenuSelectedData as _topMenuSelectedData,
	leftMenuSelectedData as _leftMenuSelectedData,
	layoutInnterSize as _layoutInnterSize,
	windowSize as _windowSize,
	breadcrumbSize as _breadcrumbSize,
	headerSize as _headerSize,
	pageAuthority as _pageAuthority,
	topMenuSelectedKeys as _topMenuSelectedKeys,
} from '@/state'
import { isLink, onWindowSize, appSave, getAllKeys, PName } from '@/tools'
import { useRequest } from 'ahooks'
import api from '@/api'
import _ from 'lodash'

const { Header, Content, Footer, Sider } = Layout

const MyLayout = memo((props) => {
	const {
		children,
		isShowHeader,
		isShowSider,
		isShowBreadcrumb,
		isShowContent,
		isShowFooter,
		fileName,
	} = props
	const navigate = useNavigate()
	const location = useLocation()
	const { token: { colorBgContainer } } = theme.useToken()
	const [collapsed, setCollapsed] = useRecoilState(_leftCollapsed) // 左栏折叠状态
	const [topMenuSelectedData] = useRecoilState(_topMenuSelectedData) // 顶栏选择数据
	const [leftMenuSelectedData] = useRecoilState(_leftMenuSelectedData) // 左栏选择数据
	const [, setLayoutInnterSize] = useRecoilState(_layoutInnterSize) // 布局容器-内容区域-尺寸
	const [, setWindowSize] = useRecoilState(_windowSize) // 视口区域-尺寸
	const [, setBreadcrumbSize] = useRecoilState(_breadcrumbSize) // 面包屑区域-尺寸
	const [, setHeaderSize] = useRecoilState(_headerSize) // 顶栏区域-尺寸
	const [pageAuthority] = useRecoilState(_pageAuthority) // 用户页面权限
	const [, setTopMenuSelectedKeys] = useRecoilState(_topMenuSelectedKeys) // 顶栏选中key

	const { data: menuData, run } = useRequest(() => api.menuShow().then(
		(res) =>
			res?.data?.rows?.map((v) => ({
				// 菜单数据
				..._.omit(v, ['children', 'isDel', 'updatedAt', 'createdAt']), // 将一级菜单下的 children 替换成 leftchildren
				leftchildren: v.children,
			})) || [],
	))

	// mune 数据中的全部 url
	const menuUrlAll = useMemo(() => {
		if (Array.isArray(menuData)) {
			return getAllKeys(menuData || [])
		} else {
			return []
		}
	}, [menuData])

	// 路由守卫-监听路由变化时检验用户是否有权限访问对应页面
	const routeGuard = (pathname, routes) => {
		// pathname 不在 menuItems 数据中存在时 把 menu 取消选中
		if (!menuUrlAll.includes(pathname)) {
			setTopMenuSelectedKeys(undefined)
		}

		// 页面白名单(游客可访问)
		const pageWhitelist = [
			'/', // 首页
			'/recommend', // 推荐页面
			// ...
		]

		if (pageWhitelist.includes(pathname)) {
			// 白名单内不做处理-正常跳转
		} else if (!pageAuthority.includes(pathname)) {
			// 不在白名单的 需要鉴权
			// 用户选择跳转的 url存在但没有权限的页面 报<权限不足>
			if (routes.includes(pathname)) {
				message.error(`页面: ${pathname} 权限不足 ╮(๑•́ ₃•̀๑)╭`)
				navigate('/')
			} else {
				// 不存在的页面不做处理 通配会重定向到404页面
			}
		}
	}
	useEffect(
		() => routeGuard(location.pathname, window?.__app__?.routes),
		[location.pathname, window?.__app__?.routes]
	)

	// 存储刷新全局菜单数据的方法供其他模块调用
	useEffect(() => {
		if (typeof run === 'function') {
			appSave('runRefreshMenu', run)
		}
	}, [run])

	const layoutInnter = 'layoutInnter' // className
	const layoutCtn = 'layoutCtn' // className
	const layoutBreadcrumb = 'layoutBreadcrumb' // className
	const layoutHeader = 'layoutHeader' // className

	// 面包屑数据
	const breadcrumbData = useMemo(() => {
		const res = [] // 返回给面包屑组件
		const breadcrumbInfo = [] // 用于替换title数据

		if (topMenuSelectedData?.label) {
			if (isLink(topMenuSelectedData?.key)) {
				res.push({
					title: (
						<Link to={topMenuSelectedData.key}>
							{topMenuSelectedData.label}
						</Link>
					),
				})
				breadcrumbInfo.push({
					title: topMenuSelectedData.label,
					url: topMenuSelectedData.key,
				})
			} else {
				res.push({ title: topMenuSelectedData.label })
				breadcrumbInfo.push({ title: topMenuSelectedData.label })
			}
		}

		if (leftMenuSelectedData?.length) {
			const breadcrumbDataLast = leftMenuSelectedData.map((v) => {
				if (isLink(v?.key)) {
					breadcrumbInfo.push({ title: v.label, url: v.key })
					return { title: <Link to={v.key}>{v.label}</Link> }
				} else {
					breadcrumbInfo.push({ title: v.label })
					return { title: v.label }
				}
			})
			res.push(...breadcrumbDataLast)
		}

		// 替换html-title内容
		try {
			if (breadcrumbInfo.length) {
				let title = PName
				const data = breadcrumbInfo.map((v) => v.title)
				title += `-${data.join('-')}`
				document.title = title
			}
		} catch (error) {
			console.log('替换HTML-title内容报错: ', error)
		}

		return res
	}, [topMenuSelectedData, leftMenuSelectedData])

	// 高度监听
	useEffect(() => {
		onWindowSize(document.body, setWindowSize) // 视口尺寸
		onWindowSize(`.${layoutBreadcrumb}`, setBreadcrumbSize) // 面包屑尺寸
		onWindowSize(`.${layoutHeader}`, setHeaderSize) // 顶栏尺寸

		// 内容区尺寸
		if (isShowContent) {
			onWindowSize(`.${layoutInnter}`, setLayoutInnterSize)
		} else {
			onWindowSize(`.${layoutCtn}`, setLayoutInnterSize)
		}
	}, [isShowContent])

	return (
		<Layout className={styles.ctn}>
			{isShowHeader ? (
				<Header className={`${styles.header} ${layoutHeader}`}>
					<Logo size='36X36' />
					<TopMenu isShowSider={isShowSider} menuData={menuData} />
					<User />
				</Header>
			) : null}

			<Layout>
				{isShowSider ? (
					<Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
						<LeftMenu />
					</Sider>
				) : null}

				<Content id="drawerDisplayArea" className={styles.fixedContainer}>
					<Content className={styles.content}>
						{isShowBreadcrumb ? (
							<Breadcrumb
								style={{ paddingBottom: 16 }}
								items={breadcrumbData}
								className={layoutBreadcrumb}
							/>
						) : null}

						{isShowContent ? (
							<Content
								style={{
									background: colorBgContainer,
									minHeight: `calc(100vh - 64px - 40px ${
										isShowBreadcrumb ? '- 38px' : ''
									})`,
								}}
								className={`${styles.innter} ${layoutInnter}`}
							>
								{children}
							</Content>
						) : (
							<div
								style={{ margin: -20 }}
								className={`${styles.ctn} ${layoutCtn}`}
							>
								{children}
							</div>
						)}

						{isShowFooter ? (
							<Footer style={{ textAlign: 'center' }}></Footer>
						) : null}
					</Content>
				</Content>
			</Layout>
		</Layout>
	)
})

export default MyLayout
