import { Space, Divider, Button, Typography, Select, Form } from 'antd'
// import Calc from './components/calc'
// import TwinSelector from '@/components/twinSelector'
import PermissionsShow from '@/components/permissionsShow'
import ColorSelcetGroup from '@/components/colorSelcetGroup'

const { Title } = Typography

const Test = () => {
	// const [form] = Form.useForm()

	const onValuesChange = (fd) => {
		console.log(fd)
	}

	return (
		<div style={{ padding: 20 }}>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Title level={5}>权限测试</Title>

				<PermissionsShow permissionsId={'64dae80dc284da5d0bb7c803'}>
					<Button type='primary'>增</Button>
				</PermissionsShow>

				<PermissionsShow permissionsId={'64dae864c284da5d0bb7c811'}>
					<Button type='primary'>删</Button>
				</PermissionsShow>

				<PermissionsShow permissionsId={'64dae832c284da5d0bb7c80a'}>
					<Button type='primary'>改</Button>
				</PermissionsShow>

				<PermissionsShow permissionsId={'64dae989c284da5d0bb7c826'}>
					<Button type='primary'>查</Button>
				</PermissionsShow>

				<Divider />

				<Title level={5}>可自定义的多选</Title>

				<Select
					mode="tags"
					style={{ width: '100%' }}
					allowClear
					options={[{ label: 'a', value: '/a' }, { label: 'b', value: '/b' }]}
					// onChange={onChange}
					// onChange={handleChange}
					// onSearch={handleSearch}
				/>

				<Divider />

				<Form onValuesChange={onValuesChange}>
					<Form.Item name='color'>
						<ColorSelcetGroup placeholder={'颜色'} />
					</Form.Item>
				</Form>

				<Divider />


			</Space>
		</div>
	)
}

export default Test
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: true,
}
