import { memo, useState } from 'react'
import { Col, Row, Form, Button, Space, Modal, Spin, message } from 'antd'
import styles from './index.module.scss'
import { icon } from '@/tools'
import FormItmeRender from '@/components/formItmeRender'


const ShowData = memo((props) => {
	const { data = [], form, attributeSubmit, modalSubmit, labelCol = { span: 4 }, wrapperCol = { span: 20 } } = props

	const [renderForm, setRenderForm] = useState({})
	const [attribute, setAttribute] = useState({}) // 当前属性
	const [open, setOpen] = useState(false) // 弹窗展示
	const [modalFormItemData, setModalFormItemData] = useState([]) // 弹窗表单内容
	const [loading, setLoading] = useState(false)

	// 表单 + 保存按钮
	const attributeForm = (v, k) => (
		<>
			<Form.Item
				{...v?.formConfig?.formItemProps}
				className={styles.formItem}
			>
				{v?.formConfig?.render}
			</Form.Item>

			<Button
				className={styles.btn}
				onClick={(e) => _attributeSubmit(e, v, k)}
			>
				保存
			</Button>
		</>
	)

	// 打开修改
	const onClick = (v, k) => {
		setAttribute(v)

		// 弹窗处理
		if (v?.modalConfig) {
			setModalFormItemData(v?.modalConfig?.formConfig || [])
			setOpen(true)
			form.resetFields()
		} else if (v?.formConfig?.formItemProps?.name && v?.formConfig?.render) {
			// 展示表单行内处理
			setRenderForm(prev => {
				const o = { ...prev }
				o[k] = true
				return o
			})
		}
	}

	// 单个表单提交
	const _attributeSubmit = async (e, v, k) => {
		e.stopPropagation() // 阻止冒泡

		await form.validateFields() // 表单校验

		setRenderForm(prev => {
			const o = { ...prev }
			o[k] = false
			return o
		})

		const fd = form.getFieldsValue()

		if (typeof attributeSubmit === 'function') {
			attributeSubmit(fd, v)
		}
	}

	// 弹窗表单提交
	const _modalSubmit = async (formData, modalFormItemData) => {
		try {
			setLoading(true)
			const res = await modalSubmit?.(formData, modalFormItemData)
			if (res) {
				message.success('操作成功')
				setOpen(false)
			}

			setLoading(false)
		} catch (error) {
			console.log('模态窗错误:', error)
			setLoading(false)
		}
	}

	return (
		<section className={styles.showData}>
			{
				data.map((v, k) => {
					const { formConfig = {} } = v
					const { compact = true } = formConfig

					return (
						<Row
							key={v.label}
							className={styles.row}
							onClick={() => onClick(v, k)}
						>
							<Col className={styles.label} span={5}>{v.label}</Col>
							<Col className={styles.value} span={18}>
								{
									renderForm[k] ?
										(
											<div className={styles.formItemGroup}>
												{
													compact ? (
													// 紧凑布局
														<Space.Compact style={{ width: '100%' }}>
															{attributeForm(v, k)}
														</Space.Compact>
													) : (
													// 两端对齐
														<div className={styles.between}>
															{attributeForm(v, k)}
														</div>
													)
												}
											</div>
										) :
										<div className={styles.show}>{v.value}</div>
								}
							</Col>
							<Col className={styles.right} span={1}>{v?.right ?? icon('RightOutlined')}</Col>
						</Row>
					)
				})
			}

			<Modal
				title={`修改${attribute.label}`}
				open={open}
				onOk={form.submit}
				onCancel={() => setOpen(false)}
				confirmLoading={loading}
				destroyOnClose={true}
			>
				<Spin spinning={loading}>
					<Form
						form={form}
						labelCol={labelCol}
						wrapperCol={wrapperCol}
						onFinish={(fd) => _modalSubmit(fd, modalFormItemData)}
					>
						<div style={{ paddingTop: 20 }}>
							<FormItmeRender data={modalFormItemData} />
						</div>
					</Form>
				</Spin>
			</Modal>
		</section>
	)
})

export default ShowData