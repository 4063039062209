// 搜索栏 功能按钮渲染
import { Space, Tooltip } from 'antd'
import React, { memo } from 'react'

const Btns = memo(({ data }) => {
	return (
		<Space>
			{data.map((v) => (
				<Tooltip placement="top" title={v.title} key={v.title}>
					{v.btn}
				</Tooltip>
			))}
		</Space>
	)
})

export default Btns
