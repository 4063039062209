import { memo, useState, useRef, useMemo, useEffect } from 'react'
import api from '@/api'
import { Button, Form, Input, Table, Space, Cascader, Tag, message, Alert } from 'antd'
import ListPage from '@/components/listPage'
import Search from '@/components/search'
import TimeSelect from '@/components/timeSelect'
import Copy from '@/components/copy'
import Curd from '@/components/curd'
import BoolSelect from '@/components/boolSelect'
import Btns from '@/components/btns'
import { useAntdTable, useRequest } from 'ahooks'
import { SearchOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons'
import { tableConfig, arrDayToArrTimestamp, typeOptions, generateMenuTree, icon, cascaderResultCompletion, clone, recursiveMerge, markSelected, restoreSelectedValues, filterConfig, removeDuplicateChildren, bindDataProcessing, commonColumnsIndex, commonColumnsCreatedAndUpdated } from '@/tools'

const Page = memo(() => {
	const childRef = useRef(null)
	const [form] = Form.useForm()
	const [open, setOpen] = useState(false) // curd 窗口展示状态
	const [row, setRow] = useState({}) // 表格当前所选行数据
	const [type, setType] = useState('') // curd 窗口类型
	const [loading, setLoading] = useState(false) // 加载状态
	const { data: roleAll, run: roleAllRun } = useRequest(() => api.roleAll({}).then((res) => res?.data?.rows || [])) // 全部角色数据-父级选项
	const { data: userAll } = useRequest(() => api.userAll({}).then((res) => res?.data?.rows || [])) // 全部用户数据
	const { data: permissionsAll } = useRequest(() => api.permissionsAll({}).then((res) => res?.data?.rows || [])) // 全部权限数据
	const [tableSelectData, setTableSelectData] = useState([]) // 所选表格数据
	const [tableSelectTreeData, setTableSelectTreeData] = useState([]) // 所选表格数据(树结构数据)
	const [tableSelectKey, setTableSelectKey] = useState([]) // 所选表格key
	const [bindRoleTableData, setBindRoleTableData] = useState([]) // 关联表数据(当前选中权限 & 已关联的角色信息 的归并数据)
	const selectedUserIds = useRef([]) // 单条绑定用户-选中的角色数据
	const selectedPermissionsIds = useRef([]) // 单条绑定权限-选中的角色数据

	const _typeOptions = {
		bindUser: '绑定用户',
		bindPermissions: '绑定权限',
		batchBindUser: '批量绑定用户',
		batchBindPermissions: '批量绑定权限',
	}

	// 列表数据
	const getTableData = ({ current, pageSize }, formData) => {
		const { name, parentId, disabled, desc, createdAt, updatedAt } = formData

		const filter = {
			name,
			parentId,
			disabled,
			desc,
			createdAt: arrDayToArrTimestamp(createdAt),
			updatedAt: arrDayToArrTimestamp(updatedAt),
		}

		const req = {
			filter,
			page: current,
			limit: pageSize,
		}

		return api.roleList(req).then((res) => {
			res.list = generateMenuTree(
				res?.data?.rows?.map((v) => ({ ...v })) || [],
			)
			res.total = res?.data?.count
			return res
		})
	}
	const { tableProps, search: { submit, reset } } = useAntdTable(getTableData, { defaultPageSize: 100, form })

	// 表格列
	const getColumns = (type) => {
		const data = [
			commonColumnsIndex,
			{
				width: 260,
				title: '_id',
				dataIndex: '_id',
				render: (v) => <Copy>{v}</Copy>,
			},
			{ width: 200, title: '角色名称', dataIndex: 'name' },
			{
				width: 100,
				title: '是否禁用',
				dataIndex: 'disabled',
				render: (v) => <Tag>{v ? '是' : '否'}</Tag>,
			},
			{ width: 200, title: '角色描述', dataIndex: 'desc' },
			{
				width: 260,
				title: '父级id',
				dataIndex: 'parentId',
				render: (v) => <Copy>{v}</Copy>,
			},
			...commonColumnsCreatedAndUpdated,
			{
				width: 188,
				title: '操作',
				fixed: 'right',
				render: (v, row) => (
					<Space>
						<Button size="small" onClick={() => handle('get', row)}>查看</Button>
						<Button size="small" onClick={() => handle('set', row)}>修改</Button>
						<Button size="small" onClick={() => handle('del', row)} danger>删除</Button>
						<Button size="small" onClick={() => handle('bindUser', row)}>绑定用户</Button>
						<Button size="small" onClick={() => handle('bindPermissions', row)}>绑定权限</Button>
					</Space>
				),
			},
		]

		if (type === 'batchBindUser' || type === 'batchBindPermissions') {
			return data.slice(0, 3).concat({
				width: 300,
				title: type === 'batchBindUser' ? '已绑用户' : '已绑权限',
				dataIndex: 'bindData',
				render: (v) => v?.length ?
					v.map((i) => {
						if (type === 'batchBindUser') return <Tag key={i._id}>{i?.userData?.[0]?.name || ''}</Tag>
						if (type === 'batchBindPermissions') return <Tag key={i._id}>{i?.permissionsData?.[0]?.name || ''}</Tag>
					})
					: null,
			})
		} else {
			return data
		}
	}

	// 打开curd操作抽屉
	const handle = (type, row = {}) => {
		setRow({
			...row,
			parentId: row?.parentId?.length ? [row?.parentId] : row?.parentId,
		})
		setType(type)
		setOpen(true)
	}

	// curd操作
	const curdSubmit = async (formData, row, type) => {
		formData = { ...formData }

		if (formData?.parentId?.length) {
			formData.parentId = formData.parentId[0]
		} else {
			formData.parentId = null
		}

		const init = () => {
			setLoading(false) // 取消加载状态
			setOpen(false) // 关闭窗口
			submit() // 刷新外层列表
			roleAllRun() // 重新获取角色全表数据
			message.success('操作成功')
		}

		try {
			setLoading(true)

			switch (type) {
			case 'add':
				await api.roleAdd({ data: formData })
				init()
				break

			case 'del':
				await api.roleDel({ _id: row._id })
				init()
				break

			case 'set':
				await api.roleSet({ ...formData, _id: row._id })
				init()
				break

			case 'get':
				setLoading(false)
				setOpen(false)
				break

			case 'bindUser': // 绑定用户
				setLoading(false)
				setOpen(false)
				break

			case 'bindPermissions': // 绑定权限
				setLoading(false)
				setOpen(false)
				break

			case 'batchBindUser': // 批量绑定用户
				await api.user_roleSave({
					userIds: cascaderResultCompletion(formData?.userIds, userAll),
					roleIds: tableSelectKey,
				})
				init()
				break

			case 'batchBindPermissions': // 批量绑定权限
				await api.role_permissionsSave({
					permissionsIds: cascaderResultCompletion(formData?.permissionsIds, permissionsAll),
					roleIds: tableSelectKey,
				})
				init()
				break

			default:
				break
			}
		} catch (error) {
			setLoading(false)
			console.log('curd报错: ', error)
		}
	}

	// 表格选择
	const tableSelectChange = (_selectedRowKeys, _selectedRows) => {
		setTableSelectData(_selectedRows)
		setTableSelectTreeData(removeDuplicateChildren(_selectedRows)) // 剔除 重复的一级节点数据
		setTableSelectKey(_selectedRowKeys)
	}

	// 单条绑定时 锁定无关表单
	const isSetFormDisabled = useMemo(() => (type === 'bindUser' || type === 'bindPermissions' ? true : undefined), [type])

	// 单条权限 绑定/解绑 角色
	const bind = async (value, selectedOptions) => {
		const req = {
			roleIds: [row._id],
		}

		const task = []

		switch (type) {
		case 'bindUser':
			{
				bindDataProcessing({
					req,
					selectedIds: selectedUserIds.current,
					dataAll: userAll,
					targetIds: 'userIds',
					apiDel: api.user_roleConditionalDel,
					apiSave: api.user_roleSave,
					task,
					value,
				})
			}
			break

		case 'bindPermissions':
			{
				bindDataProcessing({
					req,
					selectedIds: selectedPermissionsIds.current,
					dataAll: permissionsAll,
					targetIds: 'permissionsIds',
					apiDel: api.role_permissionsConditionalDel,
					apiSave: api.role_permissionsSave,
					task,
					value,
				})
			}
			break

		default:
			break
		}

		const res = await Promise.allSettled(task) // 增/删
		const isSuccess = res.every(v => v?.value?.code === 0)

		if (isSuccess) {
			message.success('操作成功')
			getBindData() // 从新获取数据
		}
	}

	// 获取curd form配置
	const formConfig = useMemo(() => {
		const data = [
			{
				formItemProps: {
					rules: [{ required: true }, { max: 100 }],
					label: '角色名称',
					name: 'name',
				},
				render: <Input allowClear disabled={isSetFormDisabled} />,
			},
			{
				formItemProps: {
					rules: [
						({ getFieldValue }) => ({
							validator(_, value) {
								if (value?.length > 1)
									return Promise.reject(new Error('只能选择一个节点'))
								return Promise.resolve()
							},
						}),
					],
					label: '父级角色',
					name: 'parentId',
				},
				render: (
					<Cascader
						allowClear
						options={roleAll}
						expandTrigger="hover"
						multiple
						disabled={isSetFormDisabled}
						showSearch
						{...filterConfig}
					/>
				),
			},
			{
				formItemProps: {
					rules: [],
					label: '是否禁用',
					name: 'disabled',
					initialValue: false,
				},
				render: <BoolSelect disabled={isSetFormDisabled} />,
			},
			{
				formItemProps: { rules: [], label: '角色描述', name: 'desc' },
				render: (
					<Input.TextArea allowClear rows={4} showCount maxLength={128} disabled={isSetFormDisabled} />
				),
			},
		]

		switch (type) {
		case 'bindUser': // 绑定用户
			return [
				...data,
				{
					formItemProps: {
						label: '用户',
						name: 'userIds',
					},
					render: (
						<Cascader
							allowClear
							options={userAll}
							multiple
							expandTrigger='hover'
							onChange={bind}
							loading={loading}
							disabled={loading}
							{...filterConfig}
						/>
					),
				},
			]

		case 'bindPermissions': // 绑定权限
			return [
				...data,
				{
					formItemProps: {
						label: '权限',
						name: 'permissionsIds',
					},
					render: (
						<Cascader
							allowClear
							options={permissionsAll}
							multiple
							expandTrigger='hover'
							onChange={bind}
							loading={loading}
							disabled={loading}
							{...filterConfig}
						/>
					),
				},
			]

		case 'batchBindUser': // 批量绑定用户
			return [
				{
					formItemProps: {
						label: '用户',
						name: 'userIds',
						labelCol: { span: 2 },
						wrapperCol: { span: 22 },
					},
					render: (
						<Cascader
							allowClear
							options={userAll}
							multiple
							expandTrigger='hover'
							{...filterConfig}
						/>
					),
				},
			]

		case 'batchBindPermissions': // 批量绑定权限
			return [
				{
					formItemProps: {
						label: '权限',
						name: 'permissionsIds',
						labelCol: { span: 2 },
						wrapperCol: { span: 22 },
					},
					render: (
						<Cascader
							allowClear
							options={permissionsAll}
							multiple
							expandTrigger='hover'
							{...filterConfig}
						/>
					),
				},
			]

		default: // 增删改查
			return data
		}
	}, [type, row])

	// 获取已绑定数据
	const getBindData = async () => {
		setLoading(true)
	
		const req = { roleIds: [row._id] }

		// 回显数据处理
		const bindCascaderEcho = (res, all) => {
			const selectedData = res?.data?.[0]?.data?.map((v) => type === 'bindUser' ? v.userId : v.permissionsId) || []

			// 克隆
			const _all = clone(all)

			// 打标
			const markingData = markSelected(selectedData, _all)

			// 抽取
			const reductionData = restoreSelectedValues(markingData)

			const fd = {}
			switch (type) {
			case 'bindUser':
				fd.userIds = reductionData
				selectedUserIds.current = reductionData // 存储
				break

			case 'bindPermissions':
				fd.permissionsIds = reductionData
				selectedPermissionsIds.current = reductionData // 存储
				break

			default:
				break
			}

			// 渲染
			childRef.current.form.setFieldsValue(fd)
			setLoading(false)
		}

		switch (type) {
		case 'bindUser':
			{
				const res = await api.user_roleFind(req)
				bindCascaderEcho(res, userAll)
			}
			break

		case 'bindPermissions':
			{
				const res = await api.role_permissionsFind(req)
				bindCascaderEcho(res, permissionsAll)
			}
			break

		default:
			break
		}
	}

	// 打开绑定窗口时查询并回显已绑定数据
	useEffect(() => {
		if (open) {
			try {
				const resEcho = (res) => {
					const dic = {}

					res?.data?.forEach(({ _id, data }) => {
						dic[_id] = data
					})

					const data = clone(tableSelectTreeData)

					recursiveMerge(data, dic)
					setBindRoleTableData(data)
					setLoading(false)
				}

				switch (type) {
				case 'bindUser': // 绑定用户
					getBindData() // 获取并回显 已绑定数据
					break

				case 'bindPermissions': // 绑定权限
					getBindData() // 获取并回显 已绑定数据
					break

				case 'batchBindUser': // 批量绑定用户
					{
						setLoading(true)
						const req = { roleIds: tableSelectKey }
						api.user_roleFind(req).then((res) => {
							resEcho(res)
						})
					}
					break

				case 'batchBindPermissions': // 批量绑定权限
					{
						setLoading(true)
						const req = { roleIds: tableSelectKey }
						api.role_permissionsFind(req).then((res) => {
							resEcho(res)
						})
					}
					break

				default:
					break
				}
			} catch (error) {
				setLoading(false)
				console.log(error)
			}
		} else {
			setBindRoleTableData([])
			selectedUserIds.current = []
			selectedPermissionsIds.current = []
		}
	}, [open, type])

	return (
		<ListPage
			search={
				<Search
					form={form}
					submit={submit}
					formData={[
						{
							formItemProps: { name: 'name' },
							render: <Input placeholder="角色名称" allowClear />,
						},
						{
							formItemProps: { name: 'desc' },
							render: <Input placeholder="角色描述" allowClear />,
						},
						{
							formItemProps: { name: 'parentId' },
							render: (
								<Cascader
									placeholder='父级角色'
									allowClear
									options={roleAll}
									expandTrigger="hover"
									{...filterConfig}
								/>
							),
						},
						{
							formItemProps: { name: 'disabled' },
							render: <BoolSelect placeholder="是否禁用" />,
						},
						{
							formItemProps: { name: 'createdAt' },
							render: (
								<TimeSelect placeholder={['创建时间-开始', '创建时间-结束']} />
							),
							colPorps: { multiple: 2 },
						},
						{
							formItemProps: { name: 'updatedAt' },
							render: (
								<TimeSelect placeholder={['更新时间-开始', '更新时间-结束']} />
							),
							colPorps: { multiple: 2 },
						},
					]}
					btnData={[
						{
							title: '搜索',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<SearchOutlined />}
									onClick={submit}
									loading={tableProps.loading}
								/>
							),
						},
						{
							title: '重置',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<ReloadOutlined />}
									onClick={reset}
								/>
							),
						},
						{
							title: '新增',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<PlusOutlined />}
									onClick={() => handle('add')}
								/>
							),
						},
					]}
				/>
			}
			count={tableSelectKey.length}
			actionsRight={
				<Btns
					data={[
						{
							title: '批量绑定用户',
							btn: (
								<Button
									type='primary'
									icon={icon('TeamOutlined')}
									onClick={() => handle('batchBindUser')}
									disabled={!tableSelectKey?.length}
								/>
							),
						},
						{
							title: '批量绑定权限',
							btn: (
								<Button
									type='primary'
									icon={icon('SecurityScanOutlined')}
									onClick={() => handle('batchBindPermissions')}
									disabled={!tableSelectKey?.length}
								/>
							),
						},
					]}
				/>
			}
		>
			<Table
				rowKey={(v) => v._id}
				columns={getColumns()}
				{...tableProps}
				{...tableConfig({ tableProps })}
				rowSelection={{
					type: 'checkbox',
					onChange: tableSelectChange,
					selectedRowKeys: tableSelectKey,
					checkStrictly: false,
				}}
			/>

			<Curd
				ref={childRef}
				open={open}
				setOpen={setOpen}
				row={row}
				type={type}
				title={_typeOptions[type] ? _typeOptions[type] : `${typeOptions?.[type] || ''}角色`}
				width={type.includes('batch') ? 1000 : 600}
				formConfig={formConfig}
				submit={curdSubmit}
				loading={loading}
				beforeChildren={
					type.includes('batch') ? (
						<>
							<Alert
								message="批量操作无法回显或删除已绑定数据, 如需查看或删除已绑定数据请单条数据处理"
								type="info"
								showIcon
								style={{ marginBottom: 10 }}
							/>
							<Table
								rowKey={(v) => v._id}
								columns={getColumns(type)}
								pagination={false}
								dataSource={bindRoleTableData}
								scroll={{ y: 450 }}
								style={{ marginBottom: 20 }}
								loading={loading}
							/>
						</>
					) : null
				}
			/>
		</ListPage>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: false,
}
