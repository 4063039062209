import { memo, useState, useRef, useMemo } from 'react'
import api from '@/api'
import { Button, Form, Input, Table, Image, Typography, Space, message, Flex, Modal, Dropdown } from 'antd'
import ListPage from '@/components/listPage'
import Search from '@/components/search'
import TimeSelect from '@/components/timeSelect'
import Copy from '@/components/copy'
import Curd from '@/components/curd'
import ProblemExplanation from '@/components/problemExplanation'
import { useAntdTable } from 'ahooks'
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons'
import { tableConfig, arrDayToArrTimestamp, commonColumnsIndex, commonColumnsCreatedAndUpdated, typeOptions, icon } from '@/tools'

const { Link } = Typography

const Page = memo(() => {
	const childRef = useRef(null)
	const [form] = Form.useForm()
	const [tableSelectData, setTableSelectData] = useState([]) // 所选表格数据
	const [tableSelectKey, setTableSelectKey] = useState([]) // 所选表格key

	const [open, setOpen] = useState(false) // curd 窗口展示状态
	const [row, setRow] = useState({}) // 表格当前所选行数据
	const [type, setType] = useState('') // curd 窗口类型
	const [loading, setLoading] = useState(false) // 加载状态


	// 列表数据
	const getTableData = ({ current, pageSize }, formData) => {
		const {
			title,
			description,
			keywords,
			rating,
			subject,
			author,
			generator,
			copyright,
			viewport,
			robots,
			url,
			icon,
			createdBy,
			updatedBy,
			createdById,
			updatedById,
			createdAt,
			updatedAt,
		} = formData

		const filter = {
			title,
			description,
			keywords,
			rating,
			subject,
			author,
			generator,
			copyright,
			viewport,
			robots,
			url,
			icon,
			createdBy,
			updatedBy,
			createdById,
			updatedById,
			createdAt: arrDayToArrTimestamp(createdAt),
			updatedAt: arrDayToArrTimestamp(updatedAt),
		}

		const req = {
			filter,
			page: current,
			limit: pageSize,
		}

		return api.cardList(req).then((res) => {
			res.list = res?.data?.rows
			res.total = res?.data?.count
			return res
		})
	}
	const { tableProps, search: { submit, reset } } = useAntdTable(getTableData, { defaultPageSize: 100, form })

	// 表格列
	const getColumns = () => {
		const data = [
			commonColumnsIndex,
			{
				width: 260,
				title: '_id',
				dataIndex: '_id',
				render: (v) => <Copy>{v}</Copy>,
			},
			{
				width: 200,
				title: '标题',
				dataIndex: 'title',
				render: (v, row) => v ? 
					<Link target="_blank" href={row.url}>{v}</Link> :
					<Link target="_blank" href={row.url}><ProblemExplanation text={row.url} icon={'SendOutlined'} /></Link>
			},
			{ width: 90, title: '图标', dataIndex: 'icon', render: v => <Image width={60} src={v} /> },
			{ width: 90, title: '苹果图标', dataIndex: 'appleTouchIcon', render: v => <Image width={60} src={v} /> },
			{ width: 90, title: '快捷图标', dataIndex: 'shortcutIcon', render: v => <Image width={60} src={v} /> },
			{ width: 300, title: '描述', dataIndex: 'description' },
			{ width: 300, title: '关键词', dataIndex: 'keywords' },
			{ width: 100, title: '适用年龄', dataIndex: 'rating' },
			{ width: 80, title: '主题', dataIndex: 'subject' },
			{ width: 80, title: '作者', dataIndex: 'author' },
			{ width: 120, title: '网页生成工具', dataIndex: 'generator' },
			{ width: 100, title: '版权', dataIndex: 'copyright' },
			{ width: 200, title: '视口', dataIndex: 'viewport' },
			{ width: 150, title: '爬虫约束', dataIndex: 'robots' },
			{ width: 200, title: '连接', dataIndex: 'url' },
			{ width: 200, title: '备用地址', dataIndex: 'alternate' },
			...commonColumnsCreatedAndUpdated, // 创建人 修改人 创建时间 修改时间
			{
				width: 188,
				title: '操作',
				fixed: 'right',
				render: (v, row) => (
					<Space>
						<Button size="small" onClick={() => handle('get', row)}>查看</Button>
						<Button size="small" onClick={() => handle('set', row)}>修改</Button>
						<Button size="small" onClick={() => handle('del', row)} danger>删除</Button>

						<Dropdown menu={{ items: [
							{ key: '1', label: <span onClick={() => reGet(row, 'http')}>http获取</span> },
							{ key: '2', label: <span onClick={() => reGet(row, 'devTools')}>devTools获取</span> },
						] }} placement="bottomRight">
							<Button size="small">重新获取{icon('DownOutlined')}</Button>
						</Dropdown>
					</Space>
				),
			},
		]
		return data
	}

	// 打开curd操作抽屉
	const handle = async (type, row = {}) => {
		const newRow = { ...row }
		setRow(newRow)
		setType(type)
		setOpen(true)
	}
	
	//重新获取
	const reGet = (row, type) => {
		const { url, _id } = row

		Modal.confirm({
			title: '提示',
			icon: icon('ExclamationCircleFilled'),
			content: <>
				<p>确认通过{type}途径重新获取该网站信息?</p>
				<p>获取失败不会覆盖原数据</p>
				<p>
					获取失败时最大耗时
					<span style={{ color: 'red' }}>{type === 'http' ? 7 : 17}</span>
					秒左右
				</p>
			</>,
			onOk: async function () {
				try {
					await api.cardReGet({ data: { url, _id, type } })
					message.success('操作成功')
				} catch (error) {
					console.log(error)
				}
			},
		})
	}

	// curd操作
	const curdSubmit = async (formData, row, type) => {
		formData = { ...formData }
	
		const init = () => {
			setLoading(false) // 取消加载状态
			setOpen(false) // 关闭窗口
			submit() // 刷新外层列表
			message.success('操作成功')
		}
	
		try {
			setLoading(true)
	
			switch (type) {
			case 'add':
				// await api.cardAdd({ data: formData })
				init()
				break

			case 'del':
				await api.cardDel({ _id: row._id })
				init()
				break

			case 'set':
				console.log('set submit', formData)
				await api.cardSet({ ...formData, _id: row._id })
				init()
				break

			case 'get':
				setLoading(false)
				setOpen(false) // 关闭窗口
				break

			default:
				break
			}
		} catch (error) {
			setLoading(false)
			console.log('curd报错: ', error)
		}
	}

	// 获取curd form配置
	const formConfig = useMemo(() => {
		const data = [
			{
				formItemProps: {
					label: '标题',
					name: 'title',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: 'url',
					name: 'url',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					label: '图标',
					name: 'icon',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					label: '描述',
					name: 'description',
				},
				render: <Input.TextArea allowClear rows={6} />,
			},
			{
				formItemProps: {
					label: '关键词',
					name: 'keywords',
				},
				render: <Input.TextArea allowClear rows={6} />,
			},
			{
				formItemProps: {
					label: '发布器地址',
					name: '_publisher',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					label: '备用url',
					// name: '_backupUrlList',
				},
				render: (
					<Form.List name="_backupUrlList">
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, ...restField }) => (
									<Flex key={key} justify='space-between'>
										<Form.Item {...restField} name={[name, 'backupUrl']}>
											<Input style={{ width: 420 }} />
										</Form.Item>
										<Button type='text' onClick={() => remove(name)} block icon={icon('MinusCircleOutlined')} />
									</Flex>
								))}
								<Form.Item>
									<Button type="dashed" onClick={() => add()} block icon={icon('PlusOutlined')} />
								</Form.Item>
							</>
						)}
					</Form.List>
				),
			},


		]
		return data
	}, [type, row])

	// 表格选择
	const tableSelectChange = (_selectedRowKeys, _selectedRows) => {
		setTableSelectData(_selectedRows)
		setTableSelectKey(_selectedRowKeys)
	}

	const copyText = `
		// 获取当前网页的 title
		console.log("Title: " + document.title);

		// 获取当前网页的 icon 连接
		var linkIcon = document.querySelector('link[rel="icon"]');
		if (!linkIcon) {
			linkIcon = document.querySelector('link[rel="shortcut icon"]');
		}
		if (!linkIcon) {
			linkIcon = document.querySelector('link[rel="appleTouchIcon"]');
		}

		if (linkIcon) {
			var iconUrl = linkIcon.getAttribute("href");
			if (iconUrl.startsWith("/") || iconUrl.startsWith("//")) {
				iconUrl = window.location.origin + iconUrl;
			}
			console.log("Icon: " + iconUrl);
		}

		// 获取当前网页的 description
		var metaDescription = document.querySelector('meta[name="description"]');
		if (metaDescription) {
			console.log("Description: " + metaDescription.getAttribute("content"));
		}

		// 获取当前网页的 keywords
		var metaKeywords = document.querySelector('meta[name="keywords"]');
		if (metaKeywords) {
			console.log("Keywords: " + metaKeywords.getAttribute("content"));
		}
	`

	return (
		<ListPage
			search={
				<Search
					form={form}
					submit={submit}
					formData={[
						{
							formItemProps: { name: 'title' },
							render: <Input placeholder="标题" allowClear />,
						},
						{
							formItemProps: { name: 'description' },
							render: <Input placeholder="描述" allowClear />,
						},
						{
							formItemProps: { name: 'keywords' },
							render: <Input placeholder="关键词" allowClear />,
						},
						{
							formItemProps: { name: 'rating' },
							render: <Input placeholder="适用年龄" allowClear />,
						},
						{
							formItemProps: { name: 'subject' },
							render: <Input placeholder="主题" allowClear />,
						},
						{
							formItemProps: { name: 'author' },
							render: <Input placeholder="作者" allowClear />,
						},
						{
							formItemProps: { name: 'generator' },
							render: <Input placeholder="网页生成工具" allowClear />,
						},
						{
							formItemProps: { name: 'copyright' },
							render: <Input placeholder="版权" allowClear />,
						},
						{
							formItemProps: { name: 'viewport' },
							render: <Input placeholder="视口" allowClear />,
						},
						{
							formItemProps: { name: 'robots' },
							render: <Input placeholder="爬虫约束" allowClear />,
						},
						{
							formItemProps: { name: 'url' },
							render: <Input placeholder="连接" allowClear />,
						},

						{
							formItemProps: { name: 'createdBy' },
							render: <Input placeholder="创建人" allowClear />,
						},
						{
							formItemProps: { name: 'updatedBy' },
							render: <Input placeholder="修改人" allowClear />,
						},
						{
							formItemProps: { name: 'createdById' },
							render: <Input placeholder="创建人Id" allowClear />,
						},
						{
							formItemProps: { name: 'updatedById' },
							render: <Input placeholder="修改人Id" allowClear />,
						},

						{
							formItemProps: { name: 'createdAt' },
							render: <TimeSelect placeholder={['创建时间-开始', '创建时间-结束']} />,
							colPorps: { multiple: 2 },
						},
						{
							formItemProps: { name: 'updatedAt' },
							render: <TimeSelect placeholder={['更新时间-开始', '更新时间-结束']} />,
							colPorps: { multiple: 2 },
						},
					]}
					btnData={[
						{
							title: '搜索',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<SearchOutlined />}
									onClick={submit}
									loading={tableProps.loading}
								/>
							),
						},
						{
							title: '重置',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<ReloadOutlined />}
									onClick={reset}
								/>
							),
						},
					]}
				/>
			}
			count={tableSelectKey.length}
		>
			<Table
				rowKey={(v) => v._id}
				columns={getColumns()}
				{...tableProps}
				{...tableConfig({ tableProps })}
				rowSelection={{
					type: 'checkbox',
					onChange: tableSelectChange,
					selectedRowKeys: tableSelectKey,
					checkStrictly: false,
				}}
			/>

			<Curd
				ref={childRef}
				open={open}
				setOpen={setOpen}
				row={row}
				type={type}
				title={typeOptions?.[type] + '卡片'}
				width={600}
				formProps={{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }}
				formConfig={formConfig}
				submit={curdSubmit}
				loading={loading}
				afterChildren={
					type === 'set' ?
						(
							<Flex justify='end'>
								<Typography.Paragraph copyable={{ text: copyText }}>
									<Space size={5}>
										点此复制代码
										<ProblemExplanation text='将复制的代码 粘贴到控制台中 按Enter 可快速获取对应网站的 标题 / 图标 / 描述 / 关键词' />
									</Space>
								</Typography.Paragraph>
							</Flex>
						) :
						null
				}
			/>
		</ListPage>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: false,
}
