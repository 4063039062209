import { memo, useState, useMemo } from 'react'
import api from '@/api'
import { Button, Form, Input, Table, Space, InputNumber, Cascader, message, Select, Tag } from 'antd'
import ListPage from '@/components/listPage'
import Search from '@/components/search'
import TimeSelect from '@/components/timeSelect'
import IconSelect from '@/components/iconSelect'
import BoolSelect from '@/components/boolSelect'
import Copy from '@/components/copy'
import Curd from '@/components/curd'
import ProblemExplanation from '@/components/problemExplanation'
import { useAntdTable, useRequest } from 'ahooks'
import { SearchOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons'
import { dateToStr, icon, showLink, tableConfig, arrDayToArrTimestamp, typeOptions, generateMenuTree, filterConfig, showBool, commonColumnsIndex, commonColumnsCreatedAndUpdated } from '@/tools'
import { routes as _routes } from '@/router/components/router'

const Page = memo(() => {
	const [form] = Form.useForm()
	const [open, setOpen] = useState(false) // curd 窗口展示状态
	const [row, setRow] = useState({}) // 表格当前所选行数据
	const [type, setType] = useState('') // curd 窗口类型
	const [loading, setLoading] = useState(false) // 加载状态
	const whitelistText = '白名单内的菜单会对游客开放 无需登录&权限也能访问'
	const { data: menuAll, run } = useRequest(() => api.menuAll({}).then((res) => res?.data?.rows || [])) // 全部菜单数据
	const routes = useMemo(() => { // 页面(路由)
		if (_routes?.length) {
			return _routes.map((v) => ({ label: v.path, value: v.path }))
		} else {
			return []
		}
	}, [_routes])

	// 列表数据
	const getTableData = ({ current, pageSize }, formData) => {
		const { icon, key, label, isDel, desc, whitelist, disabled, createdAt, updatedAt } = formData

		const filter = {
			icon,
			key,
			label,
			isDel,
			desc,
			whitelist,
			disabled,
			createdAt: arrDayToArrTimestamp(createdAt),
			updatedAt: arrDayToArrTimestamp(updatedAt),
		}

		const req = {
			filter,
			page: current,
			limit: pageSize,
		}

		return api.menuList(req).then((res) => {
			res.list = generateMenuTree(
				res?.data?.rows?.map((v) => ({ ...v, value: v._id })) || [],
			) // rootNode
			res.total = res?.data?.count
			return res
		})
	}
	const {
		tableProps,
		search: { submit, reset },
	} = useAntdTable(getTableData, { defaultPageSize: 100, form })

	// 表格列
	const getColumns = () => {
		const data = [
			commonColumnsIndex,
			{
				width: 260,
				title: '_id',
				dataIndex: '_id',
				render: (v) => <Copy>{v}</Copy>,
			},
			{
				width: 100,
				title: '菜单icon',
				dataIndex: 'icon',
				render: (v) => icon(v),
			},
			{ width: 200, title: '菜单名称', dataIndex: 'label' },
			{
				width: 350,
				title: '菜单路由',
				dataIndex: 'key',
				render: (v) => showLink(v),
			},
			{ width: 100, title: '菜单排序', dataIndex: 'sort' },
			{
				width: 100,
				title: (
					<Space size={5}>
						白名单
						<ProblemExplanation text={whitelistText} />
					</Space>
				),
				dataIndex: 'whitelist',
				render: v => showBool(v)
			},
			{
				width: 100,
				title: '是否禁用',
				dataIndex: 'disabled',
				render: (v) => <Tag>{v ? '是' : '否'}</Tag>,
			},
			{ width: 200, title: '菜单描述', dataIndex: 'desc' },
			...commonColumnsCreatedAndUpdated,
			{
				width: 188,
				title: '操作',
				fixed: 'right',
				render: (v, row) => (
					<Space>
						<Button size="small" onClick={() => handle('get', row)}>
              查看
						</Button>
						<Button size="small" onClick={() => handle('set', row)}>
              修改
						</Button>
						<Button size="small" onClick={() => handle('del', row)} danger>
              删除
						</Button>
					</Space>
				),
			},
		]
		return data
	}

	// 打开curd操作抽屉
	const handle = (type, row = {}) => {
		setRow({
			...row,
			parentId: row?.parentId?.length ? [row?.parentId] : row?.parentId,
		})
		setType(type)
		setOpen(true)
	}

	// curd操作
	const curdSubmit = async (formData, row, type) => {
		formData = { ...formData }

		if (formData?.parentId?.length) {
			formData.parentId = formData.parentId[0]
		} else {
			formData.parentId = null
		}

		if (Array.isArray(formData.key) && formData.key.length) {
			formData.key = formData.key[0]
		}

		const init = () => {
			setLoading(false) // 取消加载状态
			setOpen(false) // 关闭窗口
			submit() // 刷新外层列表
			run() // 刷新菜单选项
			window?.__app__?.runRefreshMenu() // 刷新全局的菜单渲染
			message.success('操作成功')
		}

		try {
			setLoading(true)

			switch (type) {
			case 'add':
				await api.menuAdd({ data: formData })
				init()
				break

			case 'del':
				await api.menuDel({ _id: row._id })
				init()
				break

			case 'set':
				await api.menuSet({ ...formData, _id: row._id })
				init()
				break

			case 'get':
				setLoading(false)
				setOpen(false) // 关闭窗口
				break

			default:
				break
			}
		} catch (error) {
			setLoading(false)
			console.log('curd报错: ', error)
		}
	}

	const searchFormData = () => {
		return [
			{
				formItemProps: { name: 'label' },
				render: <Input placeholder="菜单名称" allowClear />,
			},
			{
				formItemProps: { name: 'key' },
				render: <Input placeholder="菜单路由" allowClear />,
			},
			{
				formItemProps: { name: 'icon' },
				render: <IconSelect placeholder="菜单icon" />,
			},
			{
				formItemProps: { name: 'whitelist' },
				render: <BoolSelect placeholder="白名单" />,
			},
			{
				formItemProps: { name: 'disabled' },
				render: <BoolSelect placeholder="是否禁用" />,
			},
			{
				formItemProps: { name: 'desc' },
				render: <Input placeholder="菜单描述" allowClear />,
			},
			// <ProblemExplanation text={whitelistText} />
			// dataIndex: 'whitelist' 白名单
			// { formItemProps: { name: 'isDel' }, render: <BoolSelect placeholder='是否删除' /> },
			{
				formItemProps: { name: 'createdAt' },
				render: (
					<TimeSelect placeholder={['创建时间-开始', '创建时间-结束']} />
				),
				colPorps: { multiple: 2 },
			},
			{
				formItemProps: { name: 'updatedAt' },
				render: (
					<TimeSelect placeholder={['更新时间-开始', '更新时间-结束']} />
				),
				colPorps: { multiple: 2 },
			},
		]
	}

	const searchBtnData = () => {
		return [
			{
				title: '搜索',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={<SearchOutlined />}
						onClick={submit}
						loading={tableProps.loading}
					/>
				),
			},
			{
				title: '重置',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={<ReloadOutlined />}
						onClick={reset}
					/>
				),
			},
			{
				title: '新增',
				isNeedFormItem: false,
				btn: (
					<Button
						type="primary"
						icon={<PlusOutlined />}
						onClick={() => handle('add')}
					/>
				),
			},
		]
	}

	const curdFormConfig =() => {
		return [
			{
				formItemProps: {
					rules: [{ required: true }, { min: 2 }, { max: 6 }],
					label: '菜单名称',
					name: 'label',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [
						() => ({ // { getFieldValue }
							validator(_, value) {
								if (value?.length > 1)
									return Promise.reject(new Error('只能选择一个节点'))
								return Promise.resolve()
							},
						}),
					],
					label: '父级菜单',
					name: 'parentId',
				},
				render: (
					<Cascader
						allowClear
						options={menuAll}
						expandTrigger="hover"
						multiple
						{...filterConfig}
					/>
				),
			},
			{
				formItemProps: {
					rules: [
						({ getFieldValue }) => ({
							validator(_, value) {
								if (Array.isArray(value) && value?.length > 1)
									return Promise.reject(new Error('只能选择一个菜单路由'))
								return Promise.resolve()
							},
						}),
					],
					label: '菜单路由',
					name: 'key',
				},
				render: <Select
					mode="tags"
					style={{ width: '100%' }}
					allowClear
					options={routes}
				/>,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '菜单icon',
					name: 'icon',
				},
				render: <IconSelect />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '菜单排序',
					name: 'sort',
				},
				render: (
					<InputNumber min={1} max={1000} style={{ width: '100%' }} />
				),
			},
			{
				formItemProps: {
					initialValue: false,
					rules: [{ required: true }],
					label: (
						<Space size={5}>
							白名单
							<ProblemExplanation text={whitelistText} />
						</Space>
					),
					name: 'whitelist',
				},
				render: (
					<BoolSelect placeholder="白名单" />
				),
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '是否禁用',
					name: 'disabled',
					initialValue: false,
				},
				render: <BoolSelect />,
			},
			{
				formItemProps: { label: '菜单描述', name: 'desc' },
				render: (
					<Input.TextArea allowClear rows={4} showCount maxLength={128} />
				),
			},
			// { formItemProps: { rules: [{ required: true }], label: '是否删除', name: 'isDel' }, render: <BoolSelect placeholder='是否删除' /> },
			// { formItemProps: { rules: [{ required: true }], label: '创建时间', name: 'createdAt' }, render: <TimeSelect placeholder={['创建时间-开始', '创建时间-结束']}/> },
			// { formItemProps: { rules: [{ required: true }], label: '更新时间', name: 'updatedAt' }, render: <TimeSelect placeholder={['更新时间-开始', '更新时间-结束']}/> },
		]
	}

	return (
		<ListPage
			search={
				<Search
					form={form}
					submit={submit}
					formData={searchFormData()}
					btnData={searchBtnData()}
				/>
			}
		>
			<Table
				rowKey={(v) => v._id}
				columns={getColumns()}
				{...tableProps}
				{...tableConfig({ tableProps })}
			/>

			<Curd
				open={open}
				setOpen={setOpen}
				row={row}
				type={type}
				title={`${typeOptions?.[type] || ''}菜单`}
				formConfig={curdFormConfig()}
				submit={curdSubmit}
				loading={loading}
			/>
		</ListPage>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: false,
}
