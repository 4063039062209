import { memo, useRef } from 'react'
import { Form, Input, message, DatePicker } from 'antd'
import ShowData from '../showData'
import { dateToStr, dayToTimestamp } from '@/tools'
import api from '@/api'
import { useRecoilState } from 'recoil'
import { userInfo as _userInfo, user, imageCaptchaId as _imageCaptchaId } from '@/state'
import DicSelect from '@/components/dicSelect'
import dayjs from 'dayjs'
import ImageCaptcha from '@/components/verificationCode/imageCaptcha'
import PicDisplayEdit from './picDisplayEdit'


// 用户信息
const GenderShow = memo((props) => { // 性别展示
	const { data } = props

	return <>
		{
			data === '1' ? '男' :
				data === '2' ? '女' :
					data
		}
	</>
})

const UserInfo = memo(() => {
	const childRef = useRef(null)
	const [form] = Form.useForm()
	const [, setUserInfo] = useRecoilState(_userInfo) // 用户信息
	const [userInfo] = useRecoilState(user) // 用户信息
	const [imageCaptchaId] = useRecoilState(_imageCaptchaId) // 图片验证码Id

	const data = [
		{
			label: <PicDisplayEdit />,
			// right: '',
		},
		{
			label: '用户名',
			value: userInfo.name,
			formConfig: {
				render: <Input allowClear />,
				formItemProps: {
					name: 'name',
					rules: [{ required: true, message: '请输入' }]
				} 
			}
		},
		{
			label: '密码',
			value: '••••••••',
			modalConfig: {
				formConfig: [
					{
						formItemProps: {
							rules: [{ required: true, message: '请输入' }],
							name: 'password',
							label: '密码'
						},
						render: <Input.Password allowClear />,
					},
					{
						formItemProps: {
							rules: [{ required: true, message: '请输入' }],
							name: 'newPassword',
							label: '新密码'
						},
						render: <Input.Password allowClear />,
					},
					{
						formItemProps: {
							rules: [{ required: true, message: '请输入' }],
							name: 'code',
							label: '验证码'
						},
						render: (
							<ImageCaptcha
								placeholder='图形验证码'
								ref={childRef}
							/>
						),
					},
				]
			}
		},
		{
			label: '性别',
			value: <GenderShow data={userInfo.gender} />,
			formConfig: {
				render: <DicSelect dicKey={'gender'} />,
				formItemProps: { name: 'gender' }
			}
		},
		{
			label: '生日',
			value: dateToStr(userInfo.birthday, 'YYYY-MM-DD'),
			formConfig: {
				render: <DatePicker allowClear style={{ width: '100%' }} placeholder='' />,
				formItemProps: { name: 'birthday' }
			}
		},
		{
			label: '职业',
			value: '',
			formConfig: {
				formItemProps: { name: 'profession' }
			},
			right: '',
		},
	]

	// 单条数据修改
	const attributeSubmit = async (formData, item) => {
		const req = {}
		req[item.formConfig.formItemProps.name] = formData[item.formConfig.formItemProps.name]

		if (req.birthday) {
			req.birthday = dayToTimestamp(req.birthday)
		}

		const res = await api.userSave(req)
		message.success('操作成功')
		setUserInfo(res.data)
	}

	// 模态窗数据修改
	const modalSubmit = async (formData, modalFormItemData) => {
		try {
			const fields = modalFormItemData.map(v => v.formItemProps.name)

			let res = null
			// 修改密码
			if (fields.includes('password')) {
				res = await api.userSetPassword({ ...formData, imageCaptchaId })
			}

			setUserInfo(res.data)
			return true
		} catch (error) {
			childRef?.current?.run?.() // 重新获取验证码
			console.log(error)
			return false
		}
	}

	return (
		<Form
			layout='vertical'
			wrapperCol={{ span: 24 }}
			form={form}
			initialValues={{ ...userInfo, birthday: dayjs(userInfo.birthday) }}
		>
			<ShowData
				data={data}
				form={form}
				attributeSubmit={attributeSubmit}
				modalSubmit={modalSubmit}
			/>
		</Form>
	)
})

export default UserInfo