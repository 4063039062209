import { Avatar, Dropdown } from 'antd'
import { memo, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userInfo as _userInfo, classAll as _classAll, currentSelectClass as _currentSelectClass, topMenuSelectedData as _topMenuSelectedData, } from '@/state'
import api, { imgUrl } from '@/api'
import { PName } from '@/tools'





// 已登录
const HasLogin = memo(() => {
	const navigate = useNavigate()
	const [userInfo, setUserInfo] = useRecoilState(_userInfo) // 用户信息
	const [, setClassAll] = useRecoilState(_classAll) // 是否展示list
	const [, setCurrentSelectClass] = useRecoilState(_currentSelectClass) // 已选分类
	const [topMenuSelectedData, setTopMenuSelectedData] = useRecoilState(_topMenuSelectedData) // 顶栏选择数据
	const picSrc = useMemo(() => userInfo?.userInfo?.pic ? `${imgUrl}/${userInfo?.userInfo?.pic}` : undefined, [userInfo]) // 用户头像

	// 已登录用户 下拉选 内容
	const userDropdownOptions = [
		{ key: 'userCenter', label: <span onClick={() => operate('userCenter')}>用户中心</span> },
		{ key: 'signOut', label: <span onClick={() => operate('signOut')}>退出登录</span> },
	]

	// 跳转用户中心
	const toUserCenter = () => {
		navigate('/userCenter')
		const title = '用户中心'
		document.title = title
		setTopMenuSelectedData({ ...topMenuSelectedData, label: title }) // 解决跳转用户中心页面 后再刷新页面时 浏览器标签title会变成上一个title的问题
	}

	// 用户相关操作
	const operate = async (type) => {
		switch (type) {
		case 'userCenter': // 用户中心
			toUserCenter()
			break

		case 'signOut': // 退出登录
			{
				if (userInfo.userInfo._id) {
					try {
						await api.userSignOut({ _id: userInfo.userInfo._id })
					} catch (error) {
						console.log(error)
					}
				}
				setUserInfo(null) // 释放用户数据
				setClassAll(v => [v[0]]) // 释放分类数据
				setCurrentSelectClass('1') // 释放已选分类

				// 释放卡片数据
				// 释放卡片分页数据

				navigate('/')

				setTimeout(() => {
					window?.__app__?.runRefreshMenu() // 刷新全局的菜单渲染
				}, 100)
			}
			break

		default:
			break
		}
	}

	useEffect(() => {
		if (typeof operate === 'function') {
			window.__app__['userOperate'] = operate
		}
	}, [operate])

	// 点击头像
	const clickAvatar = () => {
		console.log('userInfo', userInfo)
		toUserCenter()
	}

	return (
	// 已登录
		<Dropdown menu={{ items: userDropdownOptions }} placement='bottom' arrow>
			<Avatar
				style={{ backgroundColor: '#bfbfbf', verticalAlign: 'middle', cursor: 'pointer' }}
				size='large'
				gap={3}
				onClick={clickAvatar}
				src={picSrc}
			>
				{userInfo?.userInfo?.name}
			</Avatar>
		</Dropdown>
	)
})

export default HasLogin
