import { memo, useState, useMemo, useRef } from 'react'
import api from '@/api'
import { Button, Form, Input, Table, Space,  message, InputNumber } from 'antd'
import ListPage from '@/components/listPage'
import Search from '@/components/search'
import TimeSelect from '@/components/timeSelect'
import Curd from '@/components/curd'
import Copy from '@/components/copy'
import { useAntdTable } from 'ahooks'
import { SearchOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons'
import { tableConfig, arrDayToArrTimestamp, typeOptions, commonColumnsIndex, commonColumnsCreatedAndUpdated } from '@/tools'

const Page = memo(() => {
	const childRef = useRef(null)
	const [form] = Form.useForm()
	const [open, setOpen] = useState(false) // curd 窗口展示状态
	const [row, setRow] = useState({}) // 表格当前所选行数据
	const [type, setType] = useState('') // curd 窗口类型
	const [loading, setLoading] = useState(false) // 加载状态
	const [, setTableSelectData] = useState([]) // 所选表格数据
	const [tableSelectKey, setTableSelectKey] = useState([]) // 所选表格key


	// 列表数据
	const getTableData = ({ current, pageSize }, formData) => {
		const { value, label, queryStr, createdAt, updatedAt } = formData

		const filter = {
			value,
			label,
			queryStr,
			createdAt: arrDayToArrTimestamp(createdAt),
			updatedAt: arrDayToArrTimestamp(updatedAt),
		}

		const req = {
			filter,
			page: current,
			limit: pageSize,
		}

		return api.searchTypeList(req).then((res) => {
			res.list = res?.data?.rows
			res.total = res?.data?.count
			return res
		})
	}
	const { tableProps, search: { submit, reset } } = useAntdTable(getTableData, { defaultPageSize: 100, form })

	// 表格列
	const getColumns = () => {
		const data = [
			commonColumnsIndex,
			{
				width: 260,
				title: '_id',
				dataIndex: '_id',
				render: (v) => <Copy>{v}</Copy>,
			},
			{ width: 100, title: '搜索类型', dataIndex: 'label' },
			{ width: 100, title: '取值', dataIndex: 'value' },
			{ width: 400, title: '查询字符串', dataIndex: 'queryStr' },
			...commonColumnsCreatedAndUpdated, // 创建人 修改人 创建时间 修改时间
			{
				width: 188,
				title: '操作',
				fixed: 'right',
				render: (v, row) => (
					<Space>
						<Button size="small" onClick={() => handle('get', row)}>查看</Button>
						<Button size="small" onClick={() => handle('set', row)}>修改</Button>
						<Button size="small" onClick={() => handle('del', row)} danger>删除</Button>
					</Space>
				),
			},
		]

		return data
	}

	// 打开curd操作抽屉
	const handle = async (type, row = {}) => {
		const newRow = { ...row }
		setRow(newRow)
		setType(type)
		setOpen(true)

		// 新增时 value 自动填入最大值自增
		if (type === 'add') {
			// 查询 value 最大的一条数据
			const res = await api.searchTypeList({ page: 1, limit: 1, sort: { value: -1 } })

			// max + 1 回显到 value input 中
			const max = res?.data?.rows?.[0]?.value || 0
			const currentValue = max + 1
			childRef.current.form.setFieldsValue({ value: currentValue })
		}
	}

	// curd操作
	const curdSubmit = async (formData, row, type) => {
		formData = { ...formData }

		const init = () => {
			setLoading(false) // 取消加载状态
			setOpen(false) // 关闭窗口
			submit() // 刷新外层列表
			message.success('操作成功')
		}

		try {
			setLoading(true)

			switch (type) {
			case 'add':
				await api.searchTypeAdd({ data: formData })
				init()
				break

			case 'del':
				await api.searchTypeDel({ _id: row._id })
				init()
				break

			case 'set':
				await api.searchTypeSet({ ...formData, _id: row._id })
				init()
				break

			case 'get':
				setLoading(false)
				setOpen(false) // 关闭窗口
				break

			default:
				break
			}
		} catch (error) {
			setLoading(false)
			console.log('curd报错: ', error)
		}
	}

	// 表格选择
	const tableSelectChange = (_selectedRowKeys, _selectedRows) => {
		setTableSelectData(_selectedRows)
		setTableSelectKey(_selectedRowKeys)
	}

	// 获取curd form配置
	const formConfig = useMemo(() => {
		const data = [
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '搜索类型',
					name: 'label',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					rules: [{ required: true }],
					label: '取值',
					name: 'value',
				},
				render: <InputNumber min={1} style={{ width: '100%' }} />,
			},
			{
				formItemProps: {
					label: '查询字符串',
					name: 'queryStr',
				},
				render: <Input allowClear />,
			},
		]

		return data
	}, [type, row])


	return (
		<ListPage
			search={
				<Search
					form={form}
					submit={submit}
					formData={[
						{
							formItemProps: { name: 'label' },
							render: <Input placeholder="搜索类型" allowClear />,
						},
						{
							formItemProps: { name: 'queryStr' },
							render: <Input placeholder="查询字符串" allowClear />,
						},
						{
							formItemProps: { name: 'createdAt' },
							render: <TimeSelect placeholder={['创建时间-开始', '创建时间-结束']} />,
							colPorps: { multiple: 2 },
						},
						{
							formItemProps: { name: 'updatedAt' },
							render: <TimeSelect placeholder={['更新时间-开始', '更新时间-结束']} />,
							colPorps: { multiple: 2 },
						},
					]}
					btnData={[
						{
							title: '搜索',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<SearchOutlined />}
									onClick={submit}
									loading={tableProps.loading}
								/>
							),
						},
						{
							title: '重置',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<ReloadOutlined />}
									onClick={reset}
								/>
							),
						},
						{
							title: '新增',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<PlusOutlined />}
									onClick={() => handle('add')}
								/>
							),
						},
					]}
				/>
			}
			count={tableSelectKey.length}
		>
			<Table
				rowKey={(v) => v._id}
				columns={getColumns()}
				{...tableProps}
				{...tableConfig({ tableProps })}
				rowSelection={{
					type: 'checkbox',
					onChange: tableSelectChange,
					selectedRowKeys: tableSelectKey,
					checkStrictly: false,
				}}
			/>

			<Curd
				ref={childRef}
				open={open}
				setOpen={setOpen}
				row={row}
				type={type}
				title={`${typeOptions?.[type] || ''}搜索类型`}
				width={800}
				formProps={{ labelCol: { span: 3 }, wrapperCol: { span: 21 } }}
				formConfig={formConfig}
				submit={curdSubmit}
				loading={loading}
			/>
		</ListPage>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: false,
}
