import { memo } from 'react'
import { useRecoilState } from 'recoil'
import { permissionsData as _permissionsData } from '@/state'

// 权限控制-按钮&模块 是否渲染
const PermissionsShow = memo((props) => {
	const { children, permissionsId = '' } = props
	const [permissionsData] = useRecoilState(_permissionsData) // 用户权限数据

	return (
		<>
			{
				permissionsData.includes(permissionsId) ? children : null
			}
		</>
	)
})

export default PermissionsShow