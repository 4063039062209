import { memo, useState, useRef, useMemo } from 'react'
import api from '@/api'
import { Button, Form, Input, Select, Table, Image, Typography, Space, InputNumber, Switch, message } from 'antd'
import ListPage from '@/components/listPage'
import Search from '@/components/search'
import TimeSelect from '@/components/timeSelect'
import Copy from '@/components/copy'
import Curd from '@/components/curd'
import ProblemExplanation from '@/components/problemExplanation'
import { useAntdTable, useRequest } from 'ahooks'
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons'
import { tableConfig, arrDayToArrTimestamp, commonColumnsIndex, commonColumnsCreatedAndUpdated, typeOptions } from '@/tools'

const { Link } = Typography

const Page = memo(() => {
	const childRef = useRef(null)
	const [form] = Form.useForm()
	const { data: userAll } = useRequest(() => api.userAll({}).then((res) => res?.data?.rows || [])) // 全部用户数据
	const [tableSelectData, setTableSelectData] = useState([]) // 所选表格数据
	const [tableSelectKey, setTableSelectKey] = useState([]) // 所选表格key
	const [open, setOpen] = useState(false) // curd 窗口展示状态
	const [row, setRow] = useState({}) // 表格当前所选行数据
	const [type, setType] = useState('') // curd 窗口类型
	const [loading, setLoading] = useState(false) // 加载状态
	const [classOptions, setClassOptions] = useState([]) // 所选用户的全部分类
	const [isShowLoading, setIsShowLoading] = useState(false)

	// 列表数据
	const getTableData = ({ current, pageSize }, formData) => {
		const {
			userId,
			classId,
			keyword,
			createdAt,
			updatedAt,
		} = formData

		if (!userId) {
			message.warning('用户是必选属性')
			return
		}

		const filter = {
			userId,
			classId: classId ? [classId] : undefined,
			keyword,
			createdAt: arrDayToArrTimestamp(createdAt),
			updatedAt: arrDayToArrTimestamp(updatedAt),
		}

		const req = {
			filter,
			page: current,
			limit: pageSize,
		}

		return api.userCardFindByUserId(req).then((res) => {
			res.list = res?.data?.rows
			res.total = res?.data?.count
			return res
		})
	}
	const { tableProps, search: { submit, reset } } = useAntdTable(getTableData, { defaultPageSize: 100, form, manual: true })

	// 切换展示状态
	const isShowChange = async (isShow, row) => {
		setIsShowLoading(true)

		try {
			await api.userCardSet({ ...row, isShow })
			await submit() // 刷新外层列表
		} catch (error) {
			console.log(error)
		}

		setIsShowLoading(false)
	}

	// 表格列
	const getColumns = () => {
		const data = [
			commonColumnsIndex,
			{
				width: 260,
				title: '_id',
				dataIndex: '_id',
				render: (v) => <Copy>{v}</Copy>,
			},
			{
				width: 200,
				title: '标题',
				dataIndex: 'title',
				render: (v, row) => row?.cardData?.title ? 
					<Link target="_blank" href={row?.cardData?.url}>{row?.customizeTitle || row?.cardData?.title}</Link> :
					<Link target="_blank" href={row?.cardData?.url}><ProblemExplanation text={row?.cardData?.url} icon={'SendOutlined'} /></Link>
			},
			{ width: 90, title: '图标', dataIndex: 'icon', render: (v, row) => <Image width={60} src={row?.cardData?.icon} /> },
			{ width: 90, title: '苹果图标', dataIndex: 'appleTouchIcon', render: (v, row) => <Image width={60} src={row?.cardData?.appleTouchIcon} /> },
			{ width: 90, title: '快捷图标', dataIndex: 'shortcutIcon', render: (v, row) => <Image width={60} src={row?.cardData?.shortcutIcon} /> },
			{ width: 300, title: '描述', dataIndex: 'description', render: (v, row) => row?.customizeDescription || row?.cardData?.description },
			{ width: 300, title: '关键词', dataIndex: 'keywords', render: (v, row) => row?.cardData?.keywords },
			...commonColumnsCreatedAndUpdated, // 创建人 修改人 创建时间 修改时间
			{ width: 90, title: '是否展示', dataIndex: 'isShow', fixed: 'right', align: 'center', render: (v, row) => <Switch value={v} onChange={(isShow) => isShowChange(isShow, row)} loading={isShowLoading} /> },
			{
				// width: 188,
				title: '操作',
				fixed: 'right',
				render: (v, row) => (
					<Space>
						<Button size="small" onClick={() => handle('get', row)}>查看</Button>
						<Button size="small" onClick={() => handle('set', row)}>修改</Button>
						<Button size="small" onClick={() => handle('del', row)} danger>删除</Button>
					</Space>
				),
			},
		]
		return data
	}

	// 打开curd操作抽屉
	const handle = async (type, row = {}) => {
		const newRow = { ...row }
		
		if (Array.isArray(newRow?.classIds) && newRow?.classIds?.length) {
			newRow.classIds = newRow.classIds.flat(1)
		}
		setRow(newRow)
		setType(type)
		setOpen(true)
	}

	// curd操作
	const curdSubmit = async (formData, row, type) => {
		formData = { ...formData }

		if (formData?.classIds?.length) {
			formData.classIds = [formData.classIds]
		}

		const init = () => {
			setLoading(false) // 取消加载状态
			setOpen(false) // 关闭窗口
			submit() // 刷新外层列表
			message.success('操作成功')
		}

		try {
			setLoading(true)

			switch (type) {
			case 'add':
				// await api.cardAdd({ data: formData })
				init()
				break

			case 'del':
				await api.userCardDel({ _id: row._id, cardId: row.cardId })
				init()
				break

			case 'set':
				await api.userCardSet({ ...formData, _id: row._id, cardId: row.cardId })
				init()
				break

			case 'get':
				setLoading(false)
				setOpen(false) // 关闭窗口
				break

			default:
				break
			}
		} catch (error) {
			setLoading(false)
			console.log('curd报错: ', error)
		}
	}

	// 获取curd form配置
	const formConfig = useMemo(() => {
		const data = [
			{
				formItemProps: {
					label: '自定义标题',
					name: 'customizeTitle',
				},
				render: <Input allowClear />,
			},
			{
				formItemProps: {
					label: '自定义描述',
					name: 'customizeDescription',
				},
				render: <Input.TextArea allowClear rows={6} />,
			},
			{
				formItemProps: {
					label: '所属分类',
					name: 'classIds',
				},
				render: <Select allowClear options={classOptions} mode='multiple' />,
			},
			{
				formItemProps: {
					label: '排序',
					name: 'sort',
				},
				render: <InputNumber min={1} style={{ width: '100%' }} />,
			},
		]
		return data
	}, [type, row])

	// 表格选择
	const tableSelectChange = (_selectedRowKeys, _selectedRows) => {
		setTableSelectData(_selectedRows)
		setTableSelectKey(_selectedRowKeys)
	}

	// 选择<用户id>时获取改用户的<分类>信息
	const getUserClass = async (userId) => {
		if (userId) {
			const res = await api.classFindByUserId({ userId }).then(r => r?.data?.rows?.map(v => ({ value: v._id, ...v })) || []) // 获取class
			setClassOptions(res)
		} else {
			setClassOptions([]) // 清空分类的options
		}
	}

	return (
		<ListPage
			search={
				<Search
					form={form}
					submit={submit}
					formData={[
						{
							formItemProps: { name: 'userId' },
							render: <Select placeholder="用户" allowClear options={userAll} onChange={getUserClass} />,
						},
						{
							formItemProps: { name: 'classId' },
							render: <Select placeholder="分类" allowClear options={classOptions} />,
						},
						{
							formItemProps: { name: 'keyword' },
							render: <Input placeholder="关键词" allowClear />,
						},
						{
							formItemProps: { name: 'createdAt' },
							render: <TimeSelect placeholder={['创建时间-开始', '创建时间-结束']} />,
							colPorps: { multiple: 2 },
						},
						{
							formItemProps: { name: 'updatedAt' },
							render: <TimeSelect placeholder={['更新时间-开始', '更新时间-结束']} />,
							colPorps: { multiple: 2 },
						},
					]}
					btnData={[
						{
							title: '搜索',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<SearchOutlined />}
									onClick={submit}
									loading={tableProps.loading}
								/>
							),
						},
						{
							title: '重置',
							isNeedFormItem: false,
							btn: (
								<Button
									type="primary"
									icon={<ReloadOutlined />}
									onClick={reset}
								/>
							),
						},
					]}
				/>
			}
			count={tableSelectKey.length}
		>
			<Table
				rowKey={(v) => v._id}
				columns={getColumns()}
				{...tableProps}
				{...tableConfig({ tableProps })}
				rowSelection={{
					type: 'checkbox',
					onChange: tableSelectChange,
					selectedRowKeys: tableSelectKey,
					checkStrictly: false,
				}}
			/>

			<Curd
				ref={childRef}
				open={open}
				setOpen={setOpen}
				row={row}
				type={type}
				title={typeOptions?.[type] + '用户卡片'}
				width={600}
				formProps={{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }}
				formConfig={formConfig}
				submit={curdSubmit}
				loading={loading}
			/>
		</ListPage>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: true,
	isShowBreadcrumb: true,
	isShowContent: true,
	isShowFooter: false,
}
