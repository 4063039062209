import { Typography } from 'antd'
import { memo } from 'react'

const { Paragraph } = Typography

// 文本(带复制按钮)
const Copy = memo(({ children }) => {
	return children ? (
		<Paragraph copyable style={{ margin: 0 }}>
			{children}
		</Paragraph>
	) : null
})

export default Copy
