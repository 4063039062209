import { memo, useEffect, useState, useMemo } from 'react'
import { Menu } from 'antd'
import styles from '../../index.module.scss'
import { useRecoilState } from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	leftMenuData as _leftMenuData,
	leftMenuSelectedData as _leftMenuSelectedData,
} from '@/state'
import { icon, isLink } from '@/tools'

const LeftMenu = memo(() => {
	const location = useLocation()
	const navigate = useNavigate()
	const [leftMenuData] = useRecoilState(_leftMenuData) // 左栏数据
	const [, setLeftMenuSelectedData] = useRecoilState(_leftMenuSelectedData) // 左栏选中数据
	const [leftMenuSelectedKeys, setLeftMenuSelectedKeys] = useState(undefined) // 左栏选中key

	// 查找指定key的item及其所有主辈节点
	const findItemAndAncestors = (key, data) => {
		// 定义一个数组，用来存储匹配的元素和其长辈级节点
		let result = []

		// 定义一个辅助函数，接受一个key字符串，一个数组和一个路径数组作为参数
		const helper = (key, data, path) => {
			// 遍历数组中的每个元素
			for (let item of data) {
				// 如果元素的key属性和参数相同，就把该元素和路径数组中的元素添加到结果数组中，并返回true
				if (item.key === key) {
					result.push(item, ...path)
					return true
				}

				// 如果元素有children属性，就递归调用辅助函数在子数组中查找，同时把当前元素添加到路径数组中
				if (item.children) {
					let found = helper(key, item.children, [item, ...path])

					// 如果在子数组中找到了匹配的元素，就返回true
					if (found) {
						return true
					}
				}
			}

			// 如果遍历完数组都没有找到匹配的元素，就返回false
			return false
		}

		// 调用辅助函数，传入key字符串，数组和空路径数组
		helper(key, data, [])

		// 返回结果数组
		return result.reverse()
	}

	// 1 初始化时
	// 2 路由变化
	//  根据url是否命中menu数据(命中时默认选中 否者没有默认选中的菜单)
	useEffect(() => {
		const path = location.pathname
		const hit = leftMenuData.filter((v) => v.key === path)

		if (hit?.length) {
			setLeftMenuSelectedKeys([hit[0].key])
		}
	}, [location, leftMenuData])

	// 选择菜单时
	const onSelect = ({ key }) => {
		// keyPath, selectedKeys, domEvent, item
		setLeftMenuSelectedKeys(key) // menu选中状态

		// 存储左栏选中的数据 用于面包屑展示
		try {
			const i = findItemAndAncestors(key, leftMenuData)

			if (i) {
				setLeftMenuSelectedData(i)
			}
		} catch (error) {
			console.log(error)
		}

		// key 以 / 开头的是url 需要跳转
		if (isLink(key)) {
			navigate(key)
			return
		}
	}

	// 递归将icon字符串转为标签
	const renderMenuData = useMemo(() => {
		if (!leftMenuData?.length) {
			return []
		}

		// 递归处理数据
		const recursion = (newData) => {
			newData.forEach((i) => {
				// 如果元素有icon属性，就把它转换为icon('xxx')
				if (i?.icon) {
					i.icon = icon(i.icon)
				}

				// 如果元素有children属性，就递归调用函数处理子数组
				if (i?.children?.length) {
					recursion(i.children)
				}
			})
			return newData
		}

		const newData = JSON.parse(JSON.stringify(leftMenuData))
		return recursion(newData)
	}, [leftMenuData])

	return (
		<Menu
			mode="inline"
			theme="dark"
			items={renderMenuData}
			className={styles.leftMenu}
			selectedKeys={leftMenuSelectedKeys}
			onSelect={onSelect}
		/>
	)
})

export default LeftMenu
