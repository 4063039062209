import { memo } from 'react'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'

// 时间范围选择器
const TimeSelect = memo((props) => {
	const { placeholder = ['开始时间', '结束时间'], ..._props } = props

	const onOpenChange = () => {
		// open
		const interval = setInterval(() => {
			const btn = document.querySelector(
				'.ant-picker-dropdown .ant-picker-ok .ant-btn',
			)

			if (btn instanceof Element) {
				clearInterval(interval)

				// 监听确认按钮的 disabled 状态
				const observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (
							mutation.type === 'attributes' &&
              mutation.attributeName === 'disabled'
						) {
							if (!mutation.target.disabled) {
								// disabled变为false 的时候 帮助用户点击
								btn.click()
							}
						}
					})
				})
				observer.observe(btn, { attributes: true })
			}
		}, 100)
	}

	return (
		<DatePicker.RangePicker
			placeholder={placeholder}
			allowClear
			style={{ width: '100%' }}
			format="YYYY-MM-DD HH:mm:ss"
			showTime={{
				hideDisabledOptions: true,
				defaultValue: [
					dayjs('00:00:00', 'HH:mm:ss'),
					dayjs('23:59:59', 'HH:mm:ss'),
				],
			}}
			onOpenChange={onOpenChange}
			{..._props}
		/>
	)
})

export default TimeSelect
