import React, { memo, useEffect, useState } from 'react'
import { Input, Popover } from 'antd'
import { icon, iconNames } from '@/tools'

// 图标选择器
const IconSelect = memo((props) => {
	const { width = 560, height = 300, value, onChange } = props

	useEffect(() => {
		sValue(value)
	}, [value])

	const [hoverIndex, sHoverIndex] = useState(-1)
	const [isShow, sIsShow] = useState(false)
	const [Value, sValue] = useState()

	const handleMouseEnter = (e, v, i) => {
		sHoverIndex(i)
	}

	// eslint-disable-next-line no-unused-vars
	const handleMouseLeave = (e, v, i) => {
		sHoverIndex(-1)
	}

	// eslint-disable-next-line no-unused-vars
	const handleClick = (e, v, i) => {
		save(v)
	}

	const handleChange = (v) => {
		save(v.target.value)
	}

	const save = (v) => {
		sValue(v)
		if (typeof onChange === 'function') onChange(v)
	}

	const content = (
		<div style={{ display: 'flex', flexFlow: 'wrap' }}>
			{iconNames.map((val, i) => (
				<div
					key={i}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontSize: hoverIndex === i ? 25 : 20,
						color: hoverIndex === i ? '#1890ff' : '#333',
						width: 40,
						height: 40,
						padding: 5,
						outline: '1px solid #eeeeee50',
						cursor: 'pointer',
					}}
					onMouseEnter={(e) => handleMouseEnter(e, val, i)}
					onMouseLeave={(e) => handleMouseLeave(e, val, i)}
					onClick={(e) => handleClick(e, val, i)}
				>
					{icon(val)}
				</div>
			))}
		</div>
	)

	return (
		<div style={{ width: '100%' }}>
			<Popover
				placement="bottom"
				title={null}
				open={isShow}
				content={content}
				trigger="focus"
				overlayStyle={{
					minWidth: width,
					width,
					maxHeighti: height,
					height,
					overflowY: 'scroll',
				}}
				overlayInnerStyle={{
					boxShadow: 'none',
					borderRadius: 5,
					border: '1px solid #eee',
				}}
			>
				<Input
					{...props}
					autoComplete={'off'}
					addonBefore={Value ? icon(Value) : undefined}
					allowClear
					value={Value}
					onChange={handleChange}
					onFocus={() => sIsShow(true)}
					onBlur={() => setTimeout(() => sIsShow(false), 150)}
				/>
			</Popover>
		</div>
	)
})

export default IconSelect
