import { memo } from 'react'

const NotFound = memo(() => {
	return 'NotFound'
})

export default NotFound
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: false,
	isShowBreadcrumb: false,
	isShowContent: true,
	isShowFooter: false,
}
