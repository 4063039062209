import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './router'
import './styles/normalize.scss' // 全局样式
import { RecoilRoot } from 'recoil' // 轻量级全局状态管理工具

// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import zhCN from 'antd/locale/zh_CN'

window.__app__ = {}
dayjs.locale('zh-cn')

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<React.StrictMode>
		<ConfigProvider locale={zhCN}>
			<RecoilRoot>
				<App />
			</RecoilRoot>
		</ConfigProvider>
	</React.StrictMode>,
)
