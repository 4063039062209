import React, { useRef, useEffect, useState, memo } from 'react'
import { Col } from 'antd'

// 占位符
const Placeholder = memo((props) => <Col {...props} />)

// 栅格容器
const Grid = memo((props) => {
	const {
		// 左侧间距
		leftPadding = {
			xs: 0,
			sm: 0,
			md: 2,
			lg: 2,
			xl: 5,
		},

		// 右侧间距
		rightPadding = {
			xs: 0,
			sm: 0,
			md: 2,
			lg: 2,
			xl: 5,
		},

		// 中间内容容器-传入数组时展示多列内容 传入对象时则一列展示
		middle = {
			xs: 24,
			sm: 24,
			md: 20,
			lg: 20,
			xl: 14,
		},

		children,
		data = [] // middle为数组时 内容通过data传入 [child1, child2, ...]
	} = props

	return (<>
		<Placeholder {...leftPadding} />

		{
			Array.isArray(middle) ?

				middle.map((v, index) => (
					<Col {...v} key={index}>
						{data[index]}
					</Col>
				)):

				<Col {...middle}>
					{children}
				</Col>
		}

		<Placeholder {...rightPadding} />
	</>
	)
})

export default Grid
