// 用户中心
import { memo } from 'react'
import styles from './index.module.scss'
import { Row, Typography } from 'antd'
// import { useRecoilState } from 'recoil'
// import { userInfo as _userInfo } from '@/state'
import Grid from '@/components/grid'
import LazyLoadContainer from '@/components/lazyLoadContainer'

import TitleBox from './components/titleBox'
import UserInfo from './components/userInfo'
import ContactInformation from './components/contactInformation'
import Preference from './components/preference'

import profile_scene_preferences_720x256 from './components/static/profile_scene_preferences_720x256.png'
import dataandpersonalization_scene_info_you_share_720x256 from './components/static/dataandpersonalization_scene_info_you_share_720x256.png'
import googleonestorage_scene_632x224 from './components/static/googleonestorage_scene_632x224.png'
import dataandpersonalization_scene_more_options_720x256 from './components/static/dataandpersonalization_scene_more_options_720x256.png'
import dataandpersonalization_scene_things_done_places_been_720x256 from './components/static/dataandpersonalization_scene_things_done_places_been_720x256.png'


const { Title } = Typography

const Page = memo(() => {
	// const [userInfo] = useRecoilState(_userInfo) // 用户信息

	return (
		<div className={styles.userCenter}>
			<Row gutter={[20, 20]}>
				<Grid>
					<Title level={2} style={{ textAlign: 'center' }}>用户中心</Title>
				</Grid>

				<Grid>
					<TitleBox
						src={dataandpersonalization_scene_info_you_share_720x256}
						title={'个人信息'}
						desc={'用于展示的个人信息的，包括用户名、性别、年龄、职业等基本信息'}
					/>
				</Grid>

				<Grid>
					{/* 个人信息表单 */}
					<div className={styles.ctn}>
						<UserInfo />
					</div>
				</Grid>

				<Grid>
					<TitleBox
						src={googleonestorage_scene_632x224}
						title={'联系方式'}
						desc={'可以添加和管理联系方式，用于接收平台的验证信息、提示信息等内容'}
					/>
				</Grid>

				<Grid>
					{/* 联系方式表单 */}
					<div className={styles.ctn}>
						<ContactInformation />
					</div>
				</Grid>

				{/* <Grid>
					<TitleBox
						src={dataandpersonalization_scene_more_options_720x256}
						title={'数据统计'}
						desc={'展示用户的数据统计信息，包括登录次数、活跃时长、汇总数据等，从而更好地管理自己的时间和任务'}
					/>
				</Grid> */}

				{/* <Grid>
					<div className={styles.ctn}>
						{new Array(10).fill(0).map((v, k) => <p key={k}>{'-'}</p>)}
					</div>
				</Grid> */}

				{/* <Grid
					middle={[
						{ xs: 12, sm: 12, md: 10, lg: 10, xl: 7 },
						{ xs: 12, sm: 12, md: 10, lg: 10, xl: 7 },
					]}
					data={[
						(<div className={styles.ctn} key={1}>
							<LazyLoadContainer>
								{new Array(5).fill(0).map((v, k) => <p key={k}>{'-'}</p>)}
							</LazyLoadContainer>
						</div>),

						(<div className={styles.ctn} key={2}>
							<LazyLoadContainer>
								{new Array(5).fill(0).map((v, k) => <p key={k}>{'-'}</p>)}
							</LazyLoadContainer>
						</div>)
					]}
				/> */}

				<Grid>
					<TitleBox
						src={profile_scene_preferences_720x256}
						title={'偏好设置'}
						desc={'可以根据自己的喜好和需求进行个性化设置 可以选择接收哪些类型的通知消息，设置账号的安全性选项，或者调整界面的显示语言等'}
					/>
				</Grid>

				<Grid>
					<div className={styles.ctn}>
						<Preference />
					</div>
				</Grid>

				{/* <Grid>
					<TitleBox
						src={dataandpersonalization_scene_things_done_places_been_720x256}
						title={'样式布局'}
						desc={'调整界面的样式和布局。可以选择不同的主题颜色、字体样式和页面布局，以满足自己的审美需求'}
					/>
				</Grid> */}

				{/* <Grid>
					<div className={styles.ctn}>
						{new Array(20).fill(0).map((v, k) => <p key={k}>{'-'}</p>)}
					</div>
				</Grid> */}
			</Row>
		</div>
	)
})

export default Page
export const isShowConfig = {
	isShowHeader: true,
	isShowSider: false,
	isShowBreadcrumb: false,
	isShowContent: false,
	isShowFooter: false,
}
