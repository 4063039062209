import { memo, useEffect, useMemo, useState } from 'react'
import { Button, Dropdown, Drawer, Table, Space, Form, Input, InputNumber, message } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { useRecoilState } from 'recoil'
import { classAll as _classAll, isLogin as _isLogin, isShowLogin as _isShowLogin } from '@/state'
import { commonColumnsIndex } from '@/tools'
import api from '@/api'


const Set = memo((props) => {
	const { refresh } = props
	const [form] = Form.useForm()
	const [isShow, setIsShow] = useState(false)
	const [classAll] = useRecoilState(_classAll) // 是否展示list
	const [loading, setLoading] = useState(false)
	const dataSource = useMemo(() => classAll.length > 1 ? classAll.slice(1) : [], [classAll]) // 表格数据
	const [, setIsShowLogin] = useRecoilState(_isShowLogin) // 是否展示登录窗口
	const [isLogin] = useRecoilState(_isLogin) // 用户是否登录


	useEffect(() => {
		if (isShow) {
			form.setFieldsValue({ list: dataSource })
		} else {
			form.resetFields()
		}
	}, [isShow, dataSource])

	const onClick = (key) => {
		if (!isLogin) {
			setIsShowLogin(true)
			return
		}

		switch (key) {
		case '1': // 分类设置
			setIsShow(true)
			break

		case '2': // 布局设置
			// setIsLayoutEdit(true)
			break

		default:
			break
		}
	}

	const items = [
		{ key: '1', label: <a onClick={() => onClick('1')}>分类设置</a> },
		{ key: '2', label: <a onClick={() => onClick('2')}>布局设置</a> },
	]

	// 获取表格列
	const getColumns = () => {
		const rules = [{ required: true, message: '请输入' }]
		const style = { position: 'relative', top: 12 }

		const data = [
			{ ...commonColumnsIndex, width: 60, },
			{
				title: '分类',
				dataIndex: 'label',
				width: 250,
				render: (v, row) => (
					<Form.Item rules={rules} name={[row.name, 'label']} style={style}>
						<Input allowClear />
					</Form.Item>
				),
			},
			{
				title: '排序',
				dataIndex: 'sort',
				width: 100,
				render: (v, row) => (
					<Form.Item rules={rules} name={[row.name, 'sort']} style={style}>
						<InputNumber min={1} style={{ width: '100%' }} />
					</Form.Item>
				),
			},
			{
				title: '操作',
				width: 70,
				render: (row) => (
					<Button onClick={() => save(row.key)} loading={loading}>保存</Button>
				),
			},
		]
		return data
	}

	// 保存
	const save = async (_key) => {
		setLoading(true)

		try {
			const { list } = form.getFieldsValue()
			const row = list[_key]
			const { key, label, sort, _id } = row
	
			const req = { _id, label, sort }
			const res = await api.classSetClass(req)
			refresh()
			message.success('操作成功')
		} catch (error) {
			//
		}

		setLoading(false)
	}

	return (
		<div style={{ paddingLeft: 10 }}>
			{/* <Dropdown menu={{ items }} placement="bottomRight" arrow> */}
			<Button
				icon={<SettingOutlined />}
				type='text'
				size='large'
				onClick={() => onClick('1')}
			/>
			{/* </Dropdown> */}

			{/* 分类设置 */}
			<Drawer
				title="分类设置"
				placement="right"
				onClose={() => setIsShow(false)}
				open={isShow}
				width={600}
				getContainer={'#drawerDisplayArea'}
				footer={
					<div style={{ textAlign: 'right' }}>
						<Space>
							<Button onClick={() => setIsShow(false)}>取消</Button>
							<Button type="primary" onClick={() => setIsShow(false)}>确认</Button>
						</Space>
					</div>
				}
			>
				<Form form={form} onFinish={save} size='small'>
					<Form.List name="list">
						{(fields) => {
							return (
								<Table
									dataSource={fields}
									size='small'
									columns={getColumns()}
									pagination={false}
									rowKey={'_id'}
								/>
							)
						}}
					</Form.List>
				</Form>
			</Drawer>
		</div>
	)
})

export default Set
