// 处理异常 - 约定的特殊code调用对应的处理逻辑(例: 掉线是跳转登录页面)
const data = {
	10001: {
		desc: 'token错误 请重新登录',
		handle: async () => {
			window?.__app__?.userOperate?.('signOut') // touken 过期退出登录

			// 跳转登录页面
		},
	}

	// ...
}

export default data
