import { memo, useEffect, useState, useRef, useMemo } from 'react'
import { Row, Col, Card, Space, Typography, Divider, Flex, Modal, Form, InputNumber, Input, message, Dropdown, Button, Select, Spin } from 'antd'
import { useRecoilState } from 'recoil'
import { cardData as _cardData, currentCardData as _currentCardData, currentSelectClass as _currentSelectClass, classAll as _classAll, isLogin as _isLogin, isShowLogin as _isShowLogin, userInfo as _userInfo } from '@/state'
import { icon as renderIcon, getReg, typeOptions, findFastestURL } from '@/tools'
import api from '@/api'
import Pic from '@/components/pic'


const MyCard = memo((props) => {
	const colGap = 16 // 列间隙
	const lineGap = 16 // 行间隙
	const searchRef = useRef()
	const [cardData, setCardData] = useRecoilState(_cardData) // card数据
	const [currentCardData, setCurrentCardData] = useRecoilState(_currentCardData) // 当前(添加的)card数据
	const [currentSelectClass] = useRecoilState(_currentSelectClass) // 当前选择的类
	const [classAll] = useRecoilState(_classAll) // 当前用户全部分类
	const [isLogin] = useRecoilState(_isLogin) // 用户是否登录
	const [userInfo] = useRecoilState(_userInfo) // 用户信息
	const [isShowLogin, setIsShowLogin] = useRecoilState(_isShowLogin) // 是否展示登录窗口
	const [isShow, setIsShow] = useState(false)
	const [form] = Form.useForm()
	const [addConfirmLoading, setAddConfirmLoading] = useState(false)
	const [type, setType] = useState('')
	const [echoSetData, setEchoSetData] = useState({}) // 修改时 查询的回显数据
	const [loading, setLoading] = useState({})

	const classAllOption = useMemo(() => {
		return classAll?.slice(1)?.map(v => ({ value: v.key, label: v.label })) || []
	}, [classAll])

	useEffect(() => {
		// 用户退出登录的时候 清除卡片数据
		if (!isLogin) {
			setCardData([])
		}
	}, [isLogin])

	useEffect(() => {
		const { url, title, description, sort, classIds } = currentCardData // sort
		const fd = {
			url,
			sort,
			customizeTitle: title,
			customizeDescription: description,
			classIds: classIds?.length ? classIds?.flat(1) : (form?.getFieldsValue()?.classIds || []), // 如果返回的卡片有class 数据则使用返回 否则优先使用当前分类的选项
		}
		form.setFieldsValue(fd)
	}, [currentCardData])

	// 卡片渲染
	const renderCard = (options) => {
		const { key, icons, title, description, type, url } = options
		const isAdd = type === 'add'

		const echoClass = () => form.setFieldsValue({ classIds: currentSelectClass === '1' ? [] : [currentSelectClass] }) // 所选分类回显

		const clickCard = async (key) => {
			if (isAdd) { // 新增
				if (isLogin) { // 未登录时
					setType('add')
					setIsShow(true) // 打开添加卡片
					echoClass() // 所选分类回显
				} else {
					setIsShowLogin(true)
				}
			} else {
				// 用户开启了跳转优化 并且 有备用地址是
				if (userInfo?.config?.optimizeJump && options?.all?.cardData?._backupUrlList?.length) {

					const loadingData = { ...loading }
					loadingData[key] = true
					console.log(loadingData)
					setLoading(loadingData)

					const urls = [options.url, ...options.all.cardData._backupUrlList.map(v => v.backupUrl)]

					console.time('测试相应最快url耗时')
					const _url = await findFastestURL(urls) // 查找响应速度最快的url

					setLoading({})

					window.open(_url)
					console.timeEnd('测试相应最快url耗时')

					// 都不响应 且 又发布器地址 跳转发布器让用户自行选择
					// options?.all?.cardData?._backupUrlList?._publisher
				} else {
					window.open(url)
				}
			}
		}

		const handle = async (e, type) => {
			e.stopPropagation()

			switch (type) {
			case 'del':
				Modal.confirm({
					title: '提示',
					icon: renderIcon('ExclamationCircleFilled'),
					content: '确认删除所选书签?',
					onOk: async () => {
						await api.userCardDelBind({ _id: options.key })
						message.success('操作成功')
						window?.__app__?.search?.() // 刷新卡片数据
					},
				})
				break

			case 'set': {
				setType(type)
				setIsShow(true)
				const res = await api.userCardGetCard({ _id: options.all._id })

				const data = res?.data?.[0]
				setEchoSetData(data)
				setCurrentCardData({
					...data?.cardData,
					sort: data?.sort,
					title: data?.customizeTitle || data?.cardData?.title,
					description: data?.customizeDescription || data?.cardData?.description,
					classIds: data?.classIds || [],
				})
			}
				break

			default:
				break
			}
		}

		const items = [
			{ key: '1', label: (<span onClick={(e) => handle(e, 'del')}>删除</span>), danger: true },
			{ key: '2', label: (<span onClick={(e) => handle(e, 'set')}>修改</span>) },
		]

		return (
			<Col
				xs={24}
				sm={24}
				md={12}
				lg={8}
				xl={6}
				xxl={4}
				key={key}
			>
				<Spin spinning={loading[key] ? true : false}>
					<Card
						size='small'
						title={
							isAdd ? undefined :
								<Space style={{ width: '100%' }}>
									<Pic src={icons} size={20} />
									<p>{title}</p>
								</Space>
						}
						extra={
							isAdd ? undefined :
								<Dropdown menu={{ items }} placement="bottom" arrow onClick={(e) => e.stopPropagation()}>
									<Button type='text' icon={renderIcon('MoreOutlined')} size='small' />
								</Dropdown>
						}
						style={{
							width: '100%',
							cursor: 'pointer',
							border: isAdd ? '1px dashed #f0f0f0' : undefined,
						}}
						// hoverable={true}
						onClick={() => clickCard(key)}
					>
						{
							isAdd ?
								<Flex style={{ height: 77 }} justify='center' align='center'>{renderIcon('PlusOutlined')}</Flex> :

								(<Space style={{ width: '100%' }} direction='vertical'>
									<Typography.Paragraph style={{ fontSize: 11, height: 34.5, margin: 0 }} ellipsis={{ rows: 2 }}>
										{description}
									</Typography.Paragraph>
  
									{/*
									<Divider style={{ margin: 0 }} />
									<Space wrap={true} size={4}>
										<div style={{ padding: '2px 5px', borderRadius: 15, border: '1px slid #eee', color: '#888', fontSize: 8, backgroundColor: 'rgba(134,134,134,.1)', display: 'inline-block' }}>ChatGPT 指令网站</div>
										<div style={{ padding: '2px 5px', borderRadius: 15, border: '1px slid #eee', color: '#888', fontSize: 8, backgroundColor: 'rgba(134,134,134,.1)', display: 'inline-block' }}>ChatGPT 指令网站</div>
									</Space>
								*/}
								</Space>)
						}
					</Card>
				</Spin>
			</Col>
		)
	}

	// 添加/修改用户卡片
	const addOrSetUserCard = async (fd) => {
		setAddConfirmLoading(true)

		try {
			const { sort, customizeTitle, customizeDescription, classIds } = fd

			const req = {
				classIds: classIds?.map(v => [v]) || [], // 将来分类有可能迭代 嵌套的层级关系(所以现在以二维数组保存 为了和级联选择器的数据结构兼容)
				cardId: currentCardData._id,
				sort: sort,
				customizeTitle: customizeTitle === currentCardData.title ? undefined : customizeTitle,
				customizeDescription: customizeDescription === currentCardData.description ? undefined : customizeDescription
			}

			if (type === 'add') {
				await api.userCardAdd({ data: req })
			} else if (type === 'set') {
				req._id = echoSetData._id
				await api.userCardSetCard(req)
			}

			setCurrentCardData({})
			setIsShow(false)
			message.success('操作成功')

			// 重新搜索卡片
			window?.__app__?.search?.()
		} catch (error) {
			console.log(error)
		}

		setAddConfirmLoading(false)
	}

	// 添加卡片
	const addCard = async (url) => {
		const valdData = form.getFieldError('url')

		// 如果连接输入错误 会返回错误arr 否则返回输入内容
		if (Array.isArray(valdData) && valdData.length) {
			return
		} else {
			setAddConfirmLoading(true)

			// 添加卡片 并获取添加结果
			try {
				const data =  await api.cardAdd({ data: { url } })
				const sortData = await api.userCardGetSortMax() // 查询最大排序
				const maxSort = sortData?.data?.rows?.[0]?.sort || 0
				setTimeout(() => {
					form.setFieldsValue({ sort: maxSort + 1 })
				}, 0)
				setCurrentCardData(data.data)
			} catch (error) {
				console.log(error)
			}

			setAddConfirmLoading(false)
		}
	}

	// 提交表单
	const submit = () => {
		if (currentCardData.url) {
			form.submit()
		} else {
			message.warning('搜索对应网址详情后再进行保存')
		}
	}

	return (
		<div>
			<Row gutter={[colGap, lineGap]}>
				{
					cardData.map(v => renderCard({
						key: v?._id,
						icons: [v?.cardData?.appleTouchIcon, v?.cardData?.icon, v?.cardData?.shortcutIcon],
						title: v?.customizeTitle || v?.cardData?.title,
						description: v?.customizeDescription || v?.cardData?.description,
						url: v?.cardData?.url,
						all: v
					}))
				}
				{ renderCard({ type: 'add' }) }
			</Row>

			{/* curd 窗口 */}
			<Modal
				width={450}
				title={`${typeOptions[type]}书签`}
				open={isShow}
				onOk={submit}
				onCancel={() => setIsShow(false)}
				confirmLoading={addConfirmLoading}
			>
				<Form form={form} onFinish={addOrSetUserCard}>
					<Form.Item label="连接" name="url" rules={[{ required: true }, getReg('url')]}>
						<Input.Search onSearch={addCard} placeholder='输入完整网址例: https://www.xxx.com/' ref={searchRef} loading={addConfirmLoading} />
					</Form.Item>

					<Form.Item label="分类" name="classIds">
						<Select mode="multiple" options={classAllOption} allowClear />
					</Form.Item>

					{
						currentCardData.url ? (
							<>
								<Form.Item label="排序" name="sort" rules={[{ required: true }]}>
									<InputNumber min={1} style={{ width: '100%' }} />
								</Form.Item>

								<Form.Item label="标题" name="customizeTitle">
									<Input allowClear />
								</Form.Item>

								<Form.Item label="描述" name="customizeDescription">
									<Input.TextArea allowClear rows={3} />
								</Form.Item>
							</>
						) : null
					}
				</Form>
			</Modal>
		</div>
	)
})

export default MyCard
