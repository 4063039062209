import Layout from '../layout'

export const shell = (
	Component,
	{
		isShowHeader,
		isShowSider,
		isShowBreadcrumb,
		isShowContent,
		isShowFooter,
	} = {},
	fileName
) => (
	<Layout
		isShowHeader={isShowHeader}
		isShowSider={isShowSider}
		isShowBreadcrumb={isShowBreadcrumb}
		isShowContent={isShowContent}
		isShowFooter={isShowFooter}
		fileName={fileName}
	>
		<Component />
	</Layout>
)

const Shell = (
	Component,
	{
		isShowHeader,
		isShowSider,
		isShowBreadcrumb,
		isShowContent,
		isShowFooter,
	} = {},
	fileName
) => (
	<Layout
		isShowHeader={isShowHeader}
		isShowSider={isShowSider}
		isShowBreadcrumb={isShowBreadcrumb}
		isShowContent={isShowContent}
		isShowFooter={isShowFooter}
		fileName={fileName}
	>
		<Component />
	</Layout>
)


export default Shell