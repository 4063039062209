import { memo, useEffect, useMemo } from 'react'
import styles from './index.module.scss'
import { Space, Typography } from 'antd'
import { onWindowSize } from '@/tools'
import { useRecoilState } from 'recoil'
import {
	windowSize as _windowSize,
	headerSize as _headerSize,
	actionsSize as _actionsSize,
	searchSize as _searchSize,
	breadcrumbSize as _breadcrumbSize,
	virtualTableHeight as _virtualTableHeight,
} from '@/state'

const { Link } = Typography

// 通用表格封装
const ListPage = memo((props) => {
	const listPageSearch = 'listPageSearch' // className
	const listPageActions = 'listPageActions' // className

	const {
		children,
		search,
		gap = 20,
		levelingMarginRight = true, // form-item右pading调平

		count, // 已选中数据条数
		actionsLeft, // 批量操作按钮-左
		actionsRight, // 批量操作按钮-右
	} = props

	// 是否展示 批量操作区域
	const isShowActions = useMemo(() => {
		return count !== undefined || actionsLeft || actionsRight
	}, [count, actionsLeft, actionsRight])

	// 容器尺寸
	const [windowSize] = useRecoilState(_windowSize) // 视口区域-尺寸
	const [headerSize] = useRecoilState(_headerSize) // 顶栏区域-尺寸
	const [breadcrumbSize] = useRecoilState(_breadcrumbSize) // 面包屑区域-尺寸
	const [actionsSize, setActionsSize] = useRecoilState(_actionsSize) // 顶栏区域-尺寸
	const [searchSize, setSearchSize] = useRecoilState(_searchSize) // 顶栏区域-尺寸
	const [, setVirtualTableHeight] = useRecoilState(_virtualTableHeight) // 虚拟表格-尺寸

	// 窗口大小监听
	useEffect(() => {
		if (search) onWindowSize(`.${listPageSearch}`, setSearchSize)
		if (isShowActions) onWindowSize(`.${listPageActions}`, setActionsSize)
	}, [isShowActions, search])

	// 计算虚拟表格高度
	useEffect(() => {
		//          视口 -              顶栏 -              面包屑 -            容器padding * 2 - 搜索栏 -      布局间隙
		let res =
      windowSize.height -
      headerSize.height -
      breadcrumbSize.height -
      40 -
      40 -
      searchSize.height -
      gap

		if (isShowActions) {
			res = res - actionsSize.height
		}

		setVirtualTableHeight({ height: res })
	}, [
		windowSize,
		headerSize,
		breadcrumbSize,
		searchSize,
		actionsSize,
		isShowActions,
	])

	return (
		<section className={styles.layout}>
			<Space direction="vertical" style={{ width: '100%' }} size={gap}>
				{search ? (
					<header
						className={`${styles.header} ${listPageSearch}`}
						style={{ ...(levelingMarginRight ? { marginRight: -16 } : null) }}
					>
						{search}
					</header>
				) : null}

				{isShowActions ? (
					<header className={`${styles.actions} ${listPageActions}`}>
						{count !== undefined ? (
							<p className={styles.count}>
                已选 <Link>{count}</Link> 条数据
							</p>
						) : null}

						<div className={styles.btns}>
							<div className={styles.actionsLeft}>{actionsLeft}</div>
							<div className={styles.actionsRight}>{actionsRight}</div>
						</div>
					</header>
				) : null}

				{children ? <main className={styles.main}>{children}</main> : null}
			</Space>
		</section>
	)
})

export default ListPage
